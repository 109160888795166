import { useAtom, useAtomValue } from "jotai";
import {
  getAllLayoutsForUser,
  getInsightsDatasets,
  getSpecificLayout,
  updateLayout,
} from "../../../api";
import { AccountAtom } from "../../../store";
import {
  DashboardLayoutStateStore,
  SelectedDashboardIdAtom,
} from "../../../store/DashboardLayout";
import { useCallback, useEffect, useState } from "react";
import { Layout } from "react-grid-layout";
import { useFilters } from "../../../hooks/useFilters";
import moment from "moment";

export interface UseGridLayoutController {
  fetchLayouts: () => void;
  updateLayoutComp: (updatedLayout: Layout[]) => void;
  setBreakpoint: (breakpoint: string) => void;
  handleResize: () => void;
  loading: boolean;
}

export const useGridLayoutController = (): UseGridLayoutController => {
  const filters = useFilters();
  const [dashboardLayoutStore, setDashboardLayoutState] = useAtom(
    DashboardLayoutStateStore
  );
  const selectedDashboardId = useAtomValue(SelectedDashboardIdAtom);
  const [loading, setLoading] = useState<boolean>(false);
  const accountState = useAtomValue(AccountAtom);

  const fetchInsightsDatasets = useCallback(
    async (layoutId: number) => {
      if (!layoutId) return;
      try {
        const response = await getInsightsDatasets(
          accountState?.selectedAccount?.id,
          layoutId,
          filters.selectedUnderlying,
          filters.selectedTag,
          filters.selectedStrategy,
          filters.selectedBook,
          filters.selectedPositionGroup,
          moment(filters.fromDate).format("YYYY-MM-DD"),
          moment(filters.toDate).format("YYYY-MM-DD")
        );
        return response.data;
      } catch (error) {
        console.error("Error fetching insights datasets:", error);
      }
    },
    [accountState, filters]
  );

  const fetchLayouts = useCallback(async () => {
    setLoading(true);
    try {
      const layouts = await getAllLayoutsForUser();
      if (!layouts) return;

      if (accountState.selectedAccount?.id === undefined) {
        console.error("No account selected");
        return;
      }

      let layoutId =
        selectedDashboardId ??
        (accountState.selectedAccount?.defaultDashboardConfigurationId ||
          layouts[0]?.configurationId);

      if (layoutId) {
        const layout = await getSpecificLayout(
          layoutId,
          accountState.selectedAccount?.id
        );
        if (layout?.config) {
          const datasets = await fetchInsightsDatasets(layoutId);
          const newLayoutData = generateLayoutData(layout, datasets || []);
          setDashboardLayoutState((prevState) => ({
            ...prevState,
            layouts: newLayoutData,
            gridLoading: false,
            enabledInsights: layout.config.enabledInsights || [],
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching layouts:", error);
    } finally {
      setLoading(false);
    }
  }, [
    fetchInsightsDatasets,
    setDashboardLayoutState,
    selectedDashboardId,
    accountState,
  ]);

  const generateLayoutData = useCallback(
    (layout, datasets) => {
      return dashboardLayoutStore.breakpoints.reduce((acc, breakpoint) => {
        acc[breakpoint] = layout.config.layout[breakpoint].map((item) => ({
          ...item,
          data: datasets.find(
            (i) => parseInt(i.insightId) === parseInt(item.i)
          ),
        }));
        return acc;
      }, {});
    },
    [dashboardLayoutStore.breakpoints]
  );

  const setBreakpoint = useCallback(
    (breakpoint: string) => {
      setDashboardLayoutState((prevState) => ({
        ...prevState,
        currentBreakpoint: breakpoint,
      }));
    },
    [setDashboardLayoutState]
  );

  const handleResize = useCallback(() => {
    const width = window.innerWidth;
    if (width >= 1200) setBreakpoint("lg");
    else if (width >= 992) setBreakpoint("md");
    else if (width >= 768) setBreakpoint("sm");
    else if (width >= 576) setBreakpoint("xs");
    else if (width >= 250) setBreakpoint("xxs");
    else setBreakpoint("lg");
  }, [setBreakpoint]);

  const updateLayoutComp = useCallback(
    async (updatedLayout: Layout[]) => {
      const currentLayoutData =
        dashboardLayoutStore.layouts[dashboardLayoutStore.currentBreakpoint];
      const updatedMergedLayout = updatedLayout.map((item) => ({
        ...item,
        data: currentLayoutData.find((i) => parseInt(i.i) === parseInt(item.i))
          ?.data,
      }));

      const layoutId =
        selectedDashboardId ??
        accountState.selectedAccount.defaultDashboardConfigurationId;

      try {
        const update = await updateLayout(
          layoutId,
          {
            layout: {
              ...dashboardLayoutStore.layouts,
              [dashboardLayoutStore.currentBreakpoint]: updatedMergedLayout,
            },
          },
          dashboardLayoutStore.currentBreakpoint
        );
        setDashboardLayoutState((prevState) => ({
          ...prevState,
          layouts: {
            ...prevState.layouts,
            [dashboardLayoutStore.currentBreakpoint]: updatedMergedLayout,
          },
          enabledInsights: update.enabledInsights,
        }));
      } catch (error) {
        console.error("Error updating layout:", error);
      }
    },
    [
      dashboardLayoutStore.layouts,
      dashboardLayoutStore.currentBreakpoint,
      accountState,
      setDashboardLayoutState,
      selectedDashboardId,
    ]
  );

  return {
    fetchLayouts,
    updateLayoutComp,
    setBreakpoint,
    handleResize,
    loading,
  };
};
