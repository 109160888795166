import React, { useEffect } from "react";
import { HStack, Text } from "@chakra-ui/react";
import { FastTag, StockLogo } from "../../design_library";
import { BsCashCoin } from "react-icons/bs";

export const UnderlyingRender = (params: any) => {
  let underlyingPrice;
  let hasEarningsInNext30Days = false;
  let numberOfDaysUntilEarnings = 0;
  try {
    underlyingPrice = parseFloat(
      params?.node?.aggData["underlyingPriceData.price"]
    ).toFixed(2);

    if (params.node.aggData.nextEarningsDate !== null) {
      // use moment
      const nextEarningsDate = new Date(params.node.aggData.nextEarningsDate);
      const today = new Date();
      const diffTime = Math.abs(nextEarningsDate - today);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays <= 30) {
        hasEarningsInNext30Days = true;
        numberOfDaysUntilEarnings = diffDays;
      }
    }
  } catch (e) {
    console.error(e);
  }
  return (
    <HStack>
      <StockLogo size={20} stock={params?.value} />
      <Text fontWeight={600}>{params.value ?? "Unknown"}</Text>

      {!isNaN(underlyingPrice) && parseFloat(underlyingPrice) !== 0 && (
        <FastTag size="xs" padding={"6px !important"} fontSize="12px">
          ${underlyingPrice}
        </FastTag>
      )}

      <Text fontWeight={400}>({params.node.allChildrenCount}) </Text>

      {hasEarningsInNext30Days && (
        <FastTag
          size="xs"
          padding={"3px !important"}
          fontSize="12px"
          bg="var(--gray)"
        >
          <HStack px="4px">
            <BsCashCoin />
            <Text>Earnings in {numberOfDaysUntilEarnings} days</Text>
          </HStack>
        </FastTag>
      )}
    </HStack>
  );
};
