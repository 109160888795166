import { useCallback, useEffect, useState } from "react";
import { AccountAtom, TransactionsAtom } from "../../store";
import { useAtomValue, useSetAtom } from "jotai";
import { getBooks, getStrategies, getGroupNames, getTags } from "../../api";
import { updateManyCashflows } from "../../api/Cashflow";
import { CashflowCategories } from "../../types";

export interface ExplainCashflowProps {
  cashflows: any[];
  onExplainCashflow: () => void;
}

interface UseExplainCashflowsResponse {
  nbrCashflows: number;
  selectedCategory: CashflowCategories | undefined;
  setSelectedCategory: (value: CashflowCategories | undefined) => void;
  books: string[];
  selectedBook: string | undefined;
  setSelectedBook: (value: string | undefined) => void;
  positionGroups: string[];
  selectedPositionGroup: string | undefined;
  setSelectedPositionGroup: (value: string | undefined) => void;
  strategies: string[];
  selectedStrategy: string | undefined;
  setSelectedStrategy: (value: string | undefined) => void;
  tags: string[];
  selectedTags: string[];
  setSelectedTags: (value: string[]) => void;
  notes: string;
  setNotes: (notes: string) => void;
  saveChanges: () => Promise<void>;
  loading: boolean;
  determiningState: boolean;
  saving: boolean;
  applyField: (field: string) => void;
}

export const useExplainCashflow = (
  props: ExplainCashflowProps
): UseExplainCashflowsResponse => {
  const [loading, setLoading] = useState(false);
  const [determiningState, setDeterminingState] = useState(false);
  const [books, setBooks] = useState<string[]>([]);
  const [strategies, setStrategies] = useState<string[]>([]);
  const [positionGroups, setPositionGroups] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const accountState = useAtomValue(AccountAtom);
  const transactionState = useAtomValue(TransactionsAtom);
  const setTransactionsState = useSetAtom(TransactionsAtom);
  const [saving, setSaving] = useState(false);

  const loadReferenceData = (force: boolean = false) => {
    if (accountState.selectedAccount?.id && (force || !loading)) {
      setLoading(true);
      setTimeout(async () => {
        try {
          setBooks(await getBooks(accountState.selectedAccount!.id));
          setStrategies(await getStrategies(accountState.selectedAccount!.id));
          setPositionGroups(
            await getGroupNames(accountState.selectedAccount!.id)
          );
          setTags(await getTags(accountState.selectedAccount!.id));
        } finally {
          setLoading(false);
        }
      }, 1);
    }
  };

  const [selectedCategory, setSelectedCategory] = useState<
    CashflowCategories | undefined
  >(undefined);

  const [selectedBook, setSelectedBook] = useState<string | undefined>(
    undefined
  );

  const [selectedPositionGroup, setSelectedPositionGroup] = useState<
    string | undefined
  >(undefined);

  const [selectedTagsState, setSelectedTagsState] = useState<string[]>([]);

  const [selectedStrategy, setSelectedStrategy] = useState<string | undefined>(
    undefined
  );

  // notes
  const [notes, setNotes] = useState<string>("");

  const determineCategory = useCallback(() => {
    if (!loading) {
      let result = undefined;
      for (const cashflow of props.cashflows) {
        if (!result && cashflow.category) {
          result = cashflow.category;
        } else if (
          result &&
          cashflow.category &&
          result !== cashflow.category
        ) {
          result = undefined;
          break;
        }
      }
      setSelectedCategory(result);
    }
  }, [props.cashflows, loading]);

  const determineBooks = useCallback(() => {
    if (!loading) {
      let result = undefined;
      for (const cashflow of props.cashflows) {
        if (!result && cashflow.book) {
          result = cashflow.book;
        } else if (result && cashflow.book && result !== cashflow.book) {
          result = undefined;
          break;
        }
      }
      setSelectedBook(result);
    }
  }, [props.cashflows, loading]);

  const determinePositionGroups = useCallback(() => {
    if (!loading) {
      let result = undefined;
      for (const cashflow of props.cashflows) {
        if (!result && cashflow.positionGroup) {
          result = cashflow.positionGroup;
        } else if (
          result &&
          cashflow.positionGroup &&
          result !== cashflow.positionGroup
        ) {
          result = undefined;
          break;
        }
      }
      setSelectedPositionGroup(result);
    }
  }, [props.cashflows, loading]);

  const determineStrategies = useCallback(() => {
    if (!loading) {
      let result = undefined;
      for (const cashflow of props.cashflows) {
        if (!result && cashflow.strategy) {
          result = cashflow.strategy;
        } else if (
          result &&
          cashflow.strategy &&
          result !== cashflow.strategy
        ) {
          result = undefined;
          break;
        }
      }
      setSelectedStrategy(result);
    }
  }, [props.cashflows, loading]);

  const determineTags = useCallback(() => {
    if (!loading) {
      let result = undefined;
      for (const cashflow of props.cashflows) {
        if (cashflow.tags) {
          const cashflowTags = cashflow.tags as string[];
          if (
            cashflowTags &&
            cashflow.tags !== "{}" &&
            cashflowTags.length > 0
          ) {
            const ordered = cashflowTags.sort().join(",");
            if (!result) {
              result = ordered;
            } else if (result !== ordered) {
              result = undefined;
              break;
            }
          }
        }
      }
      setSelectedTagsState(result ? (result?.split(",") ?? []) : []);
    }
  }, [props.cashflows, loading]);

  const determineNotes = useCallback(() => {
    if (!loading) {
      let result = "";
      for (const cashflow of props.cashflows) {
        if (cashflow.notes) {
          if (!result) {
            result = cashflow.notes;
          } else if (result !== cashflow.notes) {
            result = "";
            break;
          }
        }
      }
      setNotes(result);
    }
  }, [props.cashflows, loading]);

  const setSelectedTags = useCallback(
    (newTags: string[]) => {
      setSelectedTagsState(newTags);

      if (newTags.length > 0) {
        const allTagsSet = new Set([...transactionState.tags, ...newTags]);
        const allTags = Array.from(allTagsSet);

        if (allTags.length !== transactionState.tags.length) {
          setTransactionsState((prevState) => ({
            ...prevState,
            tags: allTags,
          }));
        }
      }
    },
    [transactionState.tags, setTransactionsState]
  );

  const clearState = useCallback(() => {
    setSelectedCategory(undefined);
    setSelectedBook(undefined);
    setSelectedPositionGroup(undefined);
    setSelectedStrategy(undefined);
    setSelectedTagsState([]);
    setNotes("");
  }, []);

  const applyField = useCallback(
    async (field: string) => {
      setSaving(true);
      if (accountState.selectedAccount?.id) {
        let updatedCashflows = props.cashflows;
        switch (field) {
          case "category":
            updatedCashflows = props.cashflows.map((cashflow) => {
              if (selectedCategory) {
                cashflow.category = selectedCategory;
              } else {
                cashflow.category = "";
              }
              return cashflow;
            });
            break;
          case "book":
            updatedCashflows = props.cashflows.map((cashflow) => {
              if (selectedBook) {
                cashflow.book = selectedBook;
              } else {
                cashflow.book = "";
              }
              return cashflow;
            });
            break;
          case "positionGroup":
            updatedCashflows = props.cashflows.map((cashflow) => {
              if (selectedPositionGroup) {
                cashflow.positionGroup = selectedPositionGroup;
              } else {
                cashflow.positionGroup = "";
              }
              return cashflow;
            });
            break;
          case "strategy":
            updatedCashflows = props.cashflows.map((cashflow) => {
              if (selectedStrategy) {
                cashflow.strategy = selectedStrategy;
              } else {
                cashflow.strategy = "";
              }
              return cashflow;
            });
            break;
          case "tags":
            updatedCashflows = props.cashflows.map((cashflow) => {
              if (selectedTagsState) {
                cashflow.tags = selectedTagsState;
              } else {
                cashflow.tags = [];
              }
              return cashflow;
            });
            break;
          case "notes":
            updatedCashflows = props.cashflows.map((cashflow) => {
              if (notes) {
                cashflow.notes = notes;
              } else {
                cashflow.notes = "";
              }
              return cashflow;
            });
            break;
        }

        await updateManyCashflows(
          updatedCashflows,
          accountState.selectedAccount?.id
        );
        props.onExplainCashflow();

        clearState();
      }
      setSaving(false);
      await loadReferenceData(true);
    },
    [
      props.cashflows,
      accountState.selectedAccount,
      selectedCategory,
      selectedBook,
      selectedPositionGroup,
      selectedStrategy,
      selectedTagsState,
      notes,
    ]
  );

  const saveChanges = useCallback(async () => {
    setSaving(true);
    if (accountState.selectedAccount?.id) {
      const updatedCashflows = props.cashflows.map((cashflows) => {
        if (selectedCategory) {
          cashflows.category = selectedCategory;
        } else {
          cashflows.category = "";
        }

        if (selectedStrategy) {
          cashflows.strategy = selectedStrategy;
        } else {
          cashflows.strategy = "";
        }

        if (selectedBook) {
          cashflows.book = selectedBook;
        } else {
          cashflows.book = "";
        }

        if (selectedPositionGroup) {
          cashflows.positionGroup = selectedPositionGroup;
        } else {
          cashflows.positionGroup = "";
        }

        if (selectedTagsState) {
          cashflows.tags = selectedTagsState;
        } else {
          cashflows.tags = [];
        }

        if (notes) {
          cashflows.notes = notes;
        } else {
          cashflows.notes = "";
        }

        return cashflows;
      });

      await updateManyCashflows(
        updatedCashflows,
        accountState.selectedAccount?.id
      );
      props.onExplainCashflow();

      clearState();
    }
    setSaving(false);
    await loadReferenceData(true);
  }, [
    props.cashflows,
    accountState.selectedAccount,
    selectedCategory,
    selectedBook,
    selectedPositionGroup,
    selectedStrategy,
    selectedTagsState,
    notes,
  ]);

  useEffect(() => {
    clearState();
    if (!loading && props.cashflows.length > 0) {
      setDeterminingState(true);
      setTimeout(() => {
        determineCategory();
        determineBooks();
        determinePositionGroups();
        determineStrategies();
        determineTags();
        determineNotes();
        setTimeout(() => {
          setDeterminingState(false);
        }, 0);
      }, 50);
    }
  }, [props.cashflows, loading]);

  useEffect(() => {
    loadReferenceData();
  }, [accountState.selectedAccount]);

  return {
    nbrCashflows: props.cashflows.length ?? 0,
    books,
    loading,
    selectedCategory,
    setSelectedCategory,
    selectedBook,
    setSelectedBook,
    positionGroups,
    selectedPositionGroup,
    setSelectedPositionGroup,
    tags,
    selectedTags: selectedTagsState,
    setSelectedTags,
    notes,
    setNotes,
    strategies,
    selectedStrategy,
    setSelectedStrategy,
    saveChanges,
    determiningState,
    saving,
    applyField,
  };
};
