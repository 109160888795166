import { useAtom, useAtomValue } from "jotai";
import { AccountAtom, InstrumentStateAtom } from "../store";
import { getInstruments, getInstrumentsByIds } from "../api";
import { Instrument, InstrumentRangeType } from "../types";
import { useEffect } from "react";

export interface UseInstruments {
  list: Instrument[];
  loadIds: (ids: number[]) => void;
  refresh: () => void;
}

export const useInstruments = (load: boolean = false): UseInstruments => {
  const account = useAtomValue(AccountAtom);
  const [instruments, setInstruments] = useAtom(InstrumentStateAtom);

  const mergeInstruments = (
    setA: Instrument[],
    setB: Instrument[]
  ): Instrument[] => {
    const ids = setA.map((x) => x.id);
    const addInstruments = setB.filter((x) => !ids.includes(x.id));
    return addInstruments.length > 0 ? [...setA, ...addInstruments] : setA;
  };

  const loadIds = async (ids: number[]) => {
    if (account.selectedAccount.id) {
      const instrumentIds = instruments ? instruments.map((x) => x.id) : [];
      const missingIds = ids.filter((x) => !instrumentIds.includes(x));
      const result = await getInstrumentsByIds(
        account.selectedAccount.id,
        missingIds
      );
      if (result) {
        await setInstruments((prevState) => {
          const current = prevState ? [...prevState] : [];
          return mergeInstruments(current, result);
        });
      }
    }
  };

  const loadInstruments = async (
    accountId: number,
    range: InstrumentRangeType
  ) => {
    const result = await getInstruments(accountId, range, false, true, true);
    if (result) {
      setInstruments((prevState) => {
        const current = prevState ? [...prevState] : [];
        return mergeInstruments(current, result);
      });
    }
  };

  const refresh = () => {
    // TODO: Implement refresh?
  };

  const init = async () => {
    if (account.selectedAccount.id) {
      await loadInstruments(
        account.selectedAccount.id,
        InstrumentRangeType.USER
      );
    }
  };

  useEffect(() => {
    init();
  }, []);

  return {
    list: instruments || [],
    refresh,
    loadIds,
  };
};
