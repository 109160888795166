import { useAtomValue } from "jotai";
import moment from "moment";
import { useState, useEffect, useCallback, useMemo } from "react";
import { GetCalendarNotes, CreateCalendarNote } from "../../api/CalendarNotes";
import convertSymbolToReadable from "../../components/grid/utils/convertSymbolToReadable";
import { AccountAtom, PositionStateAtom } from "../../store";
import { getEarnings } from "../../api/Earnings";
import { InstrumentType, Position } from "../../types";
import { distinctValues } from "../../utils";
import { useInstruments } from "../../hooks";

// Define types for better type safety
interface CalendarNote {
  id: string;
  content: string;
  calendarDateReference: string;
  date: string;
}

interface CalendarEvent {
  id: string;
  title: string;
  start: Date;
  end: Date;
  allDay: boolean;
  resourceId: string;
}

interface Trade {
  instrumentId: string;
  underlyingInstrumentId?: string;
  tradeDate: string;
  positionGroup?: string;
  symbol?: string;
  expiry?: string;
}

interface Earning {
  instrumentId: string;
  date: string;
  beforeAfterMarket: string;
  instrument?: {
    symbol: string;
  };
}

interface CalendarData {
  notes: CalendarNote[];
  expiries: Trade[];
  trades: Trade[];
  pnl: any[];
  numberOfTradedDays: number;
  totalPNL: any[];
}

interface useCalendarControllerProps {
  events: CalendarEvent[];
  month: string;
  day: string;
  loading: boolean;
  notes: CalendarNote[];
  selectedNote: CalendarNote | null;
  expiries: Trade[];
  setDay: (day: string) => void;
  setMonth: (month: string) => void;
  isOpen: boolean;
  accountState: any;
  expiriesForThisDate: Trade[];
  pnlByDate: any[];
  trades: Trade[];
  setIsOpen: (isOpen: boolean) => void;
  tradesForThisDate: Trade[];
  pnlThisMonth: any[];
  numberOfTradedDays: number;
  getNotes: (month: string) => void;
  createNote: (content: string, date: string) => void;
  earnings: Earning[];
  earningsForThisDate: Earning[];
}

export const useCalendarController = (): useCalendarControllerProps => {
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [month, setMonth] = useState(moment.utc().format("MMM YYYY"));
  const [day, setDay] = useState(moment.utc().format("DD MMM YYYY"));
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState<CalendarNote[]>([]);
  const [selectedNote, setSelectedNote] = useState<CalendarNote | null>(null);
  const [expiries, setExpiries] = useState<Trade[]>([]);
  const instruments = useInstruments();

  const [isOpen, setIsOpen] = useState(false);

  const accountState = useAtomValue(AccountAtom);
  const [expiriesForThisDate, setExpiriesForThisDate] = useState<Trade[]>([]);
  const [pnlByDate, setPnlByDate] = useState<any[]>([]);
  const [trades, setTrades] = useState<Trade[]>([]);
  const [tradesForThisDate, setTradesForThisDate] = useState<Trade[]>([]);
  const [pnlThisMonth, setPnlThisMonth] = useState<any[]>([]);
  const [numberOfTradedDays, setNumberOfTradedDays] = useState<number>(0);
  const [earnings, setEarnings] = useState<Earning[]>([]);
  const [earningsForThisDate, setEarningsForThisDate] = useState<Earning[]>([]);
  const positions = useAtomValue(PositionStateAtom);

  // Cache the account ID to prevent unnecessary API calls
  const accountId = useMemo(
    () => accountState?.selectedAccount?.id,
    [accountState?.selectedAccount?.id]
  );

  // Cache the open positions to prevent unnecessary calculations
  const openPositions = useMemo(
    () => positions?.openPositions || [],
    [positions?.openPositions]
  );

  // Get unique instrument IDs for earnings data
  const uniqueInstrumentIds = useMemo(() => {
    if (!positions?.positions?.length || !openPositions?.length) return [];

    // Type assertion to handle the position type
    const instrumentIds = (positions.positions as any[])
      .filter((position) => openPositions.includes(position.positionId))
      .map((position) => {
        if (position.instrumentType === InstrumentType.Equity) {
          return position.instrumentId;
        } else {
          return position.underlyingInstrumentId;
        }
      });

    return distinctValues(instrumentIds);
  }, [positions?.positions, openPositions]);

  // Memoize the getNotes function to prevent unnecessary recreations
  const getNotes = useCallback(
    async (month: string) => {
      if (!accountId || !month) {
        console.log("No account or month selected");
        return;
      }

      setLoading(true);

      try {
        // Fetch notes data
        const notes = await GetCalendarNotes(month, accountId);

        // Fetch earnings data only if we have instrument IDs
        let earningsData: Earning[] = [];
        if (uniqueInstrumentIds.length > 0) {
          // Type assertion to handle the API expecting number[] but receiving string[]
          earningsData = await getEarnings(
            uniqueInstrumentIds as unknown as number[]
          );
        }

        // Process earnings data
        const mapInstrumentDataToEarnings = earningsData.map(
          (earning: Earning) => ({
            ...earning,
            instrument: instruments.list.find(
              (x) => String(x.id) === String(earning.instrumentId)
            ),
          })
        );
        setEarnings(mapInstrumentDataToEarnings);

        // Process expiries data
        const expiriesEventArray = (notes.expiries || []).map(
          (expiry: Trade, index: number) => ({
            id: (expiry.symbol || "") + "-" + index,
            title: "Expiry: " + convertSymbolToReadable(expiry.symbol || ""),
            start: moment
              .utc(expiry.expiry)
              .endOf("day")
              .subtract(6, "hour")
              .toDate(),
            end: moment.utc(expiry.expiry).endOf("day").toDate(),
            allDay: false,
            resourceId: (expiry.symbol || "") + "-" + index,
          })
        );

        // Process trades data
        let tradesEventArray = (notes.trades || [])
          .map((trade: Trade, index: number) => {
            const ins = instruments.list.find(
              (x) => x.id === trade.instrumentId
            );
            if (!ins) return null;
            return {
              id: ins.symbol,
              title: "Trade: " + convertSymbolToReadable(ins.symbol),
              start: moment.utc(trade.tradeDate).toDate(),
              end: moment.utc(trade.tradeDate).add(3, "hour").toDate(),
              allDay: false,
              resourceId: ins.symbol + "-" + index,
            };
          })
          .filter(Boolean) as CalendarEvent[]; // Remove null entries


        // Deduplicate trades by ID
        tradesEventArray = tradesEventArray.reduce(
          (acc: CalendarEvent[], current: CalendarEvent) => {
            try {
              const x = acc.find(
                (item: CalendarEvent) => item.id === current.id
              );
              if (x) {
                return acc;
              } else {
                return acc.concat([current]);
              }
            } catch (e) {
              console.log(e);
              return acc;
            }
          },
          []
        );

        // Update state with fetched data
        setEvents([...expiriesEventArray, ...tradesEventArray]);
        setNotes(notes.notes || []);
        setExpiries(notes.expiries || []);
        setPnlByDate(notes.pnl || []);
        setNumberOfTradedDays(notes.numberOfTradedDays || 0);
        setPnlThisMonth(notes.totalPNL || []);
        setTrades(notes.trades || []);
      } catch (error) {
        console.error("Error fetching calendar data:", error);
      } finally {
        setLoading(false);
      }
    },
    [accountId, instruments.list, uniqueInstrumentIds]
  );

  // Fetch notes when account or month changes
  useEffect(() => {
    if (openPositions.length > 0 && accountId) {
      getNotes(month);
    }
  }, [openPositions.length, accountId, month, getNotes]);

  // Memoize the createNote function to prevent unnecessary recreations
  const createNote = useCallback(
    async (content: string, date: string) => {
      if (!accountId) return;

      setLoading(true);

      try {
        // Filter expiries for this date
        setExpiriesForThisDate(
          expiries.filter(
            (expiry) =>
              moment.utc(expiry.expiry, "YYYY-MM-DD").format("YYYY-MM-DD") ===
              date
          )
        );

        // Filter trades for this date
        setTradesForThisDate(
          trades.filter(
            (trade) => moment.utc(trade.tradeDate).format("YYYY-MM-DD") === date
          )
        );

        // Filter earnings for this date
        setEarningsForThisDate(
          earnings.filter(
            (earning) =>
              moment.utc(earning.date).format("YYYY-MM-DD") ===
              moment.utc(date).format("YYYY-MM-DD")
          )
        );

        // Check for existing note
        const existingNote = notes.find(
          (note) =>
            moment(note.calendarDateReference).format("YYYY-MM-DD") ===
            moment(date).format("YYYY-MM-DD")
        );

        if (!existingNote) {
          // Create new note if none exists
          const note = await CreateCalendarNote(date, content, accountId);
          setSelectedNote(note.note);
          setIsOpen(true);
          getNotes(month);
        } else {
          // Use existing note
          setSelectedNote(existingNote);
          setIsOpen(true);
        }
      } catch (error) {
        console.error("Error creating note:", error);
      } finally {
        setLoading(false);
      }
    },
    [accountId, expiries, trades, earnings, notes, month, getNotes]
  );

  return {
    events,
    earnings,
    month,
    day,
    loading,
    notes,
    selectedNote,
    expiries,
    isOpen,
    accountState,
    expiriesForThisDate,
    pnlByDate,
    trades,
    tradesForThisDate,
    pnlThisMonth,
    numberOfTradedDays,
    getNotes,
    createNote,
    setIsOpen,
    setDay,
    setMonth,
    earningsForThisDate,
  };
};
