import React from "react";
import { SyncSetting } from "../../../types";
import { Button } from "@chakra-ui/react";
import { BiTrash } from "react-icons/bi";

const DeleteSyncSettingsComponent = (props: {
  setting: SyncSetting;
  controller: any;
}) => {
  return (
    <div>
      <Button
        aria-label="Delete Sync Settings"
        bg="var(--red)"
        size="sm"
        onClick={async () => {
          if (
            window.confirm(
              `Are you sure you want to delete this sync setting? (${props.setting.name})`
            )
          ) {
            if (props.setting.syncSettingId !== undefined) {
              props.controller.deleteSyncSettingsFunc(
                props.setting.syncSettingId
              );
            } else {
              console.error("SyncSettingId is undefined");
            }
          }
        }}
      >
        <BiTrash />
      </Button>
    </div>
  );
};

export default DeleteSyncSettingsComponent;
