import { useMemo } from "react";
import { useAtomValue } from "jotai";
import { FilterStateAtom } from "../store/FilterStateAtom";
import { QuickFilterAtom } from "../store/QuickFilterAtom";

interface UseFilterHelper {
  filterEnabled: boolean;
  filteredUnderlying?: string;
  filteredBook?: string;
  filteredPositionGroup?: string;
  filteredStrategy?: string;
  filteredTags?: string;
}

export const useFilterHelper = (): UseFilterHelper => {
  const filters = useAtomValue(FilterStateAtom);
  const quickFilter = useAtomValue(QuickFilterAtom);

  const filterEnabled = useMemo(() => {
    return quickFilter.enabled || filters.selectedFilterId !== undefined;
  }, [quickFilter.enabled, filters.selectedFilterId]);

  const filteredUnderlying = useMemo(() => {
    if (quickFilter.enabled) {
      return quickFilter.underlyings.length > 0 &&
        quickFilter.underlyings[0] !== "All"
        ? quickFilter.underlyings[0]
        : undefined;
    }
    return filters.selectedUnderlying;
  }, [
    quickFilter.enabled,
    quickFilter.underlyings,
    filters.selectedUnderlying,
  ]);

  const filteredBook = useMemo(() => {
    if (quickFilter.enabled) {
      return quickFilter.books.length > 0 && quickFilter.books[0] !== "All"
        ? quickFilter.books[0]
        : undefined;
    }
    return filters.selectedBook;
  }, [quickFilter.enabled, quickFilter.books, filters.selectedBook]);

  const filteredPositionGroup = useMemo(() => {
    if (quickFilter.enabled) {
      return quickFilter.positionGroups.length > 0 &&
        quickFilter.positionGroups[0] !== "All"
        ? quickFilter.positionGroups[0]
        : undefined;
    }
    return filters.selectedPositionGroup;
  }, [
    quickFilter.enabled,
    quickFilter.positionGroups,
    filters.selectedPositionGroup,
  ]);

  const filteredStrategy = useMemo(() => {
    if (quickFilter.enabled) {
      return quickFilter.strategies.length > 0 &&
        quickFilter.strategies[0] !== "All"
        ? quickFilter.strategies[0]
        : undefined;
    }
    return filters.selectedStrategy;
  }, [quickFilter.enabled, quickFilter.strategies, filters.selectedStrategy]);

  const filteredTags = useMemo(() => {
    if (quickFilter.enabled) {
      return quickFilter.tags.length > 0 && quickFilter.tags[0] !== "All"
        ? quickFilter.tags[0]
        : undefined;
    }
    return filters.selectedTag;
  }, [quickFilter.enabled, quickFilter.tags, filters.selectedTag]);

  return {
    filterEnabled,
    filteredUnderlying: filterEnabled ? filteredUnderlying : undefined,
    filteredBook: filterEnabled ? filteredBook : undefined,
    filteredPositionGroup: filterEnabled ? filteredPositionGroup : undefined,
    filteredStrategy: filterEnabled ? filteredStrategy : undefined,
    filteredTags: filterEnabled ? filteredTags : undefined,
  };
};
