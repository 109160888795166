export const CASHFLOWS_BY_TYPE = {
  data: [
    {
      domain: "Deposit",
      amount: 0.14,
    },
    {
      domain: "Dividend",
      amount: 5375.53,
    },
    {
      domain: "Fee",
      amount: -279.67,
    },
    {
      domain: "Interest",
      amount: 118.47,
    },
    {
      domain: "Tax",
      amount: -826.03,
    },
  ],
  chartProperties: {
    name: "PnL By Cashflow Type",
    chartType: "bar",
    keys: ["amount"],
    colors: ["var(--chart-blue)"],
    legend: ["Amount"],
    domainType: "string",
    showEveryDomainLabel: true,
    flipAxis: true,
  },
  timeFrameType: "as_at",
  supported: true,
};
