import { GetContextMenuItemsParams } from "ag-grid-community";
import { distinct } from "../../utils";
import { updateCashflowsPositionKeyField } from "../../api/Cashflow";

export const updateBookForCashflows = async (
  value: string,
  cashflowIds: number[],
  accountId: number
) => {
  await updateCashflowsPositionKeyField(accountId, cashflowIds, "book", value);
};

export const updatePositionGroupForCashflows = async (
  value: string,
  cashflowIds: number[],
  accountId: number
) => {
  await updateCashflowsPositionKeyField(
    accountId,
    cashflowIds,
    "positionGroup",
    value
  );
};

export const updateHiddenFieldForCashflows = async (
  value: boolean,
  cashflowIds: number[],
  accountId: number
) => {
  await updateCashflowsPositionKeyField(
    accountId,
    cashflowIds,
    "hidden",
    value
  );
};

export const getCashflowIds = (params: GetContextMenuItemsParams): number[] => {
  let ids: number[] = [];

  const selectedRows = params.api.getSelectedRows();
  if (selectedRows.length > 0) {
    selectedRows.forEach((row) => {
      if (row.cashflowIds && row.cashflowIds.length > 0) {
        ids = [...ids, ...row.cashflowIds];
      }
    });
  }

  if (params?.node?.data !== undefined) {
    if (
      params?.node?.data?.cashflowIds &&
      params?.node?.data?.cashflowIds.length > 0
    ) {
      ids = [...ids, ...params?.node?.data?.cashflowIds];
    }
  } else if (!params.node?.allLeafChildren) {
    params.api.getSelectedNodes().map((node) => {
      if (
        node.data !== undefined &&
        node.data.cashflowIds &&
        node.data.cashflowIds.length > 0
      ) {
        ids = [...ids, ...node.data.cashflowIds];
      }
    });
  } else if (params?.node?.allLeafChildren) {
    params?.node?.allLeafChildren?.forEach((child) => {
      if (child.data.cashflowIds && child.data.cashflowIds.length > 0) {
        ids = [...ids, ...child.data.cashflowIds];
      }
    });
  }

  return distinct(ids);
};
