import React, { useCallback, useMemo, useRef } from "react";
import {
  Flex,
  HStack,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  Text,
  Button,
  VStack,
  Box,
  Input,
  Spacer,
  Divider,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Switch,
  Spinner,
  PopoverArrow,
  PopoverCloseButton,
} from "@chakra-ui/react";
import { FaChevronDown, FaPlus } from "react-icons/fa";
import { Filter } from "./Filter/Filter";
import { ToolbarFeatures, UserFilter } from "../../types";
import { useFilterSettingsController } from "./useFilterSettingsController";
import { Show } from "../../components";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import PopoverContentTransparent from "../../components/design_library/PopoverContentTransparent";
import DropDownButton from "../../components/design_library/DropDownButton";
import ActionButton from "../../components/design_library/ActionButton";
import {
  IoCheckmark,
  IoRadioButtonOff,
  IoRadioButtonOn,
} from "react-icons/io5";
import { SecondaryButton } from "../../components/design_library";
import CustomSwitch from "../../components/design_library/toolbar/Switch";

export const FilterSettings = () => {
  const renameFilterInput = useRef<HTMLInputElement>(null);
  const newFilterInput = useRef<HTMLInputElement>(null);
  const [showAlert, setShowAlert] = React.useState(false);
  const cancelRef = React.useRef<any>();

  const [editRow, setEditRow] = React.useState<number | undefined>(undefined);
  const [deleteFilter, setDeleteFilterRow] = React.useState<
    UserFilter | undefined
  >(undefined);

  const [expanded, setExpanded] = React.useState(false);

  const controller = useFilterSettingsController();

  const doDelete = async () => {
    if (deleteFilter?.id) {
      await controller.deleteFilter(deleteFilter.id);
    }
    setDeleteFilterRow(undefined);
    setShowAlert(false);
  };

  const confirmDelete = useCallback(() => {
    return (
      <AlertDialog
        isOpen={showAlert}
        leastDestructiveRef={cancelRef}
        onClose={() => setShowAlert(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete User Filter ({deleteFilter?.name})
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setShowAlert(false)}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={() => doDelete()} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    );
  }, [deleteFilter, showAlert]);

  const unFiltered = useMemo(() => {
    return !controller.filterApplied || !controller.selectedFilter;
  }, [controller.selectedFilter, controller.filterApplied]);

  return (
    <>
      {confirmDelete()}
      <Popover
        isOpen={expanded}
        onClose={() => setExpanded(false)}
        closeOnBlur={false}
        placement="bottom-start"
        arrowSize={15}
      >
        <PopoverTrigger>
          <Flex>
            <Flex>
              <DropDownButton
                aria-expanded={expanded}
                onClick={() => setExpanded(!expanded)}
              >
                <HStack cursor="pointer">
                  <Switch
                    isChecked={controller.filterApplied}
                    onChange={() => {
                      if (controller.filterApplied === true) {
                        controller.setSelectedFilterId(undefined);
                      }

                      controller.setFilterApplied(!controller.filterApplied);
                      setExpanded(false);
                    }}
                    colorScheme="green"
                  />

                  {!unFiltered && controller.selectedFilter && (
                    <Text
                      maxW="160px"
                      minWidth="60px"
                      overflow={"hidden"}
                      textAlign={"left"}
                      textOverflow={"ellipsis"}
                      whiteSpace={"nowrap"}
                    >
                      {controller.selectedFilter.name}
                    </Text>
                  )}
                  {unFiltered && <Text>No filter selected</Text>}
                </HStack>
              </DropDownButton>
            </Flex>
          </Flex>
        </PopoverTrigger>
        <PopoverContent p={0} mt="-4px" w="350px" bg="var(--bg)">
          <PopoverCloseButton />

          <PopoverArrow bg="var(--bg)" />
          <PopoverBody py={2} m={0} w="full">
            <Flex my={4}>
            <CustomSwitch
          id="quickFilter"
          isChecked={controller.quickFilterEnabled}
          onChange={(e: any) => {
            controller.toggleQuickFilter();
            if (expanded) {
              setExpanded(false);
            }
          }}
          mr={2}
          className="custom-switch-with-elipsis"
        >
          Show Quick Filter
        </CustomSwitch>
            </Flex>
            <Text fontSize="sm" fontWeight="bold">
              Filter
            </Text>
            <Flex direction="column" w="full" h="full">
              <Flex w="full">
                <VStack w="full" align="stretch" p={0} spacing={1}>
                  <VStack
                    maxHeight="260px"
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                    spacing={0}
                  >
                    {controller.filters.map((filter, index) => (
                      <Button
                        key={index}
                        variant="ghost"
                        justifyContent="space-between"
                        onClick={(e) => {
                          e.preventDefault();
                          controller.setSelectedFilterId(filter.id!);
                        }}
                        py={0}
                        px={2}
                        h="28px"
                        w={{
                          base: "100%",
                        }}
                        ml={0}
                        pl={0}
                      >
                        <HStack w="full">
                          <Show if={editRow !== filter.id}>
                            {controller.selectedFilter?.id !== filter.id ? (
                              <IoRadioButtonOff color="var(--white)" />
                            ) : (
                              <IoRadioButtonOn color="var(--white)" />
                            )}
                            <Text
                              maxW="100%"
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              fontSize={"14px"}
                            >
                              {filter.name}
                            </Text>
                          </Show>
                          <Show
                            if={editRow !== undefined && editRow === filter.id}
                          >
                            <Input
                              w="100%"
                              onClick={(e) => e.stopPropagation()}
                              ref={renameFilterInput}
                              defaultValue={filter.name}
                              onKeyUp={(e) => {
                                if (e.code === "Enter") {
                                  controller.renameFilter(
                                    filter.id!,
                                    renameFilterInput.current?.value ?? ""
                                  );
                                  setExpanded(false);
                                  setEditRow(undefined);
                                }
                              }}
                            />
                          </Show>
                          <Spacer />
                          <Tooltip label="Rename Filter">
                            <EditIcon
                              fontSize="12px"
                              onClick={(e) => {
                                e.stopPropagation();
                                setEditRow(filter.id);
                              }}
                              color="var(--white)"
                            />
                          </Tooltip>
                          <Tooltip label="Delete Filter">
                            <DeleteIcon
                              fontSize="12px"
                              color="var(--red)"
                              onClick={(e) => {
                                e.stopPropagation();
                                setDeleteFilterRow(filter);
                                setShowAlert(true);
                              }}
                            />
                          </Tooltip>
                        </HStack>
                      </Button>
                    ))}
                  </VStack>
                </VStack>
              </Flex>
              <Divider mt="4px" />

              <Flex w="full" mt={2}>
                {/* <Show if={controller.selectedFilter !== undefined}> */}
                <Filter
                  active={expanded}
                  close={() => {
                    controller.refreshFilters();
                    setExpanded(false);
                  }}
                  selectedFilter={controller.selectedFilter}
                  enabledOptions={[
                    ToolbarFeatures.UNDERLYING,
                    ToolbarFeatures.TAGS,
                    ToolbarFeatures.POSITION_GROUPING,
                    ToolbarFeatures.BOOKS,
                    ToolbarFeatures.DATE_RANGE,
                    ToolbarFeatures.STRATEGY,
                  ]}
                />
                {/* </Show> */}
                {/* <Show if={controller.selectedFilter === undefined}>
                  <Flex
                    w="full"
                    h="full"
                    border="1px solid"
                    borderColor="var(--gray)"
                    borderRadius={4}
                  >
                    <VStack
                      my={36}
                      w="full"
                      h="full"
                      align="center"
                      justify="center"
                    >
                      <Text color="var(--accent)">No Filter Selected</Text>
                      <Text color="var(--accent)">
                        Select or Create a Filter
                      </Text>
                    </VStack>
                  </Flex>
                </Show> */}
              </Flex>
            </Flex>

            <Divider mt="2" />
            <Flex direction="column" py={2}>
              <VStack w="full">
                <Input
                  ref={newFilterInput}
                  placeholder="Add new filter"
                  height="38px"
                  fontSize="14px"
                  onKeyUp={(e) => {
                    if (e.code === "Enter") {
                      e.preventDefault();
                      controller.createFilter(
                        newFilterInput.current?.value ?? ""
                      );
                      setExpanded(false);
                    }
                  }}
                />
                <SecondaryButton
                  w="full"
                  onClick={(e) => {
                    e.preventDefault();
                    controller.createFilter(
                      newFilterInput.current?.value ?? ""
                    );
                    // setExpanded(false);
                    newFilterInput.current!.value = "";
                  }}
                >
                  Create filter
                </SecondaryButton>
              </VStack>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
};
