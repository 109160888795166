import { CellAlignment } from "./../../components/tradingview/charting_library/charting_library.d";
import { useAtom, useAtomValue } from "jotai";
import {
  QuickFilterAtom,
  QuickFilterSettings,
} from "../../store/QuickFilterAtom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AccountAtom } from "../../store";
import { useInstruments } from "../../hooks";
import { InstrumentType } from "../../types";
import {
  getBooks,
  getGroupNames,
  getPositions,
  getStrategies,
  getTags,
  getUserInstrumentPrices,
} from "../../api";

interface UseQuickFilterController {
  quickFilter: QuickFilterSettings;
  toggleQuickFilter: () => void;
  underlyings: string[];
  setSelectedUnderlying: (underlying: string) => void;
  books: string[];
  setSelectedBook: (book: string) => void;
  positionGroups: string[];
  setSelectedPositionGroup: (positionGroup: string) => void;
  strategies: string[];
  setSelectedStrategy: (strategy: string) => void;
  tags: string[];
  setSelectedTag: (tag: string) => void;
  clear: () => void;
}

export const useQuickFilterController = (): UseQuickFilterController => {
  const [quickFilter, setQuickFilter] = useAtom(QuickFilterAtom);
  const accountState = useAtomValue(AccountAtom);

  const [loading, setLoading] = useState(false);
  const [underlyings, setUnderlyings] = useState<string[]>([]);
  const [books, setBooks] = useState<string[]>([]);
  const [strategies, setStrategies] = useState<string[]>([]);
  const [positionGroups, setPositionGroups] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);

  const loadReferenceData = () => {
    if (!loading && accountState.selectedAccount?.id) {
      setLoading(true);
      setTimeout(async () => {
        try {
          const bookResult = await getBooks(accountState.selectedAccount!.id);
          setBooks(["All", ...(bookResult ?? [])]);

          const strategyResult = await getStrategies(
            accountState.selectedAccount!.id
          );
          setStrategies(["All", ...(strategyResult ?? [])]);

          const positionGroupResult = await getGroupNames(
            accountState.selectedAccount!.id
          );
          setPositionGroups(["All", ...(positionGroupResult ?? [])]);

          const tagResult = await getTags(accountState.selectedAccount!.id);
          setTags(["All", ...(tagResult ?? [])]);

          const positions = await getPositions(
            accountState.selectedAccount!.id
          );
          if (positions !== undefined) {
            const underlyingSymbols = Array.from(
              new Set(
                positions.data.map((x: any) => {
                  if (x.underlyingType === "Future") {
                    return x.underlyingName;
                  } else {
                    return x.underlyingSymbol;
                  }
                })
              )
            ).sort() as string[];
            setUnderlyings([
              "All",
              ...underlyingSymbols.sort((a, b) => a.localeCompare(b)),
            ]);
          }
        } finally {
          setLoading(false);
        }
      }, 1);
    }
  };

  const toggleQuickFilter = useCallback(() => {
    setQuickFilter((prevState) => {
      return { ...prevState, enabled: !prevState.enabled };
    });
  }, []);

  const setSelectedUnderlying = useCallback((underlying: string) => {
    setQuickFilter((prevState) => {
      return { ...prevState, underlyings: [underlying] };
    });
  }, []);

  const setSelectedBook = useCallback((book: string) => {
    setQuickFilter((prevState) => {
      return { ...prevState, books: [book] };
    });
  }, []);

  const setSelectedPositionGroup = useCallback((positionGroup: string) => {
    setQuickFilter((prevState) => {
      return { ...prevState, positionGroups: [positionGroup] };
    });
  }, []);

  const setSelectedStrategy = useCallback((strategy: string) => {
    setQuickFilter((prevState) => {
      return { ...prevState, strategies: [strategy] };
    });
  }, []);

  const setSelectedTag = useCallback((tag: string) => {
    setQuickFilter((prevState) => {
      return { ...prevState, tags: [tag] };
    });
  }, []);

  const clear = useCallback(() => {
    setQuickFilter((prevState) => {
      return {
        ...prevState,
        underlyings: [],
        books: [],
        positionGroups: [],
        strategies: [],
        tags: [],
      };
    });
  }, []);

  useEffect(() => {
    loadReferenceData();
  }, [accountState.selectedAccount]);

  return {
    quickFilter,
    toggleQuickFilter,
    underlyings: underlyings,
    setSelectedUnderlying: setSelectedUnderlying,
    books: books,
    setSelectedBook: setSelectedBook,
    positionGroups: positionGroups,
    setSelectedPositionGroup: setSelectedPositionGroup,
    strategies: strategies,
    setSelectedStrategy: setSelectedStrategy,
    tags: tags,
    setSelectedTag: setSelectedTag,
    clear,
  };
};
