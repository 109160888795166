import { useMemo } from "react";
import { AuditResult, Position, TradeEx } from "../../types";
import { distinct } from "../../utils";
import { useQuery } from "@tanstack/react-query";
import { AccountAtom } from "../../store";
import { useAtomValue } from "jotai";
import { fetchPositionAudit } from "../../api/Audit";

interface UsePositionAuditController {
  underlyingName: string | undefined;
  positionGroup: string | undefined;
  data: AuditResult[];
}

export const usePositionAuditController = (
  positions: Position[],
  trades?: TradeEx[]
): UsePositionAuditController => {
  const accountState = useAtomValue(AccountAtom);

  // TODO: symbol when single position sleected to filter audit
  const symbol: string | undefined = useMemo(() => {
    if (positions.length === 1) {
      return positions[0].instrument.symbol;
    }
    return undefined;
  }, [positions]);

  const underlyingName: string | undefined = useMemo(() => {
    const values = distinct(
      positions.map((position) => position.underlyingName)
    );

    if (values.length === 1) {
      return values[0] as unknown as string;
    }
    return undefined;
  }, [positions]);

  const positionGroup = useMemo(() => {
    const values = distinct(
      positions.map((position) => position.positionGroup)
    );
    if (values.length === 1) {
      return values[0] as unknown as string;
    }
    return undefined;
  }, [positions]);

  const result = useQuery({
    queryKey: ["position-audit", underlyingName, positionGroup],
    queryFn: async () => {
      return await fetchPositionAudit(
        accountState.selectedAccount?.id,
        underlyingName!,
        positionGroup!
      );
    },
    enabled:
      accountState.selectedAccount?.id !== undefined &&
      underlyingName !== undefined &&
      positionGroup !== undefined,
  });

  return {
    underlyingName: underlyingName,
    positionGroup: positionGroup,
    data: result.data ?? [],
  };
};
