import BookRender from "../../../components/grid/renderers/BookRender";
import { UnderlyingRender } from "../../../components/grid/renderers/UnderlyingRender";
import {
  CashflowDetailsRenderer,
  PositionGroupRender,
  TradeDetailsRender,
} from "../../../components";
import moment from "moment";

export const PositionsInnerRender = (params: any) => {
  if (params.node.field === "book") {
    return BookRender(params);
  } else if (params.node.field === "underlyingName") {
    return UnderlyingRender(params);
  } else if (params.node.field === "positionName") {
    return PositionGroupRender(params);
  } else if (params.node.field === "positionDate") {
    return `${moment(params.valueFormatted).calendar(null, {
      lastDay: "[Yesterday]",
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      lastWeek: "[last] dddd",
      nextWeek: "dddd",
      sameElse: "L",
    })} (${moment(params.valueFormatted).fromNow()})`;
  } else if (params.node.field === "instrument.expiry") {
    if (
      !params.value ||
      `${moment(params.value).format("DD MMM YYYY")}` === "Invalid date"
    ) {
      return "No expiry";
    }
    return `${moment(params.value).format("DD MMM YYYY")}`;
  } else {
    if (!params?.data?.positionType || params?.data?.positionType === "Trade") {
      return TradeDetailsRender(params, "Positions");
    } else {
      return CashflowDetailsRenderer(params, "Positions");
    }
  }
};
