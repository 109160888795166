import React, { useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";

interface ClosePositionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  closingPrice: string;
  setClosingPrice: (value: string) => void;
  closingDate: string;
  setClosingDate: (value: string) => void;
  closingFees: string;
  setClosingFees: (value: string) => void;
  closingCommission: string;
  setClosingCommission: (value: string) => void;
  closingNotes: string;
  setClosingNotes: (value: string) => void;
}

const ClosePositionModal: React.FC<ClosePositionModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  closingPrice,
  setClosingPrice,
  closingDate,
  setClosingDate,
  closingFees,
  setClosingFees,
  closingCommission,
  setClosingCommission,
  closingNotes,
  setClosingNotes,
}) => {
  const handleNumericInput = (
    value: string,
    setter: (value: string) => void
  ) => {
    // Allow only numbers and a single decimal point
    if (/^\d*\.?\d*$/.test(value)) {
      setter(value);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setClosingPrice("");
      setClosingDate("");
      setClosingFees("");
      setClosingCommission("");
      setClosingNotes("");
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="var(--bg)">
        <ModalHeader>Close Position</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Closing Price</FormLabel>
            <Input
              placeholder="0.00"
              value={closingPrice}
              onChange={(e) =>
                handleNumericInput(e.target.value, setClosingPrice)
              }
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Closing Date</FormLabel>
            <Input
              type="date"
              value={closingDate}
              onChange={(e) => setClosingDate(e.target.value)}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Closing Fees (-ve)</FormLabel>
            <Input
              placeholder="0.00"
              value={closingFees}
              onChange={(e) =>
                handleNumericInput(e.target.value, setClosingFees)
              }
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Closing Commission (-ve)</FormLabel>
            <Input
              placeholder="0.00"
              value={closingCommission}
              onChange={(e) =>
                handleNumericInput(e.target.value, setClosingCommission)
              }
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Closing Notes</FormLabel>
            <Input
              placeholder="Enter notes for trade..."
              value={closingNotes}
              onChange={(e) => setClosingNotes(e.target.value)}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            bg="var(--accent)"
            mr={3}
            onClick={onConfirm}
            isDisabled={!closingPrice || !closingDate}
          >
            Confirm Close
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ClosePositionModal;
