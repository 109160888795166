import React from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Spinner,
  Tag,
  Text,
  useToast,
  VStack,
  Heading,
  Divider,
  useColorModeValue,
  Badge,
  Alert,
  AlertIcon,
  Tooltip,
  Container,
} from "@chakra-ui/react";
import { CopyIcon, InfoIcon } from "@chakra-ui/icons";
import { BiTrash, BiPlus } from "react-icons/bi";
import { useAPIKeysManagementPanelController } from "./useAPIKeysManagementPanelController";
import NoFeatureFlagAPIKeys from "./NoFeatureFlagAPIKeys";
import { AppStateAtom } from "../../store";
import { useAtomValue } from "jotai";

export default function APIKeysManagementPanel() {
  const toast = useToast();
  const { apiKeysList, loading, handleDeleteKey, handleCreateKey } =
    useAPIKeysManagementPanelController();
  const appState = useAtomValue(AppStateAtom);
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast({
      title: "Copied to clipboard",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  if (!appState?.featureFlags?.includes("api_keys")) {
    return <NoFeatureFlagAPIKeys />;
  }

  return (
    <Container maxW="container.xl" p={4}>
      <Box 
        w="full" 
        h="100vh" 
        overflowY="auto" 
        overflowX="hidden" 
        pb={20}
      >
        <Flex 
          w="full" 
          rounded="lg" 
          direction="column" 
          bg={useColorModeValue("var(--bg)", "gray.900")}
        >
          <Box 
            shadow="sm" 
            p={6} 
            rounded="lg" 
            w="full"
            borderWidth="1px"
            borderColor={borderColor}
          >
            <Flex justifyContent="space-between" alignItems="center" mb={6}>
              <Heading size="md">API Keys Management</Heading>
              <Tooltip label="API keys allow external applications to access your account data">
                <InfoIcon />
              </Tooltip>
            </Flex>
            
            <Divider mb={6} />
            
            <VStack spacing={6} align="stretch">
              {apiKeysList.length === 0 && !loading && (
                <Alert status="info" borderRadius="md">
                  <AlertIcon />
                  <Text color="var(--white)">
                    You don't have any API keys yet. Click the button below to
                    generate a new one.
                  </Text>
                </Alert>
              )}

              {loading ? (
                <Flex justify="center" py={8}>
                  <Spinner size="lg" />
                </Flex>
              ) : (
                apiKeysList.map((key: any) => (
                  <Box
                    key={key.id}
                    width="full"
                    bg={bgColor}
                    shadow="sm"
                    rounded="lg"
                    p={4}
                    borderWidth="1px"
                    borderColor={borderColor}
                    position="relative"
                  >
                    <Flex 
                      justifyContent="space-between" 
                      w="100%" 
                      alignItems="center"
                    >
                      <HStack maxW="60%">
                        <Text 
                          color="var(--white)" 
                          isTruncated 
                          fontFamily="mono"
                          fontWeight="medium"
                        >
                          {key.key}
                        </Text>
                        <Tooltip label="Copy to clipboard">
                          <IconButton
                            onClick={() => copyToClipboard(key.key)}
                            bg="transparent"
                            _hover={{ bg: "gray.700" }}
                            icon={<CopyIcon />}
                            aria-label="Copy API key"
                            size="sm"
                          />
                        </Tooltip>
                      </HStack>
                      
                      <HStack spacing={4}>
                        <VStack spacing={0} align="flex-end">
                          <Text 
                            color="var(--white)" 
                            fontSize="md"
                            fontWeight="semibold"
                          >
                            {key.count || 0}
                          </Text>
                          <Text color="var(--light-gray)" fontSize="xs">
                            API Calls
                          </Text>
                        </VStack>
                        
                        <Badge 
                          colorScheme="blue" 
                          fontSize="sm" 
                          px={2} 
                          py={1} 
                          borderRadius="md"
                        >
                          {key.type}
                        </Badge>
                        
                        <Tooltip label="Delete API key">
                          <IconButton
                            onClick={() => handleDeleteKey(key.id)}
                            bg="transparent"
                            _hover={{ bg: "red.700", color: "white" }}
                            icon={<BiTrash color="var(--red)" fontSize="20px" />}
                            aria-label="Delete API key"
                            size="md"
                          />
                        </Tooltip>
                      </HStack>
                    </Flex>
                  </Box>
                ))
              )}

              <Button 
                w="full" 
                onClick={handleCreateKey} 
                colorScheme="blue"
                leftIcon={<BiPlus />}
                size="lg"
                mt={4}
                bg="var(--accent)"
                color="white"
                _hover={{ bg: "var(--accent)", opacity: 0.9 }}
              >
                Generate New API Key
              </Button>
            </VStack>
          </Box>
        </Flex>
      </Box>
    </Container>
  );
}
