export const DIVIDENDS_BY_UNDERLYING = {
  data: [
    {
      domain: "BIL",
      amount: 384.3,
    },
    {
      domain: "JEPI",
      amount: 108.78,
    },
    {
      domain: "PFLT",
      amount: 79.55,
    },
    {
      domain: "SVOL",
      amount: 253.47,
    },
    {
      domain: null,
      amount: 26.34,
    },
  ],
  chartProperties: {
    name: "Dividends by Underlying",
    chartType: "bar",
    keys: ["amount"],
    colors: ["var(--chart-blue)"],
    legend: ["Amount"],
    domainType: "string",
    showEveryDomainLabel: true,
    flipAxis: false,
  },
  timeFrameType: "window",
  supported: true,
};
