import { APIEndpoint } from ".";
import { CashFlow } from "../types/Cashflow";

const token = localStorage.getItem("token");

// Create a new cash flow
export const createCashFlow = async (cashFlow: CashFlow, accountId: number) => {
  return fetch(`${APIEndpoint}/cashflows`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token || "",
      AccountId: accountId as any,
    },
    body: JSON.stringify(cashFlow),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error creating cash flow:", error));
};

// Bulk create new cash flows
export const createManyCashFlows = async (
  cashFlows: CashFlow[],
  accountId: number
) => {
  return fetch(`${APIEndpoint}/cashflows/bulk`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token || "",
      AccountId: accountId as any,
    },
    body: JSON.stringify(cashFlows),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error creating cash flows:", error));
};

export const getCashFlowsByIds = async (
  accountId: number,
  cashflowIds: number[]
): Promise<CashFlow[]> => {
  const url = `${APIEndpoint}/cashflows/byIds?ids=${cashflowIds.join(",")}`;
  const result = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: token || "",
      AccountId: accountId as any,
    },
  });

  if (result.ok) {
    const data = await result.json();
    if (data.success && data.data) {
      const cashflows = data.data.map((x: CashFlow) => {
        return { ...x } as CashFlow;
      });

      return cashflows;
    }
  }

  return [];
};

// Read (get) all cash flows
export const getCashFlows = async (
  accountId: number,
  fromDate?: string,
  toDate?: string
) => {
  const url =
    fromDate && toDate
      ? `${APIEndpoint}/cashflows?toDate=${toDate}&fromDate=${fromDate}`
      : `${APIEndpoint}/cashflows?fromDate=2020-02-21T23:59:59.999Z&toDate=${new Date(
          new Date().setFullYear(new Date().getFullYear() + 1)
        ).toISOString()}`;
  return fetch(url, {
    method: "GET",
    headers: {
      Authorization: token || "",
      AccountId: accountId as any,
    },
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error getting cash flows:", error));
};

// Update an existing cash flow
export const updateCashFlow = async (
  cashFlowId: number,
  cashFlow: CashFlow,
  accountId: number
) => {
  return fetch(`${APIEndpoint}/cashflows/${cashFlowId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token || "",
      AccountId: accountId as any,
    },
    body: JSON.stringify(cashFlow),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error updating cash flow:", error));
};

// Delete an existing cash flow
export const deleteCashFlow = async (cashFlowId: number, accountId: number) => {
  return fetch(`${APIEndpoint}/cashflows/${cashFlowId}`, {
    method: "DELETE",
    headers: {
      Authorization: token || "",
      AccountId: accountId as any,
    },
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error deleting cash flow:", error));
};

// Delete all cash flows
export const deleteAllCashFlows = async (accountId: number) => {
  return fetch(`${APIEndpoint}/cashflows`, {
    method: "DELETE",
    headers: {
      Authorization: token || "",
      AccountId: accountId as any,
    },
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error deleting all cash flows:", error));
};

export const updateManyCashflows = async (
  cashflows: CashFlow[],
  accountId: number
) => {
  return fetch(`${APIEndpoint}/cashflows/bulk`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token || "",
      AccountId: accountId as any,
    },
    body: JSON.stringify(cashflows),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error updating cashflow:", error));
};

export const getCashflowsWithoutCategory = async () => {
  const url = `${APIEndpoint}/cashflows/distinct/category`;

  return fetch(url, {
    method: "GET",
    headers: {
      Authorization: token || "",
    },
  })
    .then((response) => response.json())
    .catch((error) =>
      console.error("Error getting distinct cash flows types:", error)
    );
};

export const categorizeCashflows = async () => {
  return fetch(`${APIEndpoint}/cashflows/distinct/category`, {
    method: "POST",
    headers: {
      Authorization: token || "",
    },
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error categorizing cash flows:", error));
};

export const updateCashflowsPositionKeyField = async (
  accountId: number,
  ids: number[],
  field: string,
  value: string
) => {
  return fetch(`${APIEndpoint}/cashflows/updatePositionKeyField`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token || "",
      AccountId: accountId as any,
    },
    body: JSON.stringify({
      ids,
      field,
      value,
    }),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error updating cashflow:", error));
};

export const updateCashflowsBookAndPositionGroup = async (
  accountId: number,
  ids: number[],
  book: string,
  positionGroup: string
) => {
  return fetch(`${APIEndpoint}/cashflows/updateBookAndPositionGroup`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token || "",
      AccountId: accountId as any,
    },
    body: JSON.stringify({
      ids,
      book,
      positionGroup,
    }),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error updating cashflow:", error));
};
