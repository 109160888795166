import { APIEndpoint } from ".";
import { Account } from "../types/Account";

export const createReferrals = async (emails: string[]) => {
  const token = localStorage.getItem("token");

  return fetch(`${APIEndpoint}/referrals`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token || ""}`,
    },
    body: JSON.stringify(emails),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error creating account:", error));
};

export const getReferrals = async () => {
  const token = localStorage.getItem("token");

  return fetch(`${APIEndpoint}/referrals`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token || ""}`,
    },
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error getting referrals:", error));
};

export const getReferAFriendValue = async () => {
  const token = localStorage.getItem("token");

  return fetch(`${APIEndpoint}/referrals/referafriend`, {
    method: "GET",
    headers: {
      Authorization: `${token || ""}`,
    },
  })
    .then((response) => response.json())
    .catch((error) =>
      console.error("Error getting refer a friend value:", error)
    );
};
