import React from "react";
import {
  Box,
  HStack,
  VStack,
  Heading,
  Text,
  Tag,
  Divider,
  Badge,
  Flex,
  IconButton,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { InsightTemplate } from "../../../../types/Insight";
import { InsightTypeIcon } from "../../../../components/insights/InsightTypeIcon";
import InsightErrorBoundary from "../../../dashboard/canvas/insight/InsightRenderers/InsightErrorBoundary";
import { InsightRenderer2 } from "../../container";
import { DeleteIcon, EditIcon, CheckIcon } from "@chakra-ui/icons";
import { useInsightsController } from "../../InsightsManager/useInsightsController";

interface InsightCardProps {
  insight: InsightTemplate;
  enabled: boolean;
  handleToggleInsight: (insight: InsightTemplate) => void;
  isOwner: boolean;
  setPage: (page: string) => void;
  setInsightData: (insight: InsightTemplate) => void;
  displayDemoData: boolean;
}

const InsightCard: React.FC<InsightCardProps> = ({
  insight,
  enabled,
  handleToggleInsight,
  isOwner,
  setPage,
  setInsightData,
  displayDemoData,
}) => {
  const controller = useInsightsController();
  
  // Prevent event propagation for action buttons
  const handleEditClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setInsightData(insight);
    setPage("creator");
  };
  
  const handleDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    controller.handleDelete(insight.insightId as number);
  };

  // Determine card size based on insight type
  const isWideCard = insight.type.toLowerCase().includes("chart") || 
                     insight.type.toLowerCase().includes("table") || 
                     insight.type.toLowerCase().includes("comparison") || 
                     insight.type.toLowerCase().includes("map");
  
  // Colors
  const borderColor = enabled ? "var(--green)" : "var(--light-gray)";
  const hoverBg = "var(--dark-gray)";
  const iconBg = enabled ? "var(--green)" : "var(--gray)";

  return (
    <Box
      px={4}
      py={3}
      borderRadius="lg"
      transition="all 0.2s ease"
      h="100%"
      cursor="pointer"
      maxW={isWideCard ? "95vw" : "500px"}
      position="relative"
      boxShadow="0 4px 20px rgba(0, 0, 0, 0.15)"
      backdropFilter="blur(7.5px)"
      border={`2px solid ${borderColor}`}
      _hover={{
        bg: hoverBg,
        transform: "translateY(-2px)",
        boxShadow: "0 6px 24px rgba(0, 0, 0, 0.2)",
      }}
      onClick={() => handleToggleInsight(insight)}
    >
      {/* Status indicator */}
      {enabled && (
        <Badge 
          position="absolute" 
          top="-8px" 
          right="-8px" 
          borderRadius="full" 
          bg="var(--green)" 
          px={2}
          py={1}
          zIndex={1}
        >
          <CheckIcon mr={1} />
          Active
        </Badge>
      )}

      <VStack spacing={3} h="100%" align="stretch">
        {/* Header */}
        <HStack spacing={3} align="center">
          <Box
            w="36px"
            h="36px"
            borderRadius="md"
            bg={iconBg}
            color="white"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexShrink={0}
          >
            <InsightTypeIcon type={insight.type} />
          </Box>

          <Heading
            fontSize="16px"
            fontWeight="600"
            isTruncated
            flex="1"
            lineHeight="1.2"
          >
            {insight.title}
          </Heading>
        </HStack>

        {/* Preview */}
        <Box 
          flex="1" 
          minH="100px" 
          borderRadius="md" 
          overflow="hidden"
          bg="rgba(0,0,0,0.1)"
        >
          <InsightErrorBoundary>
            <Box h="100%" w="100%" p={2}>
              <InsightRenderer2 insight={insight} demo={displayDemoData} />
            </Box>
          </InsightErrorBoundary>
        </Box>

        {/* Description */}
        <Text
          fontSize="sm"
          color="var(--light-gray)"
          noOfLines={2}
          title={insight.description}
        >
          {insight.description}
        </Text>

        <Divider />

        {/* Footer */}
        <Flex justify="space-between" align="center">
          <HStack spacing={2}>
            {insight.official && (
              <Tag size="sm" colorScheme="green" variant="solid">Official</Tag>
            )}
            {insight.isDefaultInsight && (
              <Tag size="sm" colorScheme="blue" variant="solid">Recommended</Tag>
            )}
          </HStack>

          {isOwner && (
            <HStack spacing={1}>
              <Tooltip label="Edit insight" placement="top">
                <IconButton
                  aria-label="Edit insight"
                  icon={<EditIcon />}
                  size="sm"
                  variant="ghost"
                  onClick={handleEditClick}
                />
              </Tooltip>
              <Tooltip label="Delete insight" placement="top">
                <IconButton
                  aria-label="Delete insight"
                  icon={<DeleteIcon />}
                  size="sm"
                  variant="ghost"
                  colorScheme="red"
                  onClick={handleDeleteClick}
                />
              </Tooltip>
            </HStack>
          )}
        </Flex>
      </VStack>
    </Box>
  );
};

export default InsightCard;
