import { useMemo, useRef } from "react";
import { PayoffProps } from "./Payoff";
import { PositionRow } from "./PositionRow";
import { PriceSeries } from "./PriceSeries";
import Big from "big.js";
import { InstrumentType, Position, PutCall } from "../../types";
import { distinct } from "../../utils";
import { min } from "moment";

interface UsePayoffController {
  supported: boolean;
  options: PositionRow[];
  data: PriceSeries[];
  underlyingPrice: number;
  positionId: string;
}

interface DecomposedPositions {
  options: Position[];
  equities: Position[];
  cashflows: Position[];
  futures: Position[];
  netProceeds: number;
}

export const usePayoffController = (
  props: PayoffProps
): UsePayoffController => {
  const supported = useRef<boolean>(true);

  const positionId = useMemo(() => {
    return props.positions.map((x) => x.id).join(",");
  }, [props.positions]);

  const underlyingPrice = useMemo(() => {
    return props.positions[0].underlyingPrice ?? 0;
  }, [props.positions]);

  // break down positions into options, equities, cashflows, futures
  // and compute the total net proceeds
  const decomposedPositions: DecomposedPositions = useMemo(() => {
    const result: DecomposedPositions = {
      options: [],
      equities: [],
      cashflows: [],
      futures: [],
      netProceeds: 0,
    };

    // initialise state
    supported.current = true;

    const underlyings = distinct(
      props.positions.map((x) => x.underlyingSymbol)
    );

    // can only support one underlying
    if (underlyings.length > 1) {
      supported.current = false;
      return result;
    }

    result.options = props.positions.filter(
      (position) =>
        (position.instrument?.instrumentType?.toLowerCase() ?? "") ===
          InstrumentType.Option.toLowerCase() && position.quantity !== 0
    );

    const expires = distinct(result.options.map((x) => x.instrument.expiry));

    if (supported.current) {
      result.equities = props.positions.filter(
        (position) =>
          (position.instrument?.instrumentType?.toLowerCase() ?? "") ===
            InstrumentType.Equity.toLowerCase() && position.quantity !== 0
      );

      // NOT USED - at the moment cash flows are summed up in the net proceeds below
      // so that means they are applied to the chart as a value amount at each price point
      result.cashflows = props.positions.filter(
        (position) =>
          (position.instrument?.instrumentType?.toLowerCase() ?? "") ===
            InstrumentType.Cash.toLowerCase() && position.quantity !== 0
      );

      result.futures = props.positions.filter(
        (position) =>
          (position.instrument?.instrumentType?.toLowerCase() ?? "") ===
            InstrumentType.Future.toLowerCase() && position.quantity !== 0
      );

      result.netProceeds = props.positions.reduce(
        (acc, position) => acc + position.netProceeds,
        0
      );

      // must have at least one or more trade positions
      // FYI - we're not supporting futures for now
      if (
        (result.options.length === 0 && result.equities.length === 0) ||
        result.futures.length > 0
      ) {
        supported.current = false;
      }
    }

    return result;
  }, [props.positions]);

  const determineStep = (minPrice: number, maxPrice: number) => {
    const diff = maxPrice - minPrice;
    // 30 points beween min and max
    const step = new Big(diff).div(200).toNumber();

    let result = 0;
    if (step > 1.0) {
      result = Math.round(step);
    } else {
      result = new Big(step).round(1).toNumber();
    }

    // ensure we don't return 0 as we need to take steps up to the max
    return result <= 0 ? 0.1 : result;
  };

  const options = useMemo(() => {
    const items: PositionRow[] = [];
    if (supported) {
      decomposedPositions.options.forEach((option) => {
        items.push({
          type: option.instrument.instrumentType,
          buySell: option.quantity > 0 ? "buy" : "sell",
          putCall:
            (option.instrument?.putCall?.toLowerCase() as "put" | "call") ??
            "call",
          strikePrice: option.instrument.strike ?? 0,
          quantity: Math.abs(option.quantity),
          multiplier: option.instrument.multiplier ?? 100,
          expiryDate: option.instrument.expiry ?? "",
        });
      });
    }
    return items;
  }, [decomposedPositions]);

  const data = useMemo(() => {
    const items: PriceSeries[] = [];

    let minPrice = 0;
    let maxPrice = Number.MAX_VALUE;

    // try and determine the range for the chart, first based on the options
    if (decomposedPositions.options.length) {
      minPrice = Math.floor(
        Math.min(...options.map((o) => o.strikePrice!)) * 0.9
      );
      maxPrice = Math.ceil(
        Math.max(...options.map((o) => o.strikePrice!)) * 1.1
      );
    } else {
      // no options, try and determine the range based on the underlying price
      if (!isNaN(underlyingPrice) && underlyingPrice !== 0) {
        // range 30% below and 30% above the underlying price
        minPrice = Math.floor(underlyingPrice * 0.7);
        maxPrice = Math.ceil(underlyingPrice * 1.3);
      } else {
        // can't determine a range for the chart
        supported.current = false;
      }
    }

    // given the range determine the step
    const step = determineStep(minPrice, maxPrice);

    // starting from minPrice to maxPrice in steps of step create the price series with the net proceeds
    for (
      let i = minPrice;
      i <= maxPrice;
      i = new Big(i).plus(step).toNumber()
    ) {
      items.push({ price: i, value: decomposedPositions.netProceeds });
    }

    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      for (let o = 0; o < decomposedPositions.options.length; o++) {
        const option = decomposedPositions.options[o];
        const qty = new Big(option.quantity)
          .abs()
          .times(option.instrument.multiplier)
          .toNumber();
        const diff = Math.abs(option.instrument.strike! - item.price);
        const amount = qty * diff;
        if (
          option.instrument.putCall! === PutCall.Call &&
          option.instrument.strike! < item.price
        ) {
          item.value += option.quantity > 0 ? amount : -amount;
        } else if (
          option.instrument.putCall! === PutCall.Put &&
          option.instrument.strike! > item.price
        ) {
          item.value += option.quantity > 0 ? amount : -amount;
        }
      }

      for (let e = 0; e < decomposedPositions.equities.length; e++) {
        const equity = decomposedPositions.equities[e];
        const qty = new Big(equity.quantity)
          .abs()
          .times(equity.instrument.multiplier)
          .toNumber();
        const amount = qty * item.price;
        item.value += equity.quantity > 0 ? amount : -amount;
      }
    }

    return items;
  }, [decomposedPositions, options]);

  return {
    supported: supported.current,
    options: options,
    data: data,
    underlyingPrice,
    positionId,
  };
};
