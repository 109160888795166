import * as d3 from "d3";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { PriceSeries } from "./PriceSeries";
import { PositionRow } from "./PositionRow";
import { useColorMode, Text, Flex, HStack } from "@chakra-ui/react";
import { Show } from "../../components";
import { IoWarning } from "react-icons/io5";
import moment from "moment";

interface PayoffChartProps {
  positionId: string;
  options: PositionRow[];
  data: PriceSeries[];
  currentPrice: number;
}

let currentPositionId = "";

export const PayoffChart = ({
  options,
  data,
  currentPrice,
  positionId,
}: PayoffChartProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);
  const resizeTimeoutRef = useRef<number | null>(null);
  const { colorMode } = useColorMode();
  const isDarkMode = colorMode === "dark";
  const resizeObserver = useRef<ResizeObserver | null>(null);

  const multipleExpiries = useMemo(() => {
    return options.some(
      (option) => option.expiryDate !== options[0].expiryDate
    );
  }, [options]);

  const updateChart = useCallback(() => {
    // Get container dimensions
    const containerRect = containerRef.current!.getBoundingClientRect();
    const totalWidth = containerRect.width;
    const totalHeight = containerRect.height;

    // Clear previous chart
    const svgElement = d3.select(svgRef.current);
    svgElement.selectAll("*").remove();

    // Set dimensions with minimal margins!
    const margin = { top: 10, right: 5, bottom: 50, left: 50 };
    const width = totalWidth - margin.left - margin.right;
    const height = totalHeight - margin.top - margin.bottom;

    // Update SVG size with viewBox for better centering
    svgElement
      .attr("width", totalWidth)
      .attr("height", totalHeight + 20) // Add extra height for the x-axis label
      .attr("viewBox", `0 0 ${totalWidth} ${totalHeight + 15}`)
      .attr("preserveAspectRatio", "xMidYMid meet");

    // Create chart group with padding
    const chartGroup = svgElement
      .append("g")
      .attr(
        "transform",
        `translate(${margin.left + totalWidth * 0.001},${margin.top + 30})`
      ); // Added extra 30px to the top margin in the transform

    // Adjust width to account for the translation
    const adjustedWidth = width * 0.995; // 98% of available width

    // Theme-aware colors
    const textColor = isDarkMode ? "#e2e8f0" : "#1a202c"; // slate-200 for dark, slate-800 for light
    const gridColor = isDarkMode ? "#4a5568" : "#cbd5e0"; // slate-600 for dark, slate-300 for light
    const axisColor = isDarkMode ? "#718096" : "#4a5568"; // slate-500 for dark, slate-600 for light
    const buyColor = isDarkMode ? "#16a34a" : "#15803d"; // green-600 for dark, green-700 for light
    const sellColor = isDarkMode ? "#b91c1c" : "#991b1b"; // red-700 for dark, red-800 for light
    const currentPriceColor = isDarkMode ? "#b45309" : "#92400e"; // amber-700 for dark, amber-800 for light

    // Brighter shades for outlines
    const buyColorBright = isDarkMode ? "#22c55e" : "#22c55e"; // green-500
    const sellColorBright = isDarkMode ? "#ef4444" : "#ef4444"; // red-500
    const currentPriceColorBright = isDarkMode ? "#f59e0b" : "#f59e0b"; // amber-500

    const tooltipBgColor = isDarkMode ? "#1e293b" : "#f8fafc"; // slate-800 for dark, slate-50 for light
    const tooltipBorderColor = isDarkMode ? "#475569" : "#cbd5e0"; // slate-600 for dark, slate-300 for light
    const borderColor = isDarkMode ? "#4a5568" : "#e2e8f0"; // slate-600 for dark, slate-200 for light
    const xAxisBandColor = isDarkMode ? "#000000" : "#1e293b"; // pure black for dark, slate-800 for light
    const xAxisTextColor = "#ffffff"; // pure white for maximum contrast
    const zeroLineColor = isDarkMode ? "#64748b" : "#94a3b8"; // slate-500 for dark, slate-400 for light

    // Add chart border
    chartGroup
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", adjustedWidth)
      .attr("height", height)
      .attr("fill", "none")
      .attr("stroke", borderColor)
      .attr("stroke-width", 1)
      .attr("rx", 4)
      .attr("ry", 4);

    // Create scales with minimal padding
    const xDomain = d3.extent(data, (d) => d.price) as [number, number];
    const xPadding = (xDomain[1] - xDomain[0]) * 0.01; // Minimal padding

    const xScale = d3
      .scaleLinear()
      .domain([xDomain[0] - xPadding, xDomain[1] + xPadding])
      .range([0, adjustedWidth]);

    // Calculate y domain with 10% padding
    const yExtent = d3.extent(data, (d) => d.value) as [number, number];
    const yPadding = (yExtent[1] - yExtent[0]) * 0.05;

    const yScale = d3
      .scaleLinear()
      .domain([yExtent[0] - yPadding, yExtent[1] + yPadding])
      .range([height, 0]);

    // Create line generator for the continuous stroke
    const lineGenerator = d3
      .line<PriceSeries>()
      .x((d) => xScale(d.price))
      .y((d) => yScale(d.value))
      .curve(d3.curveLinear);

    // Process data to create proper zero crossings
    const processedData: PriceSeries[] = [];
    for (let i = 0; i < data.length - 1; i++) {
      const current = data[i];
      const next = data[i + 1];
      processedData.push(current);

      // If we cross zero, add an interpolated point at the zero crossing
      if (
        (current.value < 0 && next.value > 0) ||
        (current.value > 0 && next.value < 0)
      ) {
        const ratio =
          Math.abs(current.value) / Math.abs(next.value - current.value);
        const crossingPrice =
          current.price + (next.price - current.price) * ratio;
        processedData.push({ price: crossingPrice, value: 0 });
      }
    }
    processedData.push(data[data.length - 1]);

    // Create area generators for positive and negative areas
    const areaGenerator = d3
      .area<PriceSeries>()
      .x((d) => xScale(d.price))
      .y0(yScale(0))
      .y1((d) => yScale(d.value))
      .curve(d3.curveLinear);

    // Create clip path
    chartGroup
      .append("clipPath")
      .attr("id", "chart-area")
      .append("rect")
      .attr("width", adjustedWidth)
      .attr("height", height);

    // Add axes with theme-aware styling
    const xAxis = d3.axisBottom(xScale).ticks(20);
    const yAxis = d3.axisLeft(yScale).ticks(5);

    // Add y-axis with theme-aware styling
    chartGroup
      .append("g")
      .call(yAxis)
      .attr("font-size", "10px")
      .attr("color", axisColor)
      .selectAll("line")
      .attr("stroke", gridColor);

    // Create gradient for measuring tape effect with theme-aware colors
    const gradient = chartGroup
      .append("defs")
      .append("linearGradient")
      .attr("id", "tape-gradient")
      .attr("x1", "0%")
      .attr("y1", "0%")
      .attr("x2", "0%")
      .attr("y2", "100%");

    gradient
      .append("stop")
      .attr("offset", "0%")
      .attr("stop-color", xAxisBandColor)
      .attr("stop-opacity", 1);

    gradient
      .append("stop")
      .attr("offset", "100%")
      .attr("stop-color", xAxisBandColor)
      .attr("stop-opacity", 1);

    // Add measuring tape background with theme-aware styling
    chartGroup
      .append("rect")
      .attr("x", 0)
      .attr("y", yScale(0) - 15)
      .attr("width", adjustedWidth)
      .attr("height", 30)
      .attr("fill", xAxisBandColor)
      .attr("rx", 3)
      .attr("ry", 3);

    // Create custom axis with ticks on both sides
    const xAxisGroup = chartGroup
      .append("g")
      .attr("transform", `translate(0,${yScale(0)})`);

    // Add x-axis with theme-aware styling and centered text
    xAxisGroup
      .call(xAxis)
      .attr("font-size", "13px")
      .attr("color", xAxisTextColor)
      .attr("font-weight", "normal");

    // Center the x-axis labels vertically in the band
    xAxisGroup
      .selectAll(".tick text")
      .attr("dy", "-0.3em")
      .attr("opacity", (d, i) => (i % 2 === 0 ? 1 : 0.7))
      .attr("font-size", (d, i) => (i % 2 === 0 ? "14px" : "12px"));

    // Hide all axis lines completely
    xAxisGroup.selectAll(".tick line").attr("stroke-opacity", 0);

    // Remove the domain path (the horizontal line)
    xAxisGroup.select(".domain").attr("stroke-opacity", 0);

    // Update x-axis label position with theme-aware styling
    chartGroup
      .append("text")
      .attr("x", adjustedWidth / 2)
      .attr("y", height + 30)
      .style("text-anchor", "middle")
      .style("font-size", "14px")
      .style("font-weight", "bold")
      .style("fill", textColor)
      .text("Price");

    // Add y-axis label with theme-aware styling
    chartGroup
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", -margin.left - 15) // Move further left, away from the tickers
      .attr("x", -(height / 2))
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .style("font-size", "12px")
      .style("fill", textColor)
      .text("Profit / Loss");

    // Add areas with different colors
    const areas = chartGroup.append("g").attr("class", "areas");

    // Filter data for positive and negative values, including zero points
    const positiveData = processedData.filter((d, i, arr) => {
      if (d.value >= 0) return true;
      // Include the point if it's a zero crossing
      if (d.value === 0 && i > 0 && arr[i - 1].value > 0) return true;
      return false;
    });

    const negativeData = processedData.filter((d, i, arr) => {
      if (d.value <= 0) return true;
      // Include the point if it's a zero crossing
      if (d.value === 0 && i > 0 && arr[i - 1].value < 0) return true;
      return false;
    });

    // Add negative area
    areas
      .append("path")
      .datum(negativeData)
      .attr("class", "area-negative")
      .attr("clip-path", "url(#chart-area)")
      .attr("d", areaGenerator)
      .attr("fill", "rgba(255, 80, 80, 0.2)");

    // Add positive area
    areas
      .append("path")
      .datum(positiveData)
      .attr("class", "area-positive")
      .attr("clip-path", "url(#chart-area)")
      .attr("d", areaGenerator)
      .attr("fill", "rgba(0, 255, 0, 0.2)");

    // Add the negative line
    areas
      .append("path")
      .datum(negativeData)
      .attr("class", "line-negative")
      .attr("clip-path", "url(#chart-area)")
      .attr("d", lineGenerator)
      .attr("fill", "none")
      .attr("stroke", "rgb(255, 80, 80)")
      .attr("stroke-width", 1.5);

    // Add the positive line
    areas
      .append("path")
      .datum(positiveData)
      .attr("class", "line-positive")
      .attr("clip-path", "url(#chart-area)")
      .attr("d", lineGenerator)
      .attr("fill", "none")
      .attr("stroke", "rgb(0, 255, 0)")
      .attr("stroke-width", 1.5);

    // Add a horizontal line at y=0 (break-even line)
    chartGroup
      .append("line")
      .attr("x1", 0)
      .attr("x2", adjustedWidth)
      .attr("y1", yScale(0))
      .attr("y2", yScale(0))
      .attr("stroke", zeroLineColor)
      .attr("stroke-width", 1.5)
      .attr("stroke-dasharray", "3,3");

    // Add option strike price lines and annotations

    // Group options by strike price to handle stacking
    const optionsByStrike = new Map();
    options?.forEach((option) => {
      const strike = option.strikePrice!;
      if (!optionsByStrike.has(strike)) {
        optionsByStrike.set(strike, []);
      }
      optionsByStrike.get(strike).push(option);
    });

    // Find the maximum number of options at any strike price
    let maxOptionsAtStrike = 0;
    optionsByStrike.forEach((optionsAtStrike) => {
      maxOptionsAtStrike = Math.max(maxOptionsAtStrike, optionsAtStrike.length);
    });

    // Create a variable to store the current price marker group
    let currentPriceGroup: d3.Selection<
      SVGGElement,
      unknown,
      null,
      undefined
    > | null = null;
    let currentPriceTooltip: d3.Selection<
      SVGGElement,
      unknown,
      null,
      undefined
    > | null = null;
    let currentPriceMarkerData: {
      x: number;
      markerPath: string;
      verticalOffset: number;
      triangleHeight: number;
      boxHeight: number;
    } | null = null;

    // Process each strike price group
    optionsByStrike.forEach((optionsAtStrike, strikePrice) => {
      const x = xScale(strikePrice);
      const triangleSize = 20;
      const triangleHeight = 10;
      const boxHeight = 15;
      const verticalSpacing = -2; // Negative spacing to create overlap between annotations

      // Add a single vertical line for all options at this strike
      const firstOption = optionsAtStrike[0];
      const lineColor = firstOption.buySell === "buy" ? buyColor : sellColor;

      // Add the vertical line with theme-aware styling
      chartGroup
        .append("line")
        .attr("x1", x)
        .attr("x2", x)
        .attr("y1", 0)
        .attr("y2", height)
        .attr("stroke", lineColor)
        .attr("stroke-width", 1.5)
        .attr("stroke-dasharray", "3,3");

      // Reverse the options array to render them in reverse order
      // This ensures that options with higher indices (lower on the chart) are rendered last
      // and therefore appear on top in the z-index stacking context
      [...optionsAtStrike]
        .reverse()
        .forEach((option: PositionRow, reversedIndex: number) => {
          // Calculate the original index to maintain the same vertical positioning
          const index = optionsAtStrike.length - reversedIndex - 1;

          // Calculate vertical offset for stacking, with the first option at the top of the chart
          // and subsequent options stacked downward
          const verticalOffset =
            index * (triangleHeight + boxHeight + verticalSpacing);

          // Determine color based on buy/sell status
          const optionColor = option.buySell === "buy" ? buyColor : sellColor;
          const optionOutlineColor =
            option.buySell === "buy" ? buyColorBright : sellColorBright;

          // Create a group for the marker
          const markerGroup = chartGroup.append("g");

          // Add combined box and triangle marker with theme-aware styling
          const markerPath = `
          M ${x - triangleSize / 2} ${-boxHeight - verticalOffset}
          L ${x + triangleSize / 2} ${-boxHeight - verticalOffset}
          L ${x + triangleSize / 2} ${0 - verticalOffset}
          L ${x} ${triangleHeight - verticalOffset}
          L ${x - triangleSize / 2} ${0 - verticalOffset}
          Z`;

          // Create the marker path and make it interactive
          markerGroup
            .append("path")
            .attr("d", markerPath)
            .attr("fill", optionColor)
            .attr("stroke", optionOutlineColor)
            .attr("stroke-width", 1.5)
            .style("cursor", "pointer"); // Add cursor pointer to indicate interactivity

          // Add P/C text (adjusted position for box) with theme-aware styling and padding from top
          markerGroup
            .append("text")
            .attr("x", x)
            .attr("y", -boxHeight / 2 + 4 - verticalOffset)
            .attr("text-anchor", "middle")
            .attr("dominant-baseline", "middle")
            .attr("fill", "white")
            .style("font-size", "10px")
            .style("pointer-events", "none") // Prevent text from interfering with mouse events
            .text(
              option.putCall === "call"
                ? `${option.quantity}C`
                : `${option.quantity}P`
            );

          // Create SVG tooltip (initially hidden)
          const svgTooltip = markerGroup
            .append("g")
            .attr("class", "tooltip")
            .style("display", "none")
            .attr(
              "transform",
              `translate(0, ${triangleHeight + 10 - verticalOffset})`
            ); // Position below the marker

          // Add tooltip background
          svgTooltip
            .append("rect")
            .attr("x", x - 45)
            .attr("y", 0)
            .attr("width", 90)
            .attr("height", 66)
            .attr("fill", tooltipBgColor)
            .attr("stroke", tooltipBorderColor)
            .attr("rx", 2)
            .attr("ry", 2)
            .attr("opacity", 0.9);

          // Add tooltip text
          const tooltipText = svgTooltip
            .append("text")
            .attr("x", x)
            .attr("y", 18)
            .attr("text-anchor", "middle")
            .attr("fill", textColor)
            .attr("font-size", "12px");

          tooltipText
            .append("tspan")
            .attr("x", x)
            .attr("dy", "0em")
            .text(
              `${option.buySell === "buy" ? "Buy" : "Sell"} ${option.quantity}`
            )
            .append("tspan")
            .attr("x", x)
            .attr("dy", "18px")
            .text(`${moment(option.expiryDate).format("DD-MMM-YY")}`)
            .append("tspan")
            .attr("x", x)
            .attr("dy", "18px")
            .text(`${option.strikePrice} ${option.putCall!.toUpperCase()}`);

          // Add mouse events to show/hide SVG tooltip
          markerGroup
            .on("mouseover", function () {
              // Show tooltip
              svgTooltip.style("display", "block");

              // Highlight marker
              d3.select(this)
                .select("path")
                .attr("stroke", "white")
                .attr("stroke-width", 2.5);

              // Debug message
              console.log(
                "Showing SVG tooltip for option:",
                option.strikePrice
              );
            })
            .on("mouseout", function () {
              // Hide tooltip
              svgTooltip.style("display", "none");

              // Remove highlight
              d3.select(this)
                .select("path")
                .attr("stroke", optionOutlineColor)
                .attr("stroke-width", 1.5);
            });
        });
    });

    // Add current price line with theme-aware styling
    if (currentPrice) {
      const x = xScale(currentPrice);
      const triangleSize = 20;
      const triangleHeight = 10;
      const boxHeight = 15;
      const verticalSpacing = -10; // Negative spacing to match option annotations

      // Add the vertical line
      chartGroup
        .append("line")
        .attr("x1", x)
        .attr("x2", x)
        .attr("y1", 0)
        .attr("y2", height)
        .attr("stroke", currentPriceColor)
        .attr("stroke-width", 1.5)
        .attr("stroke-dasharray", "5,5");

      // Check if there are options at this price point
      const optionsAtCurrentPrice = optionsByStrike.get(currentPrice) || [];
      const numOptionsAtCurrentPrice = optionsAtCurrentPrice.length;

      // Calculate vertical offset for current price marker to avoid overlap
      const verticalOffset =
        numOptionsAtCurrentPrice *
        (triangleHeight + boxHeight + verticalSpacing);

      // Add marker annotation in the same style as option markers
      const markerPath = `
        M ${x - triangleSize / 2} ${-boxHeight - verticalOffset}
        L ${x + triangleSize / 2} ${-boxHeight - verticalOffset}
        L ${x + triangleSize / 2} ${0 - verticalOffset}
        L ${x} ${triangleHeight - verticalOffset}
        L ${x - triangleSize / 2} ${0 - verticalOffset}
        Z`;

      // Store the current price marker data to render it later
      currentPriceMarkerData = {
        x,
        markerPath,
        verticalOffset,
        triangleHeight,
        boxHeight,
      };
    }

    // Now render the current price marker after all option annotations to ensure highest z-index
    if (currentPriceMarkerData) {
      const { x, markerPath, verticalOffset, triangleHeight, boxHeight } =
        currentPriceMarkerData;

      // Create a group for the current price marker
      currentPriceGroup = chartGroup.append("g");

      currentPriceGroup
        .append("path")
        .attr("d", markerPath)
        .attr("fill", currentPriceColor)
        .attr("stroke", currentPriceColorBright)
        .attr("stroke-width", 1.5)
        .style("cursor", "pointer"); // Add cursor pointer to indicate interactivity

      // Add "$" text in the marker with 5px padding from top
      currentPriceGroup
        .append("text")
        .attr("x", x)
        .attr("y", -boxHeight / 2 + 4 - verticalOffset) // Adjusted for vertical offset
        .attr("text-anchor", "middle")
        .attr("dominant-baseline", "middle")
        .attr("fill", "white")
        .style("font-size", "12px")
        .style("pointer-events", "none") // Prevent text from interfering with mouse events
        .text("$");

      // Create SVG tooltip for current price (initially hidden)
      currentPriceTooltip = currentPriceGroup
        .append("g")
        .attr("class", "tooltip")
        .style("display", "none")
        .attr(
          "transform",
          `translate(0, ${triangleHeight + 10 - verticalOffset})`
        ); // Position below the marker

      // Add tooltip background
      currentPriceTooltip
        .append("rect")
        .attr("x", x - 40)
        .attr("y", 0)
        .attr("width", 80)
        .attr("height", 46)
        .attr("fill", tooltipBgColor)
        .attr("stroke", tooltipBorderColor)
        .attr("rx", 2)
        .attr("ry", 2)
        .attr("opacity", 0.9);

      // Add tooltip text
      const currentPriceTooltipText = currentPriceTooltip
        .append("text")
        .attr("x", x)
        .attr("y", 18)
        .attr("text-anchor", "middle")
        .attr("fill", textColor)
        .attr("font-size", "12px");

      currentPriceTooltipText
        .append("tspan")
        .attr("x", x)
        .attr("dy", "0em")
        .text(`Price`)
        .append("tspan")
        .attr("x", x)
        .attr("dy", "18px")
        .text(`${currentPrice.toFixed(2)}`);

      // Add mouse events to show/hide SVG tooltip for current price
      currentPriceGroup
        .on("mouseover", function () {
          // Hide all other tooltips first
          chartGroup.selectAll(".tooltip").style("display", "none");

          // Show this tooltip
          currentPriceTooltip!.style("display", "block");

          // Highlight marker
          d3.select(this)
            .select("path")
            .attr("stroke", "white")
            .attr("stroke-width", 2.5);

          // Debug message
          console.log("Showing SVG tooltip for current price");
        })
        .on("mouseout", function () {
          // Hide tooltip
          currentPriceTooltip!.style("display", "none");

          // Remove highlight
          d3.select(this)
            .select("path")
            .attr("stroke", currentPriceColorBright)
            .attr("stroke-width", 1.5);
        })
        .on("click", function () {
          // Toggle tooltip visibility on click for mobile support
          const isVisible = currentPriceTooltip!.style("display") === "block";
          currentPriceTooltip!.style("display", isVisible ? "none" : "block");

          // Toggle highlight
          if (!isVisible) {
            d3.select(this)
              .select("path")
              .attr("stroke", "white")
              .attr("stroke-width", 2.5);
          } else {
            d3.select(this)
              .select("path")
              .attr("stroke", currentPriceColorBright)
              .attr("stroke-width", 1.5);
          }
        });
    }

    // Create crosshair group with theme-aware styling
    const crosshair = chartGroup
      .append("g")
      .attr("class", "crosshair")
      .style("display", "none");

    crosshair
      .append("line")
      .attr("class", "crosshair-vertical")
      .attr("y1", 0)
      .attr("y2", height)
      .attr("stroke", gridColor)
      .attr("stroke-width", 1)
      .attr("stroke-dasharray", "3,3");

    // Add crosshair intersection dot with theme-aware styling
    crosshair
      .append("circle")
      .attr("class", "crosshair-dot")
      .attr("r", 4)
      .attr("fill", "white")
      .attr("stroke", "white")
      .attr("stroke-width", 2);

    // Create tooltip with theme-aware styling
    const tooltip = chartGroup
      .append("g")
      .attr("class", "tooltip")
      .style("display", "none");

    tooltip
      .append("rect")
      .attr("width", 100)
      .attr("height", 50)
      .attr("fill", tooltipBgColor)
      .attr("stroke", tooltipBorderColor)
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("opacity", 0.9);

    const tooltipText = tooltip
      .append("text")
      .attr("x", 15)
      .attr("y", 20)
      .attr("font-size", "12px")
      .attr("fill", textColor);

    tooltipText.append("tspan").attr("x", 10).attr("dy", "0em");
    tooltipText.append("tspan").attr("x", 10).attr("dy", "18px");

    // Add invisible overlay for mouse tracking
    chartGroup
      .append("rect")
      .attr("width", adjustedWidth)
      .attr("height", height)
      .attr("fill", "none")
      .attr("pointer-events", "all")
      .on("mousemove", function (event) {
        const [mouseX, mouseY] = d3.pointer(event);

        // Find the closest data point to the mouse position
        const mousePrice = xScale.invert(mouseX);

        // Find the closest data point by price
        let closestPoint = data[0];
        let minDistance = Math.abs(mousePrice - closestPoint.price);

        for (let i = 1; i < data.length; i++) {
          const distance = Math.abs(mousePrice - data[i].price);
          if (distance < minDistance) {
            minDistance = distance;
            closestPoint = data[i];
          }
        }

        // Get the x and y coordinates of the closest point
        const pointX = xScale(closestPoint.price);
        const pointY = yScale(closestPoint.value);

        // Update crosshair position to snap to the data point
        crosshair.style("display", "block");
        crosshair
          .select(".crosshair-vertical")
          .attr("x1", pointX)
          .attr("x2", pointX);

        // Update dot position to the exact data point
        crosshair
          .select(".crosshair-dot")
          .attr("cx", pointX)
          .attr("cy", pointY);

        // Update tooltip content and position
        tooltip.style("display", "block");

        // Position tooltip within 10px of the data point
        const tooltipX = pointX + 10;
        const tooltipY = pointY - 10;

        tooltip.attr(
          "transform",
          `translate(${
            tooltipX + 100 > adjustedWidth ? pointX - 110 : tooltipX
          },${tooltipY < 0 ? pointY + 10 : tooltipY})`
        );

        // Update tooltip text
        const tooltipTexts = tooltip.selectAll("tspan");
        if (tooltipTexts.size() >= 2) {
          tooltipTexts.each(function (d, i) {
            const element = d3.select(this);
            if (i === 0) {
              element.text(`Price: ${closestPoint.price.toFixed(2)}`);
            } else if (i === 1) {
              element.text(`P/L: ${closestPoint.value.toFixed(2)}`);
            }
          });
        }
      })
      .on("mouseout", function () {
        crosshair.style("display", "none");
        tooltip.style("display", "none");
      });
  }, [data, options, currentPrice, positionId]);

  const buildChart = useCallback(() => {
    // stops us rebuilding the chart multiple times
    if (currentPositionId === positionId) return;

    currentPositionId = positionId;

    // Initial render
    updateChart();

    // Set up resize observer with debounce
    resizeObserver.current = new ResizeObserver(() => {
      if (resizeTimeoutRef.current !== null) {
        window.clearTimeout(resizeTimeoutRef.current);
      }
      resizeTimeoutRef.current = window.setTimeout(() => {
        updateChart();
      }, 250); // 250ms debounce
    });

    resizeObserver.current?.observe(containerRef.current!);
  }, [data, options, currentPrice, positionId]);

  useEffect(() => {
    if (!svgRef.current || !containerRef.current || !data.length) return;

    buildChart();

    // Cleanup
    return () => {
      if (resizeTimeoutRef.current !== null) {
        window.clearTimeout(resizeTimeoutRef.current);
      }
      resizeObserver.current?.disconnect();
    };
  }, [data]);

  return (
    <div
      ref={containerRef}
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        paddingBottom: "20px", // Add padding at the bottom for the x-axis label
      }}
    >
      <svg
        ref={svgRef}
        style={{
          width: "100%",
          height: "100%",
          display: "block",
        }}
      />
      <Show if={multipleExpiries === true}>
        <Flex position={"absolute"} bottom="-5px" right={0} left={10} p={2}>
          <HStack spacing={2}>
            <IoWarning fill="orange" fontSize="24px" />
            <Text fontSize="14px">
              Position contains options which have different expiry dates
            </Text>
          </HStack>
        </Flex>
      </Show>
    </div>
  );
};
