import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { VStack, Text } from "@chakra-ui/react";
import {
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
  SelectionChangedEvent,
} from "ag-grid-community";
import {
  columnTypes,
  distinctValue,
  GridContainer,
  PositionGroupRender,
  Show,
  Spinner,
  UnderlyingRender,
} from "../../components";
import { cashflowsColumnDefinitions } from "./cashflowsColumnDefinitions";
import {
  overlayLoadingTemplate,
  overlayNoRowsTemplate,
} from "../../components/grid/NoRowsToShow";
import { useGridState } from "../../components/grid/useGridState";
import { CashFlowEx } from "../../types/Cashflow";
import BookRender from "../../components/grid/renderers/BookRender";
import { CategoryRenderer } from "../../components/grid/renderers/CategoryRenderer";

export const CashflowsInnerRenderer = (params: any) => {
  if (params.node.field === "book") {
    return BookRender(params);
  }
  if (params.node.field === "underlyingInstrument.symbol") {
    return UnderlyingRender(params);
  }

  if (params.node.field === "positionGroup") {
    return PositionGroupRender(params);
  }

  if (params.node.field === "category") {
    return CategoryRenderer(params);
  }

  return params.value;
};

interface CashflowsProps {
  data: CashFlowEx[];
  loading: boolean;
  selectedCashflows: CashFlowEx[];
  onSelectedRowChanged: (rows: CashFlowEx[]) => void;
}

export const Cashflows = (props: CashflowsProps) => {
  const { data, selectedCashflows } = props;

  const gridRef = useRef<GridApi | null>(null);

  const gridState = useGridState({ gridName: "Cashflows" });

  const onGridReady = useCallback((e: GridReadyEvent) => {
    gridRef.current = e.api;
    gridState.onGridReady(e);
  }, []);

  const onSelectionChanged = (e: SelectionChangedEvent) => {
    const selectedRows = e.api.getSelectedRows();

    props.onSelectedRowChanged(selectedRows);
  };

  const loadingOverlayComponent = useMemo(() => {
    return overlayLoadingTemplate;
  }, []);

  const loadingOverlayComponentParams = useMemo(() => {
    return {
      loadingMessage: "Retrieving transactions...",
    };
  }, []);

  const noRowsOverlayComponent = useMemo(() => {
    return overlayNoRowsTemplate;
  }, []);

  const noRowsOverlayComponentParams = useMemo(() => {
    return {
      noRowsMessageFunc: () =>
        "No cashflows found, please add them using the 'Add assets' button ",
    };
  }, []);

  useEffect(() => {
    if (selectedCashflows.length === 0 && gridRef.current) {
      const nodes = gridRef.current.getSelectedNodes();
      if (nodes) {
        for (const node of nodes) {
          node.setSelected(false);
        }
      }
    }
  }, [selectedCashflows]);

  return (
    <>
      <Show if={props.loading}>
        <VStack
          pos="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          w="250px"
          borderRadius={"md"}
          justifyContent={"center"}
          alignItems={"center"}
          bg="var(--bg)"
          border={"1px solid var(--accent)"}
          flexDir={"row"}
          p="4"
          zIndex={100}
        >
          <Spinner size="lg" />
          <Text>Loading Cashflows...</Text>
        </VStack>
      </Show>
      <GridContainer>
        <AgGridReact
          //   context={{ instruments: controller.instruments }}
          columnDefs={cashflowsColumnDefinitions} // Column Defs for Columns
          groupSelectsChildren={true}
          groupSelectsFiltered={true}
          rowData={data} // Row Data for Rows
          columnTypes={columnTypes} // Optional - custom column types
          rowGroupPanelShow="always"
          rowSelection="multiple" // Options - allows click selection of rows
          rowHeight={31}
          suppressAggFilteredOnly={false}
          suppressAggFuncInHeader={true}
          suppressChangeDetection={false}
          animateRows={true}
          aggFuncs={{
            distinct: distinctValue,
          }}
          autoGroupColumnDef={{
            cellRendererParams: {
              suppressCount: true,
              innerRenderer: CashflowsInnerRenderer,
            },
            checkboxSelection: true,
            sortable: false,
            resizable: true,
            initialWidth: 450,
            headerName: "Book",
          }}
          suppressRowClickSelection={true}
          sideBar={{
            toolPanels: [
              {
                id: "columns",
                labelDefault: "Columns",
                labelKey: "columns",
                iconKey: "columns",
                toolPanel: "agColumnsToolPanel",
                toolPanelParams: {
                  suppressPivotMode: true,
                  suppressValues: true,
                  suppressRowGroups: true,
                },
              },
            ],
            position: "left",
            defaultToolPanel: "filters",
          }}
          // onRowDragMove={controller.onRowDragMove}
          // onRowDragEnd={controller.onRowDragEnd}
          getRowId={(params: GetRowIdParams) => {
            return params.data.id;
          }}
          loadingOverlayComponent={loadingOverlayComponent}
          loadingOverlayComponentParams={loadingOverlayComponentParams}
          noRowsOverlayComponent={noRowsOverlayComponent}
          noRowsOverlayComponentParams={noRowsOverlayComponentParams}
          onRowGroupOpened={(params) => {
            gridState.performGroupChanged(params);
          }}
          onSelectionChanged={onSelectionChanged}
          onGridReady={onGridReady}
          onFirstDataRendered={gridState.onFirstDataRendered}
          onColumnEverythingChanged={gridState.onColumnChanged}
          onColumnResized={gridState.onColumnResized}
          onSortChanged={gridState.onColumnChanged}
          onColumnMoved={gridState.onColumnMoved}
        />
      </GridContainer>
    </>
  );
};
