import { useAtom } from "jotai";
import { AppStateAtom } from "../../store";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Container,
  Divider,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { CustomDrawer } from "../../components/design_library/CustomDrawer";
import { useRef, useState } from "react";
import { NegativeButton } from "../../components/design_library/NegativeButton";
import { useSettingsController } from "./useSettingsController";
import mixpanel from "mixpanel-browser";
import { AutoSync } from "../AutoSync/AutoSync";
import React from "react";
import { RiSettings2Fill } from "react-icons/ri";
import EmbeddingConfigurationPanel from "../embedding/EmbeddingConfigurator/EmbeddingConfigurationPanel";
import APIKeysManagementPanel from "../apikeys/APIKeysManagementPanel";
import { Toolbar } from "../../components/toolbar/Toolbar";
import { ToolbarFeatures } from "../../types";
import ReferAFriendComponent from "../referafriend/ReferFriendsComponent";
import ReferAFriendList from "../referafriend/ReferAFriendList";

const Settings = () => {
  const controller = useSettingsController();
  const [appState] = useAtom(AppStateAtom);
  const [showPassword, setShowPassword] = useState(false);
  const [logOutModalOpen, setLogOutModalOpen] = useState(false);
  const cancelRef = useRef<any>();
  
  // For better UX, add a state to track the active tab
  const [activeTab, setActiveTab] = useState(0);
  
  // Add a background color that adapts to color mode
  const panelBgColor = useColorModeValue("var(--bg)", "var(--dark-gray)");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  return (
    <Flex h="100vh" direction="column" w="100%">
      {/* Page heading */}
      <Toolbar
        enabledOptions={[ToolbarFeatures.TITLE, ToolbarFeatures.DESCRIPTION]}
        title={"Settings"}
        icon={<RiSettings2Fill />}
        description="Manage your account settings"
      />

      {/* Tabs container with improved layout */}
      <Container maxW="container.xl" py={4}>
        <Tabs 
          index={activeTab}
          onChange={(index) => setActiveTab(index)}
          variant="enclosed" 
          w="full" 
          overflow="hidden" 
          isLazy
        >
          <TabList borderBottomWidth="1px" borderBottomColor={borderColor}>
            <Tab _selected={{ borderBottom: "2px solid var(--accent)", fontWeight: "semibold" }}>Referrals</Tab>
            <Tab _selected={{ borderBottom: "2px solid var(--accent)", fontWeight: "semibold" }}>Auto Sync</Tab>
            <Tab _selected={{ borderBottom: "2px solid var(--accent)", fontWeight: "semibold" }}>Account</Tab>
            <Tab _selected={{ borderBottom: "2px solid var(--accent)", fontWeight: "semibold" }}>Tutorial</Tab>
            <Tab _selected={{ borderBottom: "2px solid var(--accent)", fontWeight: "semibold" }}>API Keys</Tab>
            <Tab _selected={{ borderBottom: "2px solid var(--accent)", fontWeight: "semibold" }}>Embedding</Tab>
            <Tab _selected={{ borderBottom: "2px solid var(--accent)", fontWeight: "semibold" }}>Billing</Tab>
          </TabList>

          <TabPanels>
            {/* REFERRALS TAB */}
            <TabPanel p={4}>
              <VStack spacing={6} align="stretch">
                <Box
                  p={6}
                  bg={panelBgColor}
                  borderRadius="md"
                  boxShadow="sm"
                  borderWidth="1px"
                  borderColor={borderColor}
                >
                  <ReferAFriendComponent hideLaterButton={true} />
                </Box>
                <Box
                  p={6}
                  bg={panelBgColor}
                  borderRadius="md"
                  boxShadow="sm"
                  borderWidth="1px"
                  borderColor={borderColor}
                >
                  <ReferAFriendList />
                </Box>
              </VStack>
            </TabPanel>

            {/* AUTO SYNC TAB */}
            <TabPanel p={4}>
              <Box 
                bg={panelBgColor} 
                rounded="md" 
                shadow="sm" 
                p={6}
                borderWidth="1px"
                borderColor={borderColor}
              >
                <AutoSync />
              </Box>
            </TabPanel>

            {/* ACCOUNT TAB */}
            <TabPanel p={4}>
              <Box 
                bg={panelBgColor} 
                rounded="md" 
                shadow="sm" 
                p={6}
                borderWidth="1px"
                borderColor={borderColor}
              >
                <VStack spacing={4} align="flex-start">
                  <Text fontSize="md">
                    Your email: <Text as="span" fontWeight="semibold">{appState.user.email}</Text>
                  </Text>
                  <Divider />
                  <HStack spacing={4}>
                    <Button
                      bg="var(--accent)"
                      color="white"
                      _hover={{ bg: "var(--accent)", opacity: 0.9 }}
                      onClick={() => controller.setDrawerOpen(true)}
                      size="md"
                    >
                      Change password
                    </Button>
                    <Button
                      variant="outline"
                      colorScheme="red"
                      size="md"
                      onClick={() => {
                        mixpanel.track("Logout Attempt");
                        setLogOutModalOpen(true);
                      }}
                    >
                      Logout
                    </Button>
                  </HStack>
                </VStack>
              </Box>

              {/* Drawer for changing password */}
              <CustomDrawer
                size="md"
                isOpen={controller.drawerOpen}
                onClose={() => {
                  controller.setDrawerOpen(false);
                  controller.setPassword("");
                  setShowPassword(false);
                }}
                body={
                  <Stack spacing={4} p={2}>
                    <Text fontSize="lg" fontWeight="semibold" mb={2}>Change Password</Text>
                    <InputGroup size="md">
                      <Input
                        pr="4.5rem"
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter new password"
                        autoComplete="new-password"
                        size="md"
                        disabled={controller.loading}
                        onChange={(e) => {
                          controller.setPassword(e.target.value);
                        }}
                        isRequired={true}
                      />
                      <InputRightElement width="4.5rem">
                        <Button
                          h="1.75rem"
                          size="sm"
                          onClick={() => setShowPassword((prev) => !prev)}
                        >
                          {showPassword ? "Hide" : "Show"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    <Divider my={2} />
                    <HStack mt={2} justifyContent="flex-end" spacing={4}>
                      <Button
                        bg="var(--accent)"
                        color="white"
                        _hover={{ bg: "var(--accent)", opacity: 0.9 }}
                        isLoading={controller.loading}
                        onClick={() => {
                          controller.changePassword();
                        }}
                      >
                        Save
                      </Button>
                      <NegativeButton
                        onClick={() => {
                          controller.setPassword("");
                          setShowPassword(false);
                          controller.setDrawerOpen(false);
                        }}
                      >
                        Cancel
                      </NegativeButton>
                    </HStack>
                  </Stack>
                }
              />
            </TabPanel>

            {/* TUTORIAL TAB */}
            <TabPanel p={4}>
              <Box 
                bg={panelBgColor} 
                rounded="md" 
                shadow="sm" 
                p={6}
                borderWidth="1px"
                borderColor={borderColor}
              >
                <VStack spacing={4} align="flex-start">
                  <Text fontSize="lg" fontWeight="semibold">Tutorial</Text>
                  <Text>Need a refresher on how to use the platform? Replay the tutorial.</Text>
                  <Button
                    bg="var(--accent)"
                    color="white"
                    _hover={{ bg: "var(--accent)", opacity: 0.9 }}
                    onClick={() => {
                      window.location.href = "../app/positions?tutorial=true";
                    }}
                  >
                    Replay tutorial
                  </Button>
                </VStack>
              </Box>
            </TabPanel>

            {/* API KEYS TAB */}
            <TabPanel p={4}>
              <APIKeysManagementPanel />
            </TabPanel>

            {/* EMBEDDING TAB */}
            <TabPanel p={4}>
              <EmbeddingConfigurationPanel />
            </TabPanel>

            {/* BILLING TAB */}
            <TabPanel p={4}>
              <Box
                bg={panelBgColor} 
                rounded="md" 
                shadow="sm" 
                p={6}
                borderWidth="1px"
                borderColor={borderColor}
              >
                <VStack spacing={4} align="flex-start">
                  <Text fontSize="lg" fontWeight="semibold">Billing Management</Text>
                  <Text>View invoices and manage your subscription plan</Text>
                  <Button
                    bg="var(--accent)"
                    color="white"
                    _hover={{ bg: "var(--accent)", opacity: 0.9 }}
                    onClick={() => controller.requestStripePortal()}
                  >
                    Manage subscription
                  </Button>
                </VStack>
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>

      {/* Logout confirmation modal with improved styling */}
      <AlertDialog
        isOpen={logOutModalOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setLogOutModalOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent bg={panelBgColor}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Logout Confirmation
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to log out?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setLogOutModalOpen(false)}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                ml={3}
                onClick={() => {
                  mixpanel.track("Logout");
                  controller.logout();
                }}
              >
                Logout
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
};

export default Settings;
