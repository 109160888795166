import React from "react";
import {
  AlertDialog,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import { useReferAFriendController } from "./useReferAFriendController";
import ReferAFriendComponent from "./ReferFriendsComponent";

const ReferAFriendPopup = () => {
  const controller = useReferAFriendController();

  return (
    <>
      {controller.shouldShowPopup && (
        <AlertDialog
          isOpen={controller.isOpen}
          onClose={controller.handleClose}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent
              bg="var(--bg)"
              border="var(--accent) 2px solid"
              p="20px"
            >
              <ReferAFriendComponent />

              <Button
                mt="20px"
                w="full"
                size="sm"
                fontWeight={400}
                onClick={controller.handleClose}
                variant="ghost"
              >
                Close
              </Button>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </>
  );
};

export default ReferAFriendPopup;
