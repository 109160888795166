import { useAtomValue } from "jotai";
import { InsightComparisonProps } from "../../../types/Insight";
import { AccountAtom, TimeFrameAtom } from "../../../store";
import { useQuery } from "@tanstack/react-query";
import { InsightStateEnum } from "../InsightStateEnum";
import { useEffect, useMemo, useState } from "react";
import { resolveNamedTimeFrame } from "../../../utils";
import { runInsight } from "../../../api";
import { BaseInsightProps } from "../BaseInsightProps";
import InsightComparisonDemoDataMap from "./data";
import { useSearchParams } from "react-router-dom";
import { useFilterHelper } from "../../../hooks";

interface UseInsightComparisonController {
  insightState: InsightStateEnum;
  supported: boolean;
  showProgress: boolean;
  title: string;
  value1: number;
  formattedValue1?: string;
  label1?: string;
  value2: number;
  formattedValue2?: string;
  label2?: string;
  prefix?: string;
  suffix?: string;
  decimals?: number;
}

export const useInsightComparisonController = (
  props: BaseInsightProps
): UseInsightComparisonController => {
  const { insight } = props;

  const filterHelper = useFilterHelper();
  const accountState = useAtomValue(AccountAtom);
  const timeFrame = useAtomValue(TimeFrameAtom);

  const [insightState, setInsightState] = useState<InsightStateEnum>(
    InsightStateEnum.Loading
  );

  const [searchParams] = useSearchParams();
  const pid = parseInt(searchParams.get("pid") ?? "");
  const accountId = pid || accountState?.selectedAccount?.id;

  // uses react query to cache result
  const result = useQuery({
    queryKey: [
      props.demo === true ? "insight-comparison" : "insight-comparison-demo",
      "insight-comparion",
      insight.insightId,
      insight.query,
      insight.type,
      accountId,
      filterHelper.filteredUnderlying,
      filterHelper.filteredTags,
      filterHelper.filteredStrategy,
      filterHelper.filteredBook,
      filterHelper.filteredPositionGroup,
      timeFrame.timeFrame,
    ],
    queryFn: async () => {
      if (props.demo === true && props?.insight?.query) {
        return InsightComparisonDemoDataMap[props?.insight?.query];
      } else {
        const timeframe = resolveNamedTimeFrame(timeFrame.timeFrame);

        return await runInsight(
          accountId,
          insight.insightId,
          insight.query!,
          insight.type,
          timeframe,
          filterHelper.filteredUnderlying,
          filterHelper.filteredTags,
          filterHelper.filteredStrategy,
          filterHelper.filteredBook,
          filterHelper.filteredPositionGroup
        );
      }
    },
    enabled:
      accountId !== undefined &&
      insight !== undefined &&
      insight.insightId !== undefined &&
      insight.query !== undefined &&
      insight.type !== undefined,
  });

  const insightResult: InsightComparisonProps | undefined = useMemo(() => {
    if (result.isSuccess && result.data) {
      const data = result.data as InsightComparisonProps;
      if (data) {
        const values = {
          prefix: data.prefix,
          value1: data.value1,
          value2: data.value2,
          suffix: data.suffix,
          decimals: data.decimals,
          label1: data.label1,
          label2: data.label2,
        };

        let formattedValue1 = Math.abs(data.value1).toFixed(values.decimals);
        formattedValue1 = formattedValue1.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        values.formattedValue1 = formattedValue1;

        let formattedValue2 = Math.abs(data.value2).toFixed(values.decimals);
        formattedValue2 = formattedValue2.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        values.formattedValue2 = formattedValue2;

        return values;
      }
    }
    return undefined;
  }, [props.demo, result.isSuccess, result.data]);

  useEffect(() => {
    if (props.demo) {
      setInsightState(InsightStateEnum.Success);
    } else {
      if (!result.isSuccess && !result.isError) {
        setInsightState(InsightStateEnum.Loading);
      } else if (result.isSuccess) {
        if (result.data) {
          setInsightState(InsightStateEnum.Success);
        } else {
          setInsightState(InsightStateEnum.NoData);
        }
        setInsightState(InsightStateEnum.Success);
      } else if (result.isError) {
        setInsightState(InsightStateEnum.Error);
      }
    }
  }, [result, props.demo]);

  return {
    insightState,
    supported:
      result.data?.supported === undefined ? true : result.data!.supported,
    showProgress: insightResult?.suffix === "%",
    title: insight?.title ?? "",
    formattedValue1: insightResult?.formattedValue1,
    value1: insightResult?.value1 || 0,
    label1: insightResult?.label1,
    formattedValue2: insightResult?.formattedValue2,
    value2: insightResult?.value2 || 0,
    label2: insightResult?.label2,
    prefix: insightResult?.prefix,
    suffix: insightResult?.suffix,
    decimals: insightResult?.decimals,
  };
};
