import { useEffect, useRef } from "react";
import { useAtom } from "jotai";
import { AppStateAtom, AccountAtom } from "./store";
import { checkToken } from "./api";
import { useToast } from "@chakra-ui/react";
import { getVersion } from "./api/App";

interface UseAppController {
  initialised: boolean;
  isUserAdmin: boolean;
}

export const useAppController = (): UseAppController => {
  const initialised = useRef<boolean>(false);
  const [appState, setAppState] = useAtom(AppStateAtom);
  const [accountState, setAccountState] = useAtom(AccountAtom);
  const toast = useToast();

  const performVersionCheck = async () => {
    const currentVersion = localStorage.getItem("appVersion");
    const version = await getVersion();
    if (version && currentVersion !== version) {
      console.log("Version updated clearing all keys", version);
      localStorage.clear();
      localStorage.setItem("appVersion", version);
    }
  };

  const performCheckToken = async () => {
    try {
      await performVersionCheck();

      setAppState((prevState) => {
        return {
          ...prevState,
          loading: true,
          errorMessage: "",
          authed: false,
          user: null,
          subscriptionActive: false,
          gridTheme: "ag-theme-alpine",
        };
      });
      setAccountState((prevState) => {
        return {
          ...prevState,
          accounts: [],
          selectedAccount: null,
          gridTheme: "ag-theme-alpine",
        };
      });

      if (localStorage.getItem("token") === null) {
        setAppState(() => {
          return {
            authed: false,
            loading: false,
            initialised: true,
            user: null,
            subscriptionActive: false,
            gridTheme: "ag-theme-alpine",
            role: "user",
            featureFlags: [],
          };
        });
        return;
      }

      const res = await checkToken();

      if (res?.success) {
        setAppState((prevState) => {
          return {
            ...prevState,
            authed: true,
            loading: false,
            initialised: true,
            email: res.user.email,
            user: res.user,
            role: res.user.role,
            subscriptionActive: res.user.subscriptionStatus,
            gridTheme: res.user.gridTheme || "ag-theme-alpine",
            featureFlags: res.user.featureFlags,
          };
        });

        // localStorage.removeItem("selectedAccount");

        setAccountState((prevState) => {
          return {
            ...prevState,
            accounts: res.accounts,
            gridTheme: "ag-theme-alpine",
          };
        });

        if (window.location.pathname === "/app/login") {
          window.location.href = "/app/dashboard";
        }
      } else {
        toast({
          title: res.title,
          description: res.message,
          status: "error",
          duration: 1600,
          isClosable: true,
        });

        if (window.location.pathname !== "/app/login") {
          window.location.href = "../app/login";
        }

        setAppState((prevState) => {
          return {
            ...prevState,
            authed: false,
            loading: false,
            initialised: true,
            user: null,
            subscriptionActive: false,
            gridTheme: "ag-theme-alpine",
          };
        });

        setAccountState((prevState) => {
          return {
            ...prevState,
            accounts: [],
            selectedAccount: null,
          };
        });
      }
    } catch (e: any) {
      setAppState((prevState) => {
        return {
          ...prevState,
          authed: false,
          initialised: true,
          subscriptionActive: false,
          gridTheme: "ag-theme-alpine",
        };
      });
    }
  };

  useEffect(() => {
    if (initialised.current === false) {
      initialised.current = true;
      performCheckToken();
    }
  }, []);

  return {
    initialised: appState.initialised || false,
    isUserAdmin: appState.user?.role === "admin",
  };
};
