import { ColDef } from "ag-grid-community";
import { ColumnTypeEnum, createGroupColumn } from "../../components";
import moment from "moment";

// book: x.book,
// positionGroup: x.positionGroup,
// strategy: x.strategy,
// tags: x.tags,
// notes: x.notes,

export const cashflowsColumnDefinitions: ColDef[] = [
  createGroupColumn("Book", "book", ColumnTypeEnum.Text, {
    valueGetter: (params) => {
      if (
        params.data.book === "null" ||
        params.data.book === null ||
        params.data.book === "" ||
        params.data.book === undefined
      ) {
        return "";
      }
      return params.data.book;
    },

    rowGroup: true,
    hide: true,
    rowDrag: true,
    sort: "asc",
    menuTabs: ["generalMenuTab"],
  }),

  createGroupColumn(
    "Underlying",
    "underlyingInstrument.symbol",
    ColumnTypeEnum.Logo,
    {
      rowGroup: true,
      hide: true,
      sort: "asc",
      rowDrag: true,
      menuTabs: ["generalMenuTab"],

      valueGetter: (params) => {
        if (params?.data?.underlyingInstrument?.instrumentType === "Future") {
          return params.data.underlyingInstrument.name;
        } else {
          return params?.data?.underlyingInstrument?.symbol;
        }
      },
    }
  ),

  createGroupColumn("Position Group", "positionGroup", ColumnTypeEnum.Text, {
    rowGroup: true,
    hide: true,
    sort: "asc",
    rowDrag: true,
    valueGetter: (params) => {
      if (
        params.data.positionGroup === "null" ||
        params.data.positionGroup === null ||
        params.data.positionGroup === "" ||
        params.data.positionGroup === undefined
      ) {
        return "Ungrouped";
      }
      return params.data.positionGroup;
    },
    menuTabs: ["generalMenuTab"],
  }),

  createGroupColumn("Category", "category", ColumnTypeEnum.Text, {
    rowGroup: true,
    hide: true,
    sort: "asc",
    maxWidth: 150,
    valueGetter: (params) => {
      if (
        params.data.category === "null" ||
        params.data.category === null ||
        params.data.category === "" ||
        params.data.category === undefined
      ) {
        return "Ungrouped";
      }
      return params.data.category;
    },
    menuTabs: ["generalMenuTab"],
  }),

  createGroupColumn("Date", "date", ColumnTypeEnum.Date, {
    sort: "asc",
    valueGetter: (params) => {
      const data = params.data ?? params.node?.data ?? params.node?.aggData;
      if (data) {
        return moment(data.date).format("YYYY-MM-DD");
      }
      return undefined;
    },
    maxWidth: 150,
  }),

  createGroupColumn("Strategy", "strategy", ColumnTypeEnum.Text, {
    hide: true,
    menuTabs: ["generalMenuTab"],
  }),

  createGroupColumn("Tags", "tags", ColumnTypeEnum.Tags, {
    hide: true,
    menuTabs: ["generalMenuTab"],
    valueGetter: (params) => {
      const data = params.data ?? params.node?.data ?? params.node?.aggData;
      if (!data) {
        return undefined;
      }
      return data.tags;
    },
  }),

  createGroupColumn("Symbol", "instrument.symbol", ColumnTypeEnum.Logo, {
    hide: true,
  }),

  createGroupColumn("Summary", "type", ColumnTypeEnum.Text, { maxWidth: 150 }),
  createGroupColumn("Description", "description", ColumnTypeEnum.Text),
  createGroupColumn("Value", "value", ColumnTypeEnum.Currency, {
    cellRendererParams: {
      showDirection: true,
    },
    aggFunc: "sum",
    maxWidth: 220,
  }),

  createGroupColumn("Fees", "fees", ColumnTypeEnum.Currency, {
    cellRendererParams: {
      showDirection: true,
    },
    aggFunc: "sum",
    maxWidth: 220,
  }),

  createGroupColumn("Currency", "currency", ColumnTypeEnum.Text, {
    maxWidth: 130,
  }),

  createGroupColumn("Id", "cashflowId", ColumnTypeEnum.Text, {
    hide: true,
    menuTabs: ["generalMenuTab"],
    aggFunc: "none",
  }),
];
