import { useAtom, useAtomValue } from "jotai";
import {
  DashboardLayoutStateStore,
  SelectedDashboardIdAtom,
} from "../../store/DashboardLayout";
import { useCallback, useEffect, useMemo } from "react";
import {
  _deleteLayout,
  _updateLayoutName,
  createDashboardConfiguration,
  getAllLayoutsForUser,
  updateAccount,
} from "../../api";
import { AccountAtom } from "../../store";
import { DashboardConfiguration } from "../../types/DashboardConfiguration";

interface useDashboardSelectorControllerResponse {
  configurations: DashboardConfiguration[];
  selectedConfigurationId: number | undefined;
  setSelectedConfiguration: (id: number) => void;
  selectedDashboard?: string;
  renameDashboard: (name: string) => void;
  createDashboard: (name: string) => void;
  deleteDashboard: (dashboardId: number) => void;
}

export const useDashboardSelectorController =
  (): useDashboardSelectorControllerResponse => {
    const [dashboardLayouts, setDashboardLayouts] = useAtom(
      DashboardLayoutStateStore
    );

    const accountState = useAtomValue(AccountAtom);
    const [selectedDashboardId, setSelectedDashboardId] = useAtom(
      SelectedDashboardIdAtom
    );

    const selectedDashboard = useMemo(() => {
      const configurations = dashboardLayouts.configurations;
      const defaultDashboardId =
        accountState.selectedAccount?.defaultDashboardConfigurationId;

      if (configurations && configurations.length > 0) {
        const activeDashboardId = selectedDashboardId || defaultDashboardId;
        const config = configurations.find(
          (x) => x.configurationId === activeDashboardId
        );

        if (!config) {
          // Default to the first dashboard if the selected one is not found
          const firstConfig = configurations[0];
          setSelectedDashboardId(firstConfig.configurationId);
          return firstConfig.name;
        }

        return config.name;
      }

      return undefined;
    }, [
      selectedDashboardId,
      accountState.selectedAccount?.defaultDashboardConfigurationId,
      dashboardLayouts.configurations,
    ]);

    const setSelectedConfiguration = useCallback(
      async (id: number | undefined) => {
        if (
          accountState.selectedAccount &&
          accountState.selectedAccount.defaultDashboardConfigurationId !== id
        ) {
          await updateAccount(accountState.selectedAccount?.id, {
            ...accountState.selectedAccount,
            defaultDashboardConfigurationId: id,
          });
        }

        if (selectedDashboardId !== id) {
          setSelectedDashboardId(id);

          setDashboardLayouts((prev) => {
            const config = prev.configurations.find((x) => {
              return x.configurationId === id;
            });

            return {
              ...prev,
              // layouts: config?.layout,
              selectedConfiguration: config,
            };
          });
        }
      },
      [accountState.selectedAccount?.id, selectedDashboardId, dashboardLayouts]
    );

    const fetchLayout = useCallback(async (): Promise<
      DashboardConfiguration[]
    > => {
      const configurations =
        (await getAllLayoutsForUser()) as DashboardConfiguration[];

      if (configurations) {
        const config = configurations[0];
        setDashboardLayouts((prev) => ({
          ...prev,
          layouts: config?.layout,
          selectedConfiguration: config,
          configurations: configurations.sort((a, b) => {
            return a.name.localeCompare(b.name);
          }),
        }));

        const urlId = new URLSearchParams(window.location.search).get(
          "dashboardId"
        );
        let layoutId = undefined;
        if (urlId !== null) {
          layoutId = urlId;
        } else {
          layoutId =
            selectedDashboardId ??
            accountState.selectedAccount?.defaultDashboardConfigurationId;
        }

        setSelectedConfiguration(layoutId);
      }
      return configurations ?? [];
    }, []);

    const renameDashboard = useCallback(
      async (dashboardId: number, name: string) => {
        if (selectedDashboardId && name) {
          const id = dashboardId;
          await _updateLayoutName(id, name);
          // await fetchLayout();

          //Fixes dashboard disappearing...
          setDashboardLayouts((prev) => {
            const configurations = prev.configurations.map((x) => {
              if (x.configurationId === id) {
                x.name = name;
              }
              return x;
            });

            return {
              ...prev,
              configurations: configurations,
            };
          });
        }
      },
      [selectedDashboardId]
    );

    const createDashboard = useCallback(
      async (name: string) => {
        if (name && accountState.selectedAccount?.id) {
          const result = await createDashboardConfiguration(
            accountState.selectedAccount?.id,
            name
          );
          await fetchLayout();
          setTimeout(() => {
            setSelectedDashboardId(result?.config?.configurationId);
          }, 500);
        }
      },
      [selectedDashboardId]
    );

    const deleteDashboard = useCallback(async (dashboardId: number) => {
      if (dashboardId) {
        await _deleteLayout(dashboardId);
        const configurations = await fetchLayout();
        const id =
          configurations.length > 0
            ? configurations[0].configurationId
            : undefined;
        setSelectedConfiguration(id);
      }
    }, []);

    useEffect(() => {
      if (
        !selectedDashboardId &&
        accountState.selectedAccount?.defaultDashboardConfigurationId
      ) {
        setSelectedDashboardId(
          accountState.selectedAccount?.defaultDashboardConfigurationId
        );
      }
    }, [
      selectedDashboardId,
      accountState.selectedAccount?.defaultDashboardConfigurationId,
    ]);

    useEffect(() => {
      fetchLayout();
    }, []);

    return {
      configurations: dashboardLayouts.configurations,
      selectedConfigurationId:
        selectedDashboardId ??
        accountState.selectedAccount?.defaultDashboardConfigurationId,
      setSelectedConfiguration,
      selectedDashboard,
      renameDashboard,
      createDashboard,
      deleteDashboard,
    };
  };
