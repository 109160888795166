import { useAtomValue } from "jotai";
import { KeyValuePair } from "../../types";
import { useFilterHelper, useInstruments } from "../../hooks";
import { AccountAtom, TransactionsAtom } from "../../store";
import { useEffect, useMemo, useState } from "react";
import { CashFlowEx } from "../../types/Cashflow";
import { getCashFlows } from "../../api/Cashflow";
import { determineDateRange } from "../../components";

interface UseCashFlowController {
  rows: CashFlowEx[];
  selectedRows: CashFlowEx[];
  setSelectedRows: (trades: CashFlowEx[]) => void;
  loading: boolean;
  showUnCategorised: boolean;
  setShowUnCategorised: (value: boolean) => void;
  showUnBooked: boolean;
  setShowUnBooked: (value: boolean) => void;
  showNoPositionGrouping: boolean;
  setShowNoPositionGrouping: (value: boolean) => void;
  setSelectedDateRange: (item: KeyValuePair) => void;
  refresh: () => void;
}

export const useCashflowsController = (): UseCashFlowController => {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<CashFlowEx[]>([]);
  const [selectedRows, setSelectedRows] = useState<CashFlowEx[]>([]);
  const [showUnCategorised, setShowUnCategorised] = useState(false);
  const [showUnBooked, setShowUnBooked] = useState(false);
  const [showNoPositionGrouping, setShowNoPositionGrouping] = useState(false);
  const instruments = useInstruments();
  const accountState = useAtomValue(AccountAtom);
  const filterHelper = useFilterHelper();

  const [selectedDateRange, setSelectedDateRange] = useState<KeyValuePair>();

  const filteredRows = useMemo(() => {
    let result = filterHelper.filteredUnderlying
      ? rows.filter((row) => {
          const underlyingInstrument = instruments.list.find((x) => {
            return (
              x.id === row.underlyingInstrumentId || x.id === row.instrumentId
            );
          });

          if (
            underlyingInstrument?.name === filterHelper.filteredUnderlying ||
            underlyingInstrument?.symbol === filterHelper.filteredUnderlying ||
            underlyingInstrument?.symbol.split("_")[0] ===
              filterHelper.filteredUnderlying
          ) {
            return true;
          } else {
            return false;
          }
        })
      : rows;

    if (filterHelper.filteredTags && filterHelper.filteredTags !== "All") {
      result = result.filter((row) => {
        return row?.tags?.includes(filterHelper.filteredTags!);
      });
    }

    if (showUnCategorised) {
      result = result.filter((row) => !row.category);
    }

    if (showUnBooked) {
      result = result.filter((row) => !row.book);
    }

    if (showNoPositionGrouping) {
      result = result.filter(
        (row) =>
          !row.positionGroup || row.positionGroup.toLowerCase() === "ungrouped"
      );
    }

    if (
      filterHelper.filteredPositionGroup &&
      filterHelper.filteredPositionGroup !== "All"
    ) {
      result = result.filter(
        (row) =>
          row.positionGroup?.toLowerCase() ===
          filterHelper.filteredPositionGroup!.toLowerCase()
      );
    }

    if (
      filterHelper.filteredStrategy &&
      filterHelper.filteredStrategy !== "All"
    ) {
      result = result.filter(
        (row) => row.strategy === filterHelper.filteredStrategy!
      );
    }

    if (filterHelper.filteredBook && filterHelper.filteredBook !== "All") {
      result = result.filter(
        (row) =>
          row.book?.toLowerCase() === filterHelper.filteredBook!.toLowerCase()
      );
    }

    return result.map((row) => {
      return {
        ...row,
        instrument: row.instrumentId
          ? instruments.list.find((x) => x.id === row.instrumentId)
          : undefined,
        underlyingInstrument: row.underlyingInstrumentId
          ? instruments.list.find((x) => x.id === row.underlyingInstrumentId)
          : instruments.list.find((x) => x.id === row.instrumentId),
      } as CashFlowEx;
    });
  }, [
    filterHelper,
    rows,
    showNoPositionGrouping,
    showUnBooked,
    showUnCategorised,
  ]);

  const refresh = () => {
    setSelectedRows([]);
    loadData(true);
  };

  const loadData = (force: boolean = false) => {
    if (
      selectedDateRange?.key &&
      accountState.selectedAccount?.id &&
      (force || !loading)
    ) {
      setLoading(true);
      setTimeout(async () => {
        try {
          const dates = determineDateRange(selectedDateRange?.key);
          const result = await getCashFlows(
            accountState.selectedAccount.id,
            dates.start,
            dates.end
          );
          setRows(result?.data);
        } finally {
          setLoading(false);
        }
      }, 1);
    }
  };

  const transactions = useAtomValue(TransactionsAtom);

  useEffect(() => {
    loadData();
  }, [accountState.selectedAccount, selectedDateRange, transactions.cashflows]);

  return {
    rows: filteredRows,
    loading,
    showUnCategorised,
    setShowUnCategorised,
    showUnBooked,
    setShowUnBooked,
    showNoPositionGrouping,
    setShowNoPositionGrouping,
    setSelectedDateRange,
    selectedRows,
    setSelectedRows,
    refresh,
  };
};
