import React from "react";
import { useAtomValue } from "jotai";
import {
  Center,
  Flex,
  Link,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Config } from "./config/Config";
import { Instruments } from "./instruments/Instruments";
import { AppStateAtom } from "../../store";
import Users from "./users/Users";
import Files from "./files/Files";
import WebsiteSettings from "./settings/WebsiteSettings";
import TradeConfirmation from "./tradeconfirmation/TradeConfirmation";
import KnowledgeBaseAdmin from "./knowledgebase/KnowledgeBaseAdmin";
import Images from "./images/Images";
import BlogAdmin from "./blogs/BlogAdmin";
import { EmailIcon, SettingsIcon } from "@chakra-ui/icons";
import { FaFileUpload, FaGuitar, FaQuestion } from "react-icons/fa";
import { RiListIndefinite, RiUserSearchLine } from "react-icons/ri";
import { BiNews, BiPaint, BiPhotoAlbum } from "react-icons/bi";
import { BsCashStack } from "react-icons/bs";
import CashflowsAdmin from "./cashflows/CashflowsAdmin";
import { IoAnalytics } from "react-icons/io5";
import Analysis from "./analysis/Analysis";

export const Admin = () => {
  const appState = useAtomValue(AppStateAtom);

  if (!appState.authed || appState.user?.role !== "admin") {
    return null;
  }

  return (
    <Flex h="full" w="full" p={2} w="100%">
      <Tabs h="full" w="full" isLazy={true}>
        <TabList>
          <Tab>
            <FaGuitar />
            <Text ml="6px">Instruments</Text>
          </Tab>
          <Tab>
            <RiListIndefinite />
            <Text ml="6px">Config</Text>
          </Tab>
          <Tab>
            <RiUserSearchLine />
            <Text ml="6px">Users</Text>
          </Tab>
          <Tab>
            <FaFileUpload />
            <Text ml="6px">Uploads</Text>
          </Tab>
          <Tab>
            <SettingsIcon mr="6px" />
            Settings
          </Tab>
          <Tab>
            <EmailIcon />
            <Text ml="6px">Trade Confirmations</Text>
          </Tab>
          <Tab>
            <FaQuestion />
            <Text ml="6px">FAQ</Text>
          </Tab>
          <Tab>
            <BiNews />
            <Text ml="6px">Blog</Text>
          </Tab>
          <Tab>
            <BiPhotoAlbum />
            <Text ml="6px">Images</Text>
          </Tab>
          <Tab>
            <BiPaint />
            <Text ml="6px">White-label</Text>
          </Tab>
          <Tab>
            <BsCashStack />
            <Text ml="6px">Cashflows</Text>
          </Tab>
          <Tab>
            <IoAnalytics />
            <Text ml="6px">Analysis</Text>
          </Tab>
        </TabList>

        <TabPanels h="calc(100% - 35px)">
          <TabPanel h="full" w="full" p="0">
            <Instruments />
          </TabPanel>
          <TabPanel h="full" w="full" p="0">
            <Config />
          </TabPanel>
          <TabPanel h="full" w="full" p="0">
            <Users />
          </TabPanel>
          <TabPanel h="full" w="full" p="0">
            <Files />
          </TabPanel>
          <TabPanel h="full" w="full" p="0">
            <WebsiteSettings />
          </TabPanel>
          <TabPanel h="full" w="full" p="0">
            <TradeConfirmation />
          </TabPanel>
          <TabPanel h="full" w="full" p="0">
            <KnowledgeBaseAdmin />
          </TabPanel>
          <TabPanel h="full" w="full" p="0">
            <BlogAdmin />
          </TabPanel>
          <TabPanel h="full" w="full" p="0">
            <Images />
          </TabPanel>
          <TabPanel h="full" w="full" p="0">
            <Center mt="40px">
              <VStack spacing={4} align="start">
                {/* Text explaining what this section is for */}
                <Text>
                  This section allows you to modify the theme in the URL. The
                  theme you select here will be applied and persist until the
                  page is refreshed.
                </Text>

                {/* Link to apply the 'Tasty' theme */}
                <Text>
                  Click the link below to apply the "Tasty" theme. This will
                  update the theme for the dashboard to 'ag-theme-tasty':
                </Text>
                <Link
                  href={`${window.location.origin}/app/dashboard?theme=ag-theme-tasty`}
                  target="_blank"
                >
                  Tasty Theme Link:{" "}
                  {`${window.location.origin}/app/dashboard?theme=ag-theme-tasty`}
                </Link>

                {/* Link to apply the current theme as per the application state */}
                <Text>
                  This link applies the theme that is currently in use in the
                  app (stored in the state). It shows your current theme based
                  on what is set in the app state:
                </Text>
                <Link
                  href={`${window.location.origin}/app/dashboard?theme=${appState.gridTheme}`}
                  target="_blank"
                >
                  Current Theme Link:{" "}
                  {`${window.location.origin}/app/dashboard?theme=${appState.gridTheme}`}
                </Link>

                {/* Text showing an example of a full URL with parameters */}
                <Text>
                  Below is an example URL that shows how you can customize the
                  dashboard with specific parameters like "whitelabel",
                  "portfolioId", and "dashboardId":
                </Text>
                <Text>
                  Example URL:{" "}
                  {
                    "http://localhost:3000/app/dashboard?whitelabel=true&portfolioId=94&dashboardId=285"
                  }
                </Text>
              </VStack>
            </Center>
          </TabPanel>
          <TabPanel h="full" w="full" p="0">
            <CashflowsAdmin />
          </TabPanel>
          <TabPanel h="full" w="full" p="0">
            <Analysis />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};
