import BookRender from "../../components/grid/renderers/BookRender";
import { UnderlyingRender } from "../../components/grid/renderers/UnderlyingRender";
import { PositionGroupRender, TradeDetailsRender } from "../../components";

export const TradesInnerRenderer = (params: any) => {
  if (params.node.field === "book") {
    return BookRender(params);
  }
  if (params.node.field === "underlyingInstrument.symbol") {
    return UnderlyingRender(params);
  }

  if (params.node.field === "positionGroup") {
    return PositionGroupRender(params);
  }

  if (params.node.group) {
    return params.value;
  }
  return TradeDetailsRender(params, "Transactions");
};
