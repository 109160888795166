import { Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import moment from "moment-timezone";
import { ChakraProvider, Box, Center, Heading } from "@chakra-ui/react";
import theme from "./themeConfig";
import { MainApp } from "./features/main-app/MainApp";
import { Dashboard } from "./features/dashboard/Dashboard";
import { Login } from "./features/authentication/login/Login";
import FourOhFour from "./404";
import { Register } from "./features/authentication/register/Register";
import { ResetPassword } from "./features/authentication/reset/ResetPassword";
import { useAppController } from "./useAppController";
import Settings from "./features/settings/Settings";
import Positions from "./features/positions/Positions";
import ThemeSwitcher from "./components/themes/ThemeSwitcher";
import { Admin } from "./features/admin/Admin";
import React from "react";
import FormExample from "./components/forms/FormExample";
import TokenAssignment from "./features/admin/users/TokenAssignment";
import { Prices } from "./features/prices/Prices";
import EmbedDashboard from "./features/embedding/ExternalEmbedding/EmbedDashboard";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import PayOffConfigurator from "./components/optionpayoff/PayOffConfigurator";
import { Dashboard2 } from "./features/dashboard2/Dashboard2";
import SchwabHandleCallBack from "./features/AutoSync/Brokers/SCHWAB/SchwabHandleCallBack";
import CalendarView from "./features/calendar/Calendar";
import { TradesContainer } from "./features/trades/TradesContainer";
import { CashflowsContainer } from "./features/cashflows/CashflowsContainer";

const Loading = () => (
  <Box>
    <Center height="100vh" flexDir="column">
      <Heading mt="2" size="md">
        Loading...
      </Heading>
    </Center>
  </Box>
);

const App = () => {
  const controller = useAppController();
  moment.tz.setDefault("Europe/London");

  const queryClient = new QueryClient();

  useEffect(() => {
    localStorage.removeItem("apiKey");
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      <ChakraProvider theme={theme}>
        <ThemeSwitcher />
        <QueryClientProvider client={queryClient}>
          {controller.initialised && (
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Login />} />
                <Route
                  index
                  path="/23rjfisf4d3-234f4-fdsfnfj4fndc/token"
                  element={<TokenAssignment />}
                />
                <Route path="/app/login" element={<Login />} />
                <Route path="/app/register" element={<Register />} />
                <Route path="/app/reset" element={<ResetPassword />} />
                <Route path="/app" element={<MainApp />}>
                  <Route index path="/app/admin" element={<Admin />} />

                  <Route
                    index
                    path="/app/admin/forms"
                    element={<FormExample />}
                  />

                  <Route
                    index
                    path="/app/dashboardOLD"
                    element={<Dashboard />}
                  />

                  <Route index path="/app/dashboard" element={<Dashboard2 />} />

                  <Route index path="/app/positions" element={<Positions />} />
                  <Route
                    index
                    path="/app/options/payoff"
                    element={<PayOffConfigurator />}
                  />

                  <Route
                    index
                    path="/app/trades"
                    element={<TradesContainer />}
                  />
                  <Route
                    index
                    path="/app/cashflows"
                    element={<CashflowsContainer />}
                  />
                  <Route index path="/app/prices" element={<Prices />} />
                  <Route
                    index
                    path="/app/calendar"
                    element={<CalendarView />}
                  />
                  <Route index path="/app/settings" element={<Settings />} />
                </Route>
                <Route
                  path="*"
                  element={<Navigate to="/app/dashboard" replace />}
                />
                <Route path="/404" element={<FourOhFour />} />
                <Route path="/embed" element={<EmbedDashboard />} />

                <Route
                  path="/app/callback/schwab"
                  element={<SchwabHandleCallBack />}
                />
              </Routes>
            </BrowserRouter>
          )}
        </QueryClientProvider>
      </ChakraProvider>
    </Suspense>
  );
};

export default App;
