import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useCashflowViewController } from "./useCashflowViewController";
import { AgGridReact } from "ag-grid-react";
import { VStack, Text } from "@chakra-ui/react";
import { GetRowIdParams, GridApi, GridReadyEvent } from "ag-grid-community";
import {
  columnTypes,
  distinctValue,
  GridContainer,
  Show,
  Spinner,
} from "../../../components";
import { columnDefinitions } from "./columnDefinitions";
import {
  overlayLoadingTemplate,
  overlayNoRowsTemplate,
} from "../../../components/grid/NoRowsToShow";
import { useGridState } from "../../../components/grid/useGridState";

interface CashflowViewProps {
  cashflowIds: number[];
}

export const CashflowView = (props: CashflowViewProps) => {
  const controller = useCashflowViewController(props.cashflowIds);

  const gridRef = useRef<GridApi | null>(null);

  const gridState = useGridState({ gridName: "Positions-Cashflows-View" });

  const onGridReady = useCallback((e: GridReadyEvent) => {
    gridRef.current = e.api;
    gridRef.current.autoSizeAllColumns();
    setTimeout(() => {
      gridState.onGridReady(e);
    }, 0);
  }, []);

  const loadingOverlayComponent = useMemo(() => {
    return overlayLoadingTemplate;
  }, []);

  const loadingOverlayComponentParams = useMemo(() => {
    return {
      loadingMessage: "Retrieving transactions...",
    };
  }, []);

  const noRowsOverlayComponent = useMemo(() => {
    return overlayNoRowsTemplate;
  }, []);

  const noRowsOverlayComponentParams = useMemo(() => {
    return {
      noRowsMessageFunc: () =>
        "No cashflows found, please add them using the 'Add assets' button ",
    };
  }, []);

  return (
    <>
      <Show if={controller.loading}>
        <VStack
          pos="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          w="250px"
          borderRadius={"md"}
          justifyContent={"center"}
          alignItems={"center"}
          bg="var(--bg)"
          border={"1px solid var(--accent)"}
          flexDir={"row"}
          p="4"
          zIndex={100}
        >
          <Spinner size="lg" />
          <Text>Loading Cashflows...</Text>
        </VStack>
      </Show>
      <GridContainer>
        <AgGridReact
          //   context={{ instruments: controller.instruments }}
          columnDefs={columnDefinitions} // Column Defs for Columns
          groupSelectsChildren={true}
          groupSelectsFiltered={true}
          rowData={controller.rows} // Row Data for Rows
          columnTypes={columnTypes} // Optional - custom column types
          rowGroupPanelShow="always"
          rowSelection="multiple" // Options - allows click selection of rows
          rowHeight={31}
          suppressAggFilteredOnly={false}
          suppressAggFuncInHeader={true}
          suppressChangeDetection={false}
          //   rowDragManaged={false}
          //   rowDragEntireRow={isMobile}
          animateRows={true}
          //   rowDragMultiRow={true}
          // getContextMenuItems={getContextMenuItems}
          aggFuncs={{
            distinct: distinctValue,
          }}
          autoGroupColumnDef={{
            cellRendererParams: {
              suppressCount: true,
              //innerRenderer: CashflowsInnerRenderer,
            },
            checkboxSelection: true,
            sortable: false,
            resizable: true,
            initialWidth: 450,
            headerName: "Book",
          }}
          suppressRowClickSelection={true}
          sideBar={{
            toolPanels: [
              {
                id: "columns",
                labelDefault: "Columns",
                labelKey: "columns",
                iconKey: "columns",
                toolPanel: "agColumnsToolPanel",
                toolPanelParams: {
                  suppressPivotMode: true,
                  suppressValues: true,
                  suppressRowGroups: true,
                },
              },
            ],
            position: "left",
            defaultToolPanel: "filters",
          }}
          // onRowDragMove={controller.onRowDragMove}
          // onRowDragEnd={controller.onRowDragEnd}
          getRowId={(params: GetRowIdParams) => {
            return params.data.id;
          }}
          loadingOverlayComponent={loadingOverlayComponent}
          loadingOverlayComponentParams={loadingOverlayComponentParams}
          noRowsOverlayComponent={noRowsOverlayComponent}
          noRowsOverlayComponentParams={noRowsOverlayComponentParams}
          onRowGroupOpened={(params) => {
            gridState.performGroupChanged(params);
          }}
          onGridReady={onGridReady}
          onFirstDataRendered={gridState.onFirstDataRendered}
          onColumnEverythingChanged={gridState.onColumnChanged}
          onColumnResized={gridState.onColumnResized}
          onSortChanged={gridState.onColumnChanged}
          onColumnMoved={gridState.onColumnMoved}
        />
      </GridContainer>
    </>
  );
};
