import APIEndpoint from "./APIEndpoint";

export const register = async (email: string, password: string) => {
  const refId = localStorage.getItem("referringId");
  return fetch(APIEndpoint + "/users/register", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email: email, password: password, refId }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        localStorage.setItem("token", res.token);
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      console.log("API Call, error registering", e);
    });
};
