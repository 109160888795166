import { useEffect, useState } from "react";
import { useAtomValue } from "jotai";
import { Brokers, SyncSetting } from "../../../../types";
import { AccountAtom } from "../../../../store";
import {
  addOrUpdateSyncSetting,
  getSchwabAuthCode,
  getSyncSettings,
  swapCodeForToken,
  testSyncSetting,
} from "../../../../api";
import { useNotifications } from "../../../../hooks/useNotifications";
import { SCHWABAutoSyncConfig } from "./SCHWABAutoSyncConfig";

// Props interface for the hook
export interface SchwabAutoSyncControllerProps {
  settings?: SyncSetting;
  close?: () => void;
  readOnly?: boolean;
}

// Response interface for the hook
interface SchwabAutoSyncControllerResponse {
  readOnly?: boolean;
  config: SCHWABAutoSyncConfig;
  setConfig: (config: SCHWABAutoSyncConfig) => void;
  testConfig: () => void;

  connectionStatus: string;
  saveConfig: (overrideConfig: SCHWABAutoSyncConfig) => void;
  loading?: boolean;
  error?: string;
  step1GetSchwabAuthCode: (accountId: number) => void;
  connected: boolean;
  step2SwapCodeForToken: (code: string, accountId: number) => void;
}

// Main hook to handle Schwab Auto Sync Controller logic
export const useSchwabAutoSyncController = (
  props: SchwabAutoSyncControllerProps
): SchwabAutoSyncControllerResponse => {
  const notifications = useNotifications();
  const accountState = useAtomValue(AccountAtom);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [connected, setConnected] = useState(false);

  // State management for configuration and connection status
  const [config, setConfig] = useState<SCHWABAutoSyncConfig>({});
  const [connectionStatus, setConnectionStatus] =
    useState<string>("NOT_CONNECTED");
  const [syncSettingIdTemp, setSyncSettingIdTemp] = useState<
    number | undefined
  >(props.settings?.syncSettingId);

  // Function to save or update sync settings
  const saveConfig = async (overrideConfig: SCHWABAutoSyncConfig) => {
    const accountId = accountState.selectedAccount?.id;
    if (!accountId) return;

    const payload = {
      accountId,
      broker: Brokers.Schwab,
      name: "Schwab Auto Sync Settings",
      settings: JSON.stringify(overrideConfig || config),
      syncSettingId: props.settings?.syncSettingId || syncSettingIdTemp,
    };

    try {
      const result = await addOrUpdateSyncSetting(
        payload as SyncSetting,
        accountId
      );
      if (result.success) {
        setSyncSettingIdTemp(result.data.syncSettingId);
      }
    } catch (error) {
      notifications.showErrorMessage("Auto Sync Settings", error as any);
    }
  };

  // Function to test configuration stages such as Authorize, Link Code, Test Connection, etc.
  const testConfig = async () => {
    setLoading(true);
    setConnectionStatus("");
    const accountId = accountState.selectedAccount?.id;
    if (!accountId) return;

    const payload = {
      accountId,
      broker: Brokers.Schwab,
      name: "Schwab Auto Sync Settings",
      settings: JSON.stringify(config),
      syncSettingId: props.settings?.syncSettingId,
    };

    try {
      const result = await testSyncSetting({ ...payload }, accountId);
      if (result.success === true) {
        notifications.createNotification(
          "Auto Sync Settings - Valid",
          "Successfully Tested"
        );
      } else {
        handleInvalidConfig();
      }
    } catch (error) {
      notifications.showErrorMessage("Test Config Error", error as any);
    }
    setLoading(false);
  };

  const handleInvalidConfig = () => {
    const clearedConfig = { ...config, code: "" };
    setConfig(clearedConfig);
    saveConfig(clearedConfig);
    notifications.createNotification(
      "Auto Sync Settings - Failed",
      "Unable to verify settings"
    );
  };

  useEffect(() => {
    if (props.settings?.settings) {
      setConfig(JSON.parse(props.settings.settings));
    }
  }, [props.settings]);

  const step1GetSchwabAuthCode = async (accountId: number) => {
    setLoading(true);

    const config = await getSyncSettings(accountState.selectedAccount?.id);
    let schwabConfigPresent = config.find((c) => c.broker === Brokers.Schwab);
    if (schwabConfigPresent) {
      setConfig(JSON.parse(schwabConfigPresent.settings));
    } else {
      saveConfig({});
    }

    const data = await getSchwabAuthCode(accountId);
    if (data) {
      window.open(data, "");
    } else {
      handleInvalidConfig();
    }
    setLoading(false);
  };

  const step2SwapCodeForToken = async (code: string, accountId: number) => {
    setLoading(true);

    try {
      const result = await swapCodeForToken(code, accountId);
      if (result === false) {
        setError("Failed to connect to Schwab, close this page and try again.");
      } else {
        setError("");
        setConnected(true);
        setTimeout(() => {
          window.location.href = "/app/positions";
        }, 3000);
      }
    } catch (error) {
      handleInvalidConfig();
    }
    setLoading(false);
  };

  return {
    readOnly: props.readOnly,
    config,
    loading,
    setConfig,
    saveConfig,
    connected,
    connectionStatus,
    testConfig,
    error,
    step1GetSchwabAuthCode,
    step2SwapCodeForToken,
  };
};
