import { atom } from "jotai";
import { FilterSettings } from "../types";

export interface QuickFilterSettings extends FilterSettings {
    enabled: boolean;
}

const buildInitialState = (): QuickFilterSettings => {
  const state: any = {
    enabled: false,
    underlyings: [],
    tags: [],
    positionGroups: [],
    strategies: [],
    books: [],
  };

  return state;
};

export const QuickFilterAtom = atom<QuickFilterSettings>(buildInitialState());