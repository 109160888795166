export const DIVIDENDS_BY_MONTH = {
  data: [
    {
      domain: "2024-07-31",
      amount: 600.85,
    },
    {
      domain: "2024-08-31",
      amount: 358.54,
    },
    {
      domain: "2024-09-30",
      amount: 978.4,
    },
    {
      domain: "2024-10-31",
      amount: 226.75,
    },
    {
      domain: "2024-11-30",
      amount: 336.27,
    },
    {
      domain: "2024-12-31",
      amount: 586.9,
    },
  ],
  chartProperties: {
    name: "Dividends By Month",
    chartType: "bar",
    keys: ["amount"],
    colors: ["var(--chart-blue)"],
    legend: ["Amount"],
    domainType: "date",
  },
  timeFrameType: "window",
  supported: true,
};
