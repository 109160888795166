import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  HStack,
  IconButton,
  InputGroup,
  InputLeftElement,
  Tooltip,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Wrap,
  WrapItem,
  useBreakpointValue,
  Badge,
} from "@chakra-ui/react";
import SearchInput from "../../../../components/forms/components/SearchInput";
import { MdGrid3X3, MdList, MdFilterList } from "react-icons/md";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import { ChevronDownIcon } from "@chakra-ui/icons";

interface CategoryFilterProps {
  categories: string[];
  selectedCategory: string | null;
  setSelectedCategory: (category: string | null) => void;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  setView: (view: string) => void;
  view: string;
  setDisplayDemoData: (display: boolean) => void;
  displayDemoData: boolean;
}

const CategoryFilter: React.FC<CategoryFilterProps> = ({
  categories,
  selectedCategory,
  setSelectedCategory,
  searchQuery,
  setSearchQuery,
  setView,
  view,
  setDisplayDemoData,
  displayDemoData,
}) => {
  const [localSearchQuery, setLocalSearchQuery] = useState(searchQuery);
  const isMobile = useBreakpointValue({ base: true, md: false });
  
  // Handle search input with debounce
  useEffect(() => {
    setLocalSearchQuery(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchQuery(localSearchQuery);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [localSearchQuery, setSearchQuery]);

  return (
    <Flex 
      direction={{ base: "column", md: "row" }}
      justify="space-between"
      align={{ base: "stretch", md: "center" }}
      w="full"
      gap={3}
      py={3}
      px={4}
    >
      {/* Categories - Desktop */}
      {!isMobile && (
        <Wrap spacing={2} flex="1" display={{ base: "none", md: "flex" }}>
          <WrapItem>
            <Button
              size="sm"
              colorScheme={!selectedCategory ? "green" : "gray"}
              variant={!selectedCategory ? "solid" : "outline"}
              onClick={() => setSelectedCategory(null)}
            >
              All
            </Button>
          </WrapItem>
          {categories.map((category) => (
            <WrapItem key={category}>
              <Button
                size="sm"
                colorScheme={selectedCategory === category ? "green" : "gray"}
                variant={selectedCategory === category ? "solid" : "outline"}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </Button>
            </WrapItem>
          ))}
        </Wrap>
      )}

      {/* Categories - Mobile Dropdown */}
      {isMobile && (
        <Menu>
          <MenuButton 
            as={Button} 
            rightIcon={<ChevronDownIcon />}
            w="full"
            mb={2}
          >
            <Flex align="center">
              <MdFilterList style={{ marginRight: '8px' }} />
              <Text>
                {selectedCategory ? selectedCategory : "All Categories"}
              </Text>
              {selectedCategory && (
                <Badge ml={2} colorScheme="green">1</Badge>
              )}
            </Flex>
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => setSelectedCategory(null)}>
              All
            </MenuItem>
            {categories.map((category) => (
              <MenuItem 
                key={category} 
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      )}

      {/* Controls */}
      <Flex gap={2} align="center">
        {/* Search */}
        <Box minW={{ base: "full", md: "200px" }}>
          <SearchInput
            value={localSearchQuery}
            onChange={(val) => setLocalSearchQuery(val)}
            placeholder="Search insights..."
          />
        </Box>

        {/* View Toggle */}
        <Tooltip label={view === "grid" ? "Switch to list view" : "Switch to grid view"}>
          <IconButton
            aria-label="Toggle view"
            icon={view === "grid" ? <MdList /> : <MdGrid3X3 />}
            onClick={() => setView(view === "grid" ? "list" : "grid")}
            variant="outline"
            size="md"
          />
        </Tooltip>

        {/* Demo Data Toggle */}
        <Tooltip label={displayDemoData ? "Show your data" : "Show demo data"}>
          <IconButton
            aria-label="Toggle demo data"
            icon={displayDemoData ? <RiEyeOffFill /> : <RiEyeFill />}
            onClick={() => setDisplayDemoData(!displayDemoData)}
            variant="outline"
            size="md"
            colorScheme={displayDemoData ? "blue" : "gray"}
          />
        </Tooltip>
      </Flex>
    </Flex>
  );
};

export default CategoryFilter;
