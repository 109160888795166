import React, { useEffect } from "react";
import {
  Drawer,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  Link,
  VStack,
  HStack,
  Text,
  Flex,
  Tooltip,
  Heading,
  Box,
  keyframes,
  Icon,
  Badge,
  Divider,
} from "@chakra-ui/react";
import { getKnowledgeBaseArticles } from "../../api";
import { BiLinkExternal, BiLogoYoutube } from "react-icons/bi";
import { MdHelp } from "react-icons/md";
import { FaDiscord } from "react-icons/fa";
import { BsChatDotsFill } from "react-icons/bs";
import DiscordInvite from "../../components/misc/DiscordInvite";
import { SecondaryButton } from "../../components/design_library";
import { Spinner } from "../../components";
import { KnowledgeBaseHelperTopics } from "../../types";
import { pulse } from "../../components/animation/Pulise";

const pulseAnimation = `${pulse} 2s infinite`;

export default function KnowledgeBaseHelper({ topic }: { topic: string }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = React.useState(true);
  const [articles, setArticles] = React.useState([]);
  const [isFirstTimeUser, setIsFirstTimeUser] = React.useState(false);

  const getArticles = async () => {
    const as = await getKnowledgeBaseArticles(true, topic);
    setArticles(as.articles);
    setLoading(false);
  };

  useEffect(() => {
    getArticles();
  }, []);

  useEffect(() => {
    if (!loading && articles.length > 0) {
      const visitedKey = `hasVisited_${topic}`;
      const hasVisited = localStorage.getItem(visitedKey);
      if (!hasVisited) {
        setIsFirstTimeUser(true);
        localStorage.setItem(visitedKey, "true");
      }
    }
  }, [loading, articles, topic, onOpen]);

  if (articles.length === 0) return null;

  return (
    <>
      <SecondaryButton
        animation={isFirstTimeUser ? pulseAnimation : undefined}
        bg="var(--dark-gray)"
        border="0"
        onClick={() => {
          onOpen();
          setIsFirstTimeUser(false);
        }}
        h="100%"
        maxH="38px"
        _hover={{ bg: "var(--hover-gray)" }}
        transition="all 0.2s"
      >
        <Icon as={MdHelp} color="var(--accent)" boxSize={5} />
        <Text pl="4px" fontSize="14px" fontWeight="medium">
          Help
        </Text>
      </SecondaryButton>

      <Drawer onClose={onClose} isOpen={isOpen} size="sm">
        <DrawerOverlay backdropFilter="blur(4px)" />
        <DrawerContent bg="var(--bg)" boxShadow="2xl">
          <DrawerCloseButton
            onClick={() => {
              onClose();
              setIsFirstTimeUser(false);
            }}
            size="lg"
            p={6}
          />
          <Flex bg="var(--dark-gray)" p={4} alignItems="center">
            <Icon as={MdHelp} color="var(--accent)" boxSize={6} mr={3} />
            <DrawerHeader flex={1} fontSize="xl" p={0}>
              {topic} Guide
            </DrawerHeader>
          </Flex>

          <VStack spacing={8} p={6} align="stretch">
            <Box>
              <Heading size="md" mb={4}>
                Articles
                <Badge ml={2} colorScheme="green">
                  {articles.length}
                </Badge>
              </Heading>
              <VStack spacing={3} align="stretch">
                {articles
                  .sort((a, b) => a.title.localeCompare(b.title))
                  .map((article) => (
                    <Tooltip key={article.id} label={article.excerpt} placement="left">
                      <Link
                        p={3}
                        borderRadius="md"
                        bg="var(--dark-gray)"
                        _hover={{
                          bg: "var(--hover-gray)",
                          textDecoration: "none",
                        }}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        href={`https://wealthbee.io/knowledgebase/${article.slug}`}
                        target="_blank"
                      >
                        <Text fontSize="15px" noOfLines={1}>
                          {article.title}
                        </Text>
                        <Icon as={BiLinkExternal} ml={2} />
                      </Link>
                    </Tooltip>
                  ))}
              </VStack>
            </Box>

            {(topic === KnowledgeBaseHelperTopics.DASHBOARD ||
              topic === KnowledgeBaseHelperTopics.TRANSACTIONS ||
              topic === KnowledgeBaseHelperTopics.POSITIONS) && (
              <Box>
                <Heading size="md" mb={4}>
                  Audio Guide
                </Heading>
                <Box
                  p={4}
                  bg="var(--dark-gray)"
                  borderRadius="md"
                >
                  <audio
                    controls
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                    }}
                    controlsList="nodownload"
                  >
                    <source
                      src={
                        topic === KnowledgeBaseHelperTopics.DASHBOARD
                          ? "https://cdn.wealthbee.io/main/audio/dashboard.mp3"
                          : topic === KnowledgeBaseHelperTopics.POSITIONS
                          ? "https://cdn.wealthbee.io/main/audio/positions.mp3"
                          : topic === KnowledgeBaseHelperTopics.TRANSACTIONS
                          ? "https://cdn.wealthbee.io/main/audio/transactions.mp3"
                          : ""
                      }
                      type="audio/mpeg"
                    />
                    Your browser does not support the audio element.
                  </audio>
                </Box>
              </Box>
            )}

            <Box>
              <Heading size="md" mb={4}>
                Additional Support
              </Heading>
              <VStack spacing={4}>
                <Link
                  w="100%"
                  href="https://www.youtube.com/@WealthBeeIo"
                  target="_blank"
                  _hover={{ textDecoration: "none" }}
                >
                  <Button
                    w="100%"
                    leftIcon={<BiLogoYoutube size="24px" />}
                    colorScheme="red"
                    variant="solid"
                  >
                    YouTube Tutorials
                  </Button>
                </Link>

                <DiscordInvite />

                <Button
                  w="100%"
                  colorScheme="blue"
                  leftIcon={<BsChatDotsFill />}
                  onClick={() => {
                    $crisp.push(["do", "chat:open"]);
                  }}
                >
                  Live Support Chat
                </Button>
              </VStack>
            </Box>
          </VStack>
        </DrawerContent>
      </Drawer>
    </>
  );
}
