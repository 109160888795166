import React from "react";
import { Stack, Text } from "@chakra-ui/react";
import ReactDatePicker from "react-datepicker";
import { Required } from "./Required";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { LeftArrowIcon, RightArrowIcon } from "../../design_library";
import "./style.css";
export const DatePicker = (props: {
  label: string;
  value: string; // Assume this is an ISO string (UTC-based)
  field: string;
  required: boolean;
  error: string;
  onChange: (field: string, value: string) => void;
}) => {
  const dateDefault = props.value
    ? moment.utc(props.value).toDate()
    : moment.utc(new Date()).toDate();

  return (
    <Stack spacing="4" mt="2" mb="1">
      <Text>
        {props.label}
        {props.required && <Required />}
      </Text>
      <ReactDatePicker
        preventOpenOnFocus={true}
        className={"react-datepicker react-datepicker-wrapper"}
        renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => {
          return (
            <div>
              <button
                aria-label="Previous Month"
                className={
                  "react-datepicker__navigation react-datepicker__navigation--previous"
                }
                onClick={decreaseMonth}
              >
                <LeftArrowIcon className={"react-datepicker-icon-left"} />
              </button>
              <span className="react-datepicker__current-month">
                {moment.utc(date).format("MMMM YYYY")}
              </span>
              <button
                aria-label="Next Month"
                className={
                  "react-datepicker__navigation react-datepicker__navigation--next"
                }
                onClick={increaseMonth}
              >
                <RightArrowIcon className={"react-datepicker-icon-right"} />
              </button>
            </div>
          );
        }}
        selected={dateDefault}
        onChange={(date) => {
          if (!date) {
            props.onChange(props.field, "");
            return;
          }
          props.onChange(props.field, moment(date).toISOString());
        }}
        dateFormat="MM/dd/yyyy"
      />
      <Text color="red.500">{props.error}</Text>
    </Stack>
  );
};
