import { Box, Center, Heading, Text, VStack } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import DiscordInvite from "../misc/DiscordInvite";
import { PrimaryButton } from "../design_library";
import { MdError } from "react-icons/md";
import { AppStateAtom } from "../../store";
import { useAtomValue } from "jotai";

function useErrorBoundary() {
  const appState = useAtomValue(AppStateAtom);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (hasError && error) {
      console.log("An error has occurred: ", error);
      console.log(error.stack || "");

      // Only run Crisp actions if not in localhost
      if (!window.location.href.includes("localhost")) {
        $crisp.push(["do", "chat:open"]);
        $crisp.push(["set", "user:email", appState.user.email]);
        $crisp.push([
          "do",
          "message:send",
          [
            "text",
            `Automated message:\n\n An error has occurred: ${error.message} on ${window.location.href}.`,
          ],
        ]);
      }
    }
  }, [hasError, error]);

  const resetError = () => {
    setHasError(false);
    setError(null);
  };

  const triggerError = (error: Error) => {
    setHasError(true);
    setError(error);
  };

  return { hasError, error, resetError, triggerError };
}

const ErrorBoundary: React.FC = ({ children }) => {
  const { hasError, error, resetError } = useErrorBoundary();

  if (hasError) {
    return (
      <Box pt="60px">
        <Center>
          <VStack>
            <MdError size="100px" />
            <Heading>Something went wrong!</Heading>
            <Text>
              This error has been reported. Please refresh the page and try
              again.
            </Text>
            <Text pb="20px">If this persists please contact support!</Text>
            <DiscordInvite />
            <Text fontSize="xs" color="var(--red)" pt="40px">
              {error && error.toString()}
            </Text>
            <PrimaryButton onClick={() => window.location.reload()}>
              Click here to reload the website
            </PrimaryButton>
          </VStack>
        </Center>
      </Box>
    );
  }

  return children;
};

export default ErrorBoundary;
