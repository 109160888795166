import React from "react";
import { VStack } from "@chakra-ui/react";
import {
  CustomLoadingOverlayProps,
  CustomNoRowsOverlayProps,
} from "ag-grid-react";
import { Spinner } from "../misc";

export const overlayNoRowsTemplate = (
  props: CustomNoRowsOverlayProps & { noRowsMessageFunc: () => string }
) => {
  return (
    <div>
      <i className="far fa-frown" aria-live="polite" aria-atomic="true">
        {props.noRowsMessageFunc()}
      </i>
    </div>
  );
};

export const overlayLoadingTemplate = (
  props: CustomLoadingOverlayProps & { loadingMessage: string }
) => {
  return (
    <VStack role="presentation">
      <div
        style={{
          height: 60,
          width: 100,
          justifySelf: "center",
          alignSelf: "center",
          textAlign: "center",
        }}
      >
        <Spinner />
      </div>
      <div aria-live="polite" aria-atomic="true">
        {props.loadingMessage}
      </div>
    </VStack>
  );
};
