import { Box, Flex, HStack, Text, Tooltip } from "@chakra-ui/react";
import moment from "moment";
import Notes from "../../../features/notes/Notes";
import { calculateDaysUntilExpiry } from "../utils/calculateDaysUntilExpiry";
import { FastTag } from "../../design_library";
import React from "react";
import { PutCall } from "../../../types";
import { FaArrowDown, FaArrowRight, FaArrowUp } from "react-icons/fa";

export const TradeDetailsRender = (params: any, page: string) => {
  let trade = params?.node?.data;
  let instrument = trade?.instrument;
  if (!instrument) {
    return <></>;
  }
  let daysUntilExpiry = calculateDaysUntilExpiry(instrument);

  let otmOrItm;
  let otm = false;
  let itm = false;
  let atm = false;
  if (
    instrument.putCall === PutCall.Put &&
    instrument.strike > trade.underlyingPrice
  ) {
    otmOrItm = "In the money";
    itm = true;
  } else if (
    instrument.putCall === PutCall.Call &&
    instrument.strike < trade.underlyingPrice
  ) {
    otmOrItm = "In the money";
    itm = true;
  } else if (
    instrument.putCall === PutCall.Put &&
    instrument.strike < trade.underlyingPrice
  ) {
    otmOrItm = "Out of the money";
    otm = true;
  } else if (
    instrument.putCall === PutCall.Call &&
    instrument.strike > trade.underlyingPrice
  ) {
    otmOrItm = "Out of the money";
    otm = true;
  } else if (
    instrument.putCall === PutCall.Call &&
    instrument.strike === trade.underlyingPrice
  ) {
    otmOrItm = "At the money";
    atm = true;
  } else if (
    instrument.putCall === PutCall.Put &&
    instrument.strike === trade.underlyingPrice
  ) {
    otmOrItm = "At the money";
    atm = true;
  } else {
    otmOrItm = undefined;
  }

  return (
    <HStack
      fontWeight={400}
      justifyContent={"space-between"}
      textAlign={"right"}
      color={params.data.quantity === 0 ? "var(--light-gray)" : "var(--white)"}
    >
      {page === "Positions" && (
        <FastTag
          h="24px"
          backgroundColor={
            params.node.data.quantity === 0
              ? "var(--trade-closed)"
              : "var(--trade-open)"
          }
        >
          {params.node.data.quantity === 0 ? "Closed" : "Open"}
        </FastTag>
      )}
      <FastTag h="24px">{params.node.data.quantity}</FastTag>
      {instrument.strike ? (
        <>
          <FastTag
            size="sm"
            h="24px"
            backgroundColor={
              daysUntilExpiry > 0 ? "var(--trade-open)" : "var(--trade-closed)"
            }
          >
            {moment(instrument.expiry).format("MMM Do")} (
            {daysUntilExpiry >= 0
              ? daysUntilExpiry.toFixed(0) + "d"
              : "Expired"}
            )
          </FastTag>

          <FastTag h="24px">
            <Tooltip label={otmOrItm} aria-label="OTM or ITM">
              <HStack mt="-7px" height="inherit">
                <Flex lineHeight={1}>
                  <Text>{instrument.strike?.toFixed(2)}</Text>
                </Flex>
                {itm === true || otm === true || atm === true ? (
                  <Text px={0} fontSize="xx-small">
                    {itm && <FaArrowDown />}
                    {otm && <FaArrowUp />}
                    {atm && <FaArrowRight />}
                  </Text>
                ) : (
                  <Box w="2px" h="24px" backgroundColor="transparent"></Box>
                )}
              </HStack>
            </Tooltip>
          </FastTag>

          <FastTag
            h="24px"
            backgroundColor={`var(--trade-${instrument.putCall.toLowerCase()})`}
          >
            {instrument?.putCall}
          </FastTag>
        </>
      ) : (
        <FastTag h="24px" backgroundColor="var(--trade-stock)">
          {instrument?.instrumentType}
        </FastTag>
      )}

      <Notes
        tags={trade.tags && trade.tags[0] !== "" ? trade.tags : null}
        notes={trade.notes !== "<p></p>" ? trade.notes : null}
      />
    </HStack>
  );
};
