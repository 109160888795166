import { background, Box, Flex, HStack, Spinner, Text } from "@chakra-ui/react";
import React, { useMemo } from "react";
import moment from "moment";
// @ts-ignore
import { Calendar, momentLocalizer } from "../../components/calendar";
import "./Journal.css";
import { CalendarNotesModal } from "../../components/calendar/CalendarNoteModal";
import { useCalendarController } from "./useCalendarController";
import { Toolbar } from "../../components/toolbar/Toolbar";
import { KnowledgeBaseHelperTopics, ToolbarFeatures } from "../../types";
import { RiCalendar2Fill } from "react-icons/ri";
import { InsightWrapper2 } from "../insights/container";

const localizer = momentLocalizer(moment);

const CalendarComponent: React.FC = () => {
  const controller = useCalendarController();

  // Memoize calendar props to prevent unnecessary re-renders
  const calendarProps = useMemo(() => ({
    setDay: (d: Date) => {
      controller.createNote("", moment(d).format("YYYY-MM-DD"));
    },
    day: controller.day,
    localizer,
    events: controller.events,
    notes: controller.notes,
    earnings: controller.earnings,
    expiries: controller.expiries,
    pnlByDate: controller.pnlByDate,
    trades: controller.trades,
    numberOfTradedDays: controller.numberOfTradedDays,
    pnlThisMonth: controller.pnlThisMonth,
    startAccessor: "start",
    endAccessor: "end",
    style: { minHeight: "85vh", height: "100%", width: "100%" },
    onNavigate: (date: Date) => {
      controller.setMonth(moment.utc(date).format("MMM YYYY"));
      controller.getNotes(moment.utc(date).format("MMM YYYY"));
    },
  }), [
    controller.day,
    controller.events,
    controller.notes,
    controller.earnings,
    controller.expiries,
    controller.pnlByDate,
    controller.trades,
    controller.numberOfTradedDays,
    controller.pnlThisMonth,
    controller.createNote,
    controller.setMonth,
    controller.getNotes
  ]);

  // Memoize modal props to prevent unnecessary re-renders
  const modalProps = useMemo(() => ({
    isOpen: controller.isOpen,
    onClose: () => {
      controller.setIsOpen(false);
      controller.getNotes(controller.month);
    },
    note: controller.selectedNote,
    date: controller.selectedNote?.calendarDateReference,
    expiries: controller.expiriesForThisDate,
    trades: controller.tradesForThisDate,
    earnings: controller.earningsForThisDate,
  }), [
    controller.isOpen,
    controller.selectedNote,
    controller.expiriesForThisDate,
    controller.tradesForThisDate,
    controller.earningsForThisDate,
    controller.month,
    controller.getNotes,
    controller.setIsOpen
  ]);

  return (
    <Box w="full">
      {controller.loading && (
        <Box
          pos="fixed"
          bg="var(--bg)"
          w="100vw"
          h="100vh"
          top="0"
          left="0"
          zIndex={99}
          opacity="0.2"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner opacity="1" size="xl" />
        </Box>
      )}

      <Toolbar
        enabledOptions={[ToolbarFeatures.TITLE, ToolbarFeatures.DESCRIPTION]}
        title="Calendar"
        icon={<RiCalendar2Fill />}
        description="
          The calendar shows your daily P&L, trades, and notes. Click on a day to add a note or view trades and expiries."
        helpKey={KnowledgeBaseHelperTopics.DASHBOARD}
      />

      <Flex flex={1} px={2}>
        <CalendarNotesModal {...modalProps} />
        <Box
          mt="10px"
          borderRadius="lg"
          boxShadow="lg"
          p={4}
          overflow="hidden"
          w="full"
          bg="var(--bg)"
          border="1px solid var(--bg)"
          userSelect="none"
        >
          <Calendar {...calendarProps} />
          <Text fontSize="sm" color="var(--light-gray)" mt="15px">
            *Earnings dates are provisional until confirmed by the company.
            These are updated once per day.
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default React.memo(CalendarComponent);
