import { Box, Divider, HStack, Stack, Text } from "@chakra-ui/react";
import { Trade } from "../../types";
import moment from "moment";
import convertSymbolToReadable from "../grid/utils/convertSymbolToReadable";
import NoteEditor from "../texteditor/NoteEditor";
import React from "react";
import { FastTag } from "../design_library";

interface NotesTimelineProps {
  trades: Trade[];
}

const CustomDivider = (props: any) => {
  const { thisTrade, lastTrade } = props;

  const daysBetween = moment
    .utc(thisTrade?.tradeDate)
    .diff(moment.utc(lastTrade?.tradeDate, "YYYY-MM-DDTHH:mm:ss.SSSZ"), "days");

  let firstTrade = false;
  let finalTrade = false;

  if (lastTrade === undefined) {
    firstTrade = true;
  }
  if (lastTrade === null) {
    finalTrade = true;
  }

  return (
    <HStack mt="20px">
      <Divider orientation="horizontal" />
      {daysBetween === 0 ? (
        <Text
          minW="80px"
          textAlign={"center"}
          color="var(--light-gray)"
          fontSize={"12px"}
        >
          Same day
        </Text>
      ) : (
        <Text
          minW="80px"
          textAlign={"center"}
          color="var(--light-gray)"
          fontSize={"12px"}
        >
          {firstTrade === false &&
            finalTrade === false &&
            daysBetween + " days"}
          {firstTrade && " First trade"}
          {finalTrade && " Last trade"}
        </Text>
      )}
      <Divider orientation="horizontal" />
    </HStack>
  );
};

export const NotesTimeline = (props: NotesTimelineProps) => {
  return (
    <Stack
      spacing="4"
      overflow={"auto"}
      maxH="100%"
      minH="100%"
      pb="200px"
      borderBottom={"0px"}
    >
      {props.trades.map((trade, index) => {
        return (
          <Stack
            key={"note-post-" + index + "-" + trade.id}
            fontSize="sm"
            px="4"
            spacing="0.5"
          >
            <HStack wrap="wrap" alignItems={"flex-start"} display="flex">
              <Box flex="1" pt="4">
                <Text fontWeight="700" color="var(--grey)">
                  {convertSymbolToReadable(trade?.instrument?.symbol)}{" "}
                  {trade.quantity}{" "}
                  <span
                    style={{
                      color:
                        trade.buySell === "Buy" ? "var(--green)" : "var(--red)",
                    }}
                  >
                    {trade.buySell}
                  </span>{" "}
                  at {trade.price} {trade.currency}
                </Text>
                <Text color="var(--light-gray)">
                  Trade date: {moment(trade.tradeDate).calendar()}
                </Text>
              </Box>

              {trade.tags && trade.tags.length > 0 && (
                <HStack mt="10px">
                  {trade.tags?.map((tag, index) => {
                    if (tag === "") return null;
                    return <FastTag key={"tag-" + index}>{tag}</FastTag>;
                  })}
                </HStack>
              )}

              <Box w="full">
                <NoteEditor
                  trade={trade}
                  menuBarEnabled={true}
                  tagsEnabled={true}
                />
              </Box>
            </HStack>

            <CustomDivider
              thisTrade={trade}
              lastTrade={index > 0 ? props.trades[index + 1] : null}
            />
          </Stack>
        );
      })}
    </Stack>
  );
};
