import React from "react";
import { Button, Flex, HStack, Text } from "@chakra-ui/react";
import { Show } from "../../components";
import { useQuickFilterController } from "./useQuickFilterController";
import { RiCloseFill, RiStockFill } from "react-icons/ri";
import { ToolbarDropDown } from "../../components/design_library/toolbar/ToolbarDropDown";
import mixpanel from "mixpanel-browser";
import { MdBook } from "react-icons/md";
import { BiArrowToRight, BiPaperPlane } from "react-icons/bi";
import { FaTags } from "react-icons/fa";

export const QuickFilter = () => {
  const controller = useQuickFilterController();

  return (
    <Show if={controller.quickFilter.enabled}>
      <HStack mx={2} mb={2}>
        <Flex>
          <Button
            size={"sm"}
            aria-expanded={controller.quickFilter.enabled}
            onClick={() => controller.toggleQuickFilter()}
          >
            <HStack cursor="pointer">
              <Text>Quick Filter</Text>
              <RiCloseFill />
            </HStack>
          </Button>
        </Flex>
        <Flex>
          <ToolbarDropDown
            value={
              controller.quickFilter.underlyings.length > 0 &&
              controller.quickFilter.underlyings[0] !== "All"
                ? {
                    label: controller.quickFilter.underlyings[0],
                    value: controller.quickFilter.underlyings[0],
                  }
                : {
                    label: (
                      <HStack color="var(--white)">
                        <RiStockFill fill="var(--accent)" />
                        <Text
                          display={{
                            base: "none",
                            md: "block",
                          }}
                        >
                          Underlying ({controller.underlyings.length - 1})
                        </Text>
                      </HStack>
                    ),
                    value: "All",
                  }
            }
            className="tool-bar-select"
            placeholder={
              <HStack color="var(--white)">
                <RiStockFill fill="var(--accent)" />
                <Text
                  display={{
                    base: "none",
                    lg: "block",
                  }}
                >
                  Underlying ({controller.underlyings.length - 1})
                </Text>
              </HStack>
            }
            options={controller.underlyings.map((underlying) => ({
              value: underlying,
              label: underlying,
            }))}
            onChange={(event: any) => {
              mixpanel.track("Underlying Filter Changed", {
                underlying: event.value ?? "All",
              });
              controller.setSelectedUnderlying(
                !event.value || event.value === "All" ? undefined : event.value
              );
            }}
          />
        </Flex>

        <Flex>
          <ToolbarDropDown
            className="tool-bar-select"
            placeholder={
              <HStack color="var(--white)">
                <MdBook fill="var(--accent)" />
                <Text
                  display={{
                    base: "none",
                    lg: "block",
                  }}
                >
                  Books
                </Text>
              </HStack>
            }
            options={controller.books.map((book) => ({
              value: book,
              label: book,
            }))}
            value={
              controller.quickFilter.books.length > 0 &&
              controller.quickFilter.books[0] !== "All"
                ? {
                    label: controller.quickFilter.books[0],
                    value: controller.quickFilter.books[0],
                  }
                : {
                    label: (
                      <HStack color="var(--white)">
                        <MdBook fill="var(--accent)" />
                        <Text
                          display={{
                            base: "none",
                            md: "block",
                          }}
                        >
                          Books
                        </Text>
                      </HStack>
                    ),
                    value: "All",
                  }
            }
            onChange={(event: any) => {
              controller?.setSelectedBook(event.value);
            }}
          />
        </Flex>

        <Flex>
          <ToolbarDropDown
            className="tool-bar-select"
            placeholder={
              <HStack color="var(--white)">
                <BiArrowToRight fill="var(--accent)" />
                <Text
                  display={{
                    base: "none",
                    lg: "block",
                  }}
                >
                  Position Groups
                </Text>
              </HStack>
            }
            options={controller.positionGroups.map((group) => ({
              value: group,
              label: group,
            }))}
            value={
              controller.quickFilter.positionGroups.length > 0 &&
              controller.quickFilter.positionGroups[0] !== "All"
                ? {
                    label: controller.quickFilter.positionGroups[0],
                    value: controller.quickFilter.positionGroups[0],
                  }
                : {
                    label: (
                      <HStack color="var(--white)">
                        <BiArrowToRight fill="var(--accent)" />
                        <Text
                          display={{
                            base: "none",
                            md: "block",
                          }}
                        >
                          Position Groups
                        </Text>
                      </HStack>
                    ),
                    value: "All",
                  }
            }
            onChange={(event: any) => {
              controller?.setSelectedPositionGroup(event.value);
            }}
          />
        </Flex>

        <Flex>
          <ToolbarDropDown
            className="tool-bar-select"
            placeholder={
              <HStack color="var(--white)">
                <BiPaperPlane fill="var(--accent)" />
                <Text
                  display={{
                    base: "none",
                    lg: "block",
                  }}
                >
                  Strategy
                </Text>
              </HStack>
            }
            options={controller.strategies?.map((strategy) => ({
              value: strategy,
              label: strategy,
            }))}
            value={
              controller.quickFilter.strategies.length > 0 &&
              controller.quickFilter.strategies[0] !== "All"
                ? {
                    label: controller.quickFilter.strategies[0],
                    value: controller.quickFilter.strategies[0],
                  }
                : {
                    label: (
                      <HStack color="var(--white)">
                        <BiPaperPlane fill="var(--accent)" />

                        <Text
                          display={{
                            base: "none",
                            md: "block",
                          }}
                        >
                          Strategy
                        </Text>
                      </HStack>
                    ),
                    value: "All",
                  }
            }
            onChange={(event: any) => {
              controller?.setSelectedStrategy(event.value);
            }}
          />
        </Flex>

        <Flex>
          <ToolbarDropDown
            className="tool-bar-select"
            placeholder={
              <HStack color="var(--white)">
                <FaTags fill="var(--accent)" />
                <Text
                  display={{
                    base: "none",
                    lg: "block",
                  }}
                >
                  Tags
                </Text>
              </HStack>
            }
            options={controller.tags?.map((tag) => ({
              value: tag,
              label: tag,
            }))}
            value={
              controller.quickFilter.tags.length > 0 &&
              controller.quickFilter.tags[0] !== "All"
                ? {
                    label: controller.quickFilter.tags[0],
                    value: controller.quickFilter.tags[0],
                  }
                : {
                    label: (
                      <HStack color="var(--white)">
                        <FaTags fill="var(--accent)" />
                        <Text
                          display={{
                            base: "none",
                            md: "block",
                          }}
                        >
                          Tags
                        </Text>
                      </HStack>
                    ),
                    value: "All",
                  }
            }
            onChange={(event: any) => {
              controller.setSelectedTag(event.value);
            }}
          />
        </Flex>

        <Flex>
          <Button
            size="xs"
            colorScheme="blue"
            height="30px"
            onClick={() => controller.clear()}
          >
            Clear
          </Button>
        </Flex>
      </HStack>
    </Show>
  );
};
