import React, { useEffect } from "react";
import {
  Button,
  Text,
  VStack,
  Input,
  HStack,
  IconButton,
  Box,
  Tooltip,
  Heading,
  Flex,
  Badge,
  useColorModeValue,
  InputGroup,
  InputRightElement,
  Divider,
  useToast,
} from "@chakra-ui/react";
import { CloseIcon, InfoIcon, AddIcon, CopyIcon } from "@chakra-ui/icons";
import { useReferAFriendController } from "./useReferAFriendController";
import { Title } from "../../components/design_library";
import { BiGift } from "react-icons/bi";
import { getReferAFriendValue } from "../../api/Referrals";

const ReferAFriendComponent = ({
  hideLaterButton,
}: {
  hideLaterButton?: boolean;
}) => {
  const controller = useReferAFriendController();
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const toast = useToast();
  
  // Colors that adapt to theme
  const accentColor = useColorModeValue("blue.500", "blue.300");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  
  const fetchReferralBonusAmount = async () => {
    setLoading(true);
    const data = await getReferAFriendValue();
    setValue(data.value);
    setLoading(false);
  };

  useEffect(() => {
    fetchReferralBonusAmount();
  }, []);

  const handleCopyLink = () => {
    controller.generateLink();
    toast({
      title: "Link copied to clipboard",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  if (loading === true) {
    return <Text>Loading...</Text>;
  }
  
  return (
    <VStack spacing={6} align="stretch">
      <Flex justifyContent="space-between" alignItems="center">
        <HStack spacing={3}>
          <Box 
            bg={useColorModeValue("yellow.100", "yellow.900")} 
            p={2} 
            borderRadius="md" 
            color={"var(--accent)"}
          >
            <BiGift fontSize="28px" />
          </Box>
          <Heading as="h2" fontSize="2xl" fontWeight="bold">
            Refer a Friend
          </Heading>
        </HStack>
        <Tooltip
          label={`Credit is applied when invitee starts their subscription. Max $${value} per calendar month.`}
          placement="top"
          hasArrow
        >
          <InfoIcon color="gray.500" />
        </Tooltip>
      </Flex>

      <Box>
        <Text fontSize="md" mb={4}>
          Invite a friend and receive{" "}
          <Text
            as="span"
            fontWeight="bold"
            fontSize="xl"
            color={"var(--accent)"}
          >
            ${value} credit
          </Text>{" "}
          when they start their subscription. Share with your friends and earn
          rewards for each successful referral.
        </Text>
        <Text fontSize="sm" color="gray.500" mb={4}>
          Referral status can be viewed from the settings page.
        </Text>

        <Divider mb={4} />

        <VStack spacing={3} align="stretch">
          {controller.emails.map((email, index) => (
            <InputGroup key={index} size="md">
              <Input
                placeholder="Enter friend's email"
                value={email}
                onChange={(e) =>
                  controller.handleEmailChange(index, e.target.value)
                }
                borderColor={borderColor}
                _focus={{ borderColor: accentColor }}
              />
              {controller.emails.length > 1 && (
                <InputRightElement>
                  <IconButton
                    icon={<CloseIcon />}
                    size="sm"
                    variant="ghost"
                    colorScheme="red"
                    onClick={() => controller.removeEmailField(index)}
                    aria-label="Remove email"
                  />
                </InputRightElement>
              )}
            </InputGroup>
          ))}
        </VStack>

        {controller.error && (
          <Text color="red.500" mt={2} fontSize="sm">
            {controller.error}
          </Text>
        )}

        <Button
          leftIcon={<AddIcon />}
          mt={4}
          onClick={controller.addEmailField}
          variant="outline"
          size="md"
          width="full"
          borderColor={borderColor}
        >
          Add Another Email
        </Button>
      </Box>

      <Divider />

      <VStack spacing={3} width="full">
        <Button
          width="full"
          colorScheme="blue"
          onClick={controller.inviteEmails}
          isLoading={controller.loading}
          height="44px"
          fontWeight={500}
          bg="var(--accent)"
          color="white"
          _hover={{ bg: "var(--accent)", opacity: 0.9 }}
        >
          Send Invites
        </Button>

        <Text fontSize="sm" color="gray.500">
          Or
        </Text>
        
        <Button 
          width="full" 
          variant="outline"
          borderColor={borderColor}
          leftIcon={<CopyIcon />}
          onClick={handleCopyLink}
          height="44px"
        >
          Copy Invite Link
        </Button>

        {controller.status === "success" && (
          <Badge colorScheme="green" p={2} borderRadius="md" mt={2}>
            Invites sent successfully!
          </Badge>
        )}

        {controller.status === "error" && (
          <Badge colorScheme="red" p={2} borderRadius="md" mt={2}>
            Something went wrong. One of these emails might already be in use.
          </Badge>
        )}
      </VStack>
    </VStack>
  );
};

export default ReferAFriendComponent;
