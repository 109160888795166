import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Heading,
  VStack,
  HStack,
  Avatar,
  Input,
  Divider,
  Box,
  FormLabel,
  Tooltip,
  Portal,
  Text,
  useToast,
  Flex,
  IconButton,
} from "@chakra-ui/react";
import { useAtomValue } from "jotai";
import { BiTrash, BiEdit, BiSave } from "react-icons/bi";
import { MdAccountTree, MdAdd } from "react-icons/md";
import { AccountAtom } from "../../store";
import { SecondaryButton, Dialog } from "../design_library";
import useAccountController from "../AccountSelector/useAccountController";
import React, { useState } from "react";
import ModalHeader from "../design_library/ModalHeader";
import ExportAccount from "../ExportAccount/ExportAccount";

export const MangageAccounts = () => {
  const controller = useAccountController();
  const accountState = useAtomValue(AccountAtom);
  const toast = useToast();
  const [editingAccountId, setEditingAccountId] = useState<number | null>(null);
  const [editingName, setEditingName] = useState<string>("");

  const handleStartEditing = (account: any) => {
    setEditingAccountId(account.id);
    setEditingName(account.name);
  };

  const handleSaveAccountName = (accountId: number) => {
    if (editingName.trim() === "") {
      toast({
        title: "Account name cannot be empty",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    
    const account = accountState.accounts.find(acc => acc.id === accountId);
    if (account) {
      controller.updateAccountName(accountId, editingName, account);
      setEditingAccountId(null);
      
      toast({
        title: "Account name updated",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <SecondaryButton
        variant="ghost"
        justifyContent="center"
        onClick={() => {
          controller.setModalOpen(true);
        }}
        width="full"
      >
        Manage portfolios
      </SecondaryButton>

      <Modal
        size={"3xl"}
        isOpen={controller.modalOpen}
        onClose={() => controller.setModalOpen(false)}
      >
        <ModalOverlay backdropFilter="blur(3px)" />

        <ModalContent bg="var(--bg)" maxW="550px">
          <ModalHeader
            icon={<MdAccountTree />}
            title={"Portfolio Management"}
            description={
              "Export your trades, update & delete your portfolios, or create a new one."
            }
          />
          <ModalBody pb="40px" pt="20px">
            <Heading fontSize={"md"} mb="16px">
              Your Portfolios
            </Heading>

            <VStack spacing={3} align="stretch" mb="24px">
              {accountState.accounts
                .sort((a: any, b: any) => a.id - b.id)
                .map((account: any) => (
                  <Box
                    key={account.id}
                    p={3}
                    borderRadius="8px"
                    border="1px solid var(--gray)"
                    _hover={{ borderColor: "var(--primary)" }}
                    transition="all 0.2s"
                  >
                    <HStack
                      w="full"
                      justifyContent={"space-between"}
                    >
                      <HStack spacing={3}>
                        <Avatar
                          bg={"var(--gray)"}
                          name={account.name}
                          w="40px"
                          h="40px"
                          borderRadius={6}
                          color={"var(--white)"}
                        />
                        
                        {editingAccountId === account.id ? (
                          <Flex align="center">
                            <Input
                              value={editingName}
                              onChange={(e) => setEditingName(e.target.value)}
                              placeholder="Enter account name"
                              size="md"
                              autoFocus
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                  handleSaveAccountName(account.id);
                                }
                              }}
                            />
                            <IconButton
                              aria-label="Save account name"
                              icon={<BiSave />}
                              size="sm"
                              ml={2}
                              onClick={() => handleSaveAccountName(account.id)}
                            />
                          </Flex>
                        ) : (
                          <HStack>
                            <Text fontWeight="medium">{account.name}</Text>
                            <Tooltip label="Edit name">
                              <IconButton
                                aria-label="Edit account name"
                                icon={<BiEdit />}
                                size="xs"
                                variant="ghost"
                                onClick={() => handleStartEditing(account)}
                              />
                            </Tooltip>
                          </HStack>
                        )}
                      </HStack>

                      <HStack spacing={2}>
                        <Dialog
                          buttonSize="sm"
                          title={"Delete all transactions"}
                          buttonText="Delete Trades"
                          description={`Are you sure you want to delete all transactions for portfolio "${account.name}"?`}
                          confirmText={"Confirm"}
                          onConfirm={() => {
                            controller.deleteAllTheTrades(account.id);
                            toast({
                              title: "All trades deleted",
                              status: "success",
                              duration: 2000,
                              isClosable: true,
                            });
                          }}
                        />
                        <Dialog
                          buttonSize="sm"
                          title={"Remove Portfolio"}
                          disabled={accountState.accounts.length === 1}
                          buttonText="Delete Portfolio"
                          description={`Are you sure you want to delete "${account.name}" and all associated transactions?`}
                          confirmText={"Confirm"}
                          onConfirm={() => {
                            controller.handleDelete(account.id);
                            toast({
                              title: "Portfolio deleted",
                              status: "success",
                              duration: 2000,
                              isClosable: true,
                            });
                          }}
                        />
                      </HStack>
                    </HStack>
                  </Box>
                ))}
            </VStack>
            <Divider mb="20px" />
            <Box mb="24px">
              <form onSubmit={(e) => {
                e.preventDefault();
                controller.handleSubmit(e);
                toast({
                  title: controller.editingId ? "Portfolio updated" : "Portfolio created",
                  status: "success",
                  duration: 2000,
                  isClosable: true,
                });
              }}>
                <FormLabel fontWeight="medium">Add new portfolio</FormLabel>
                <HStack>
                  <Input
                    type="text"
                    name="name"
                    value={controller.accountForm.name}
                    onChange={controller.handleFormChange}
                    placeholder="Enter new portfolio name"
                    required
                  />
                  <SecondaryButton type="submit" leftIcon={<MdAdd />}>
                    {controller.editingId ? "Update" : "Create"}
                  </SecondaryButton>
                </HStack>
              </form>
            </Box>

            <Divider mb="20px" />
            <Heading fontSize={"md"} mb="16px">
              Export Data
            </Heading>
            <ExportAccount />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
