import { useState, useEffect, useRef, MutableRefObject } from "react";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { useAtomValue } from "jotai";
import { AppStateAtom } from "../../store";
import { createReferrals, getReferrals } from "../../api/Referrals";
import { getReferAFriendValue } from "../../api";

interface UseReferAFriendController {
  shouldShowPopup: boolean;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  cancelRef: MutableRefObject<any>;
  emails: string[];
  error: string;
  handleClose: () => void;
  handleEmailChange: (index: number, value: string) => void;
  addEmailField: () => void;
  removeEmailField: (index: number) => void;
  inviteEmails: () => void;
  status: string;
  loading: boolean;
  generateLink: () => string;
}

export const useReferAFriendController = (): UseReferAFriendController => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const [shouldShowPopup, setShouldShowPopup] = useState(false);
  const [emails, setEmails] = useState([""]);
  const [error, setError] = useState("");
  const appState = useAtomValue(AppStateAtom);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const toast = useToast();

  useEffect(() => {
    setShouldShowPopup(true);

    if (appState.subscriptionActive === true) {
      const lastShown = localStorage.getItem("referAFriendPopupLastShown");
      const now = new Date().getTime();

      const sevenDays = 7 * 24 * 60 * 60 * 1000;
      const thirtyDays = 30 * 24 * 60 * 60 * 1000;

      if (!lastShown) {
        const subscriptionStart = localStorage.getItem("subscriptionStartTime");
        if (!subscriptionStart) {
          localStorage.setItem("subscriptionStartTime", now);
        } else if (now - subscriptionStart > sevenDays) {
          setShouldShowPopup(true);
          localStorage.setItem("referAFriendPopupLastShown", now);
          onOpen();
        }
      } else if (now - lastShown > thirtyDays) {
        setShouldShowPopup(true);
        localStorage.setItem("referAFriendPopupLastShown", now);
        onOpen();
      }
    }
  }, [onOpen]);

  const handleClose = () => {
    setShouldShowPopup(false);
    onClose();
  };

  const generateLink = () => {
    const randomNumber = Math.floor(Math.random() * 1000000);
    toast({
      title: "Link copied to clipboard",
      status: "success",
      duration: 1400,
    });
    navigator.clipboard.writeText(
      `https://platform.wealthbee.io/app/register?referringId=${appState.user?.id}&userId=${randomNumber}`
    );

    return `https://platform.wealthbee.io/app/register?referringId=${appState.user?.id}&userId=${randomNumber}`;
  };

  const handleEmailChange = (index, value) => {
    const updatedEmails = [...emails];
    updatedEmails[index] = value;
    setEmails(updatedEmails);
  };

  const addEmailField = () => {
    setEmails([...emails, ""]);
  };

  const removeEmailField = (index) => {
    const updatedEmails = emails.filter((_, i) => i !== index);
    setEmails(updatedEmails);
  };

  const validateEmails = () => {
    for (let email of emails) {
      if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
        setError("Please enter valid email addresses.");
        return false;
      }
    }
    setError("");
    return true;
  };

  const inviteEmails = async () => {
    if (!validateEmails()) return;
    setLoading(true);
    const result = await createReferrals(emails);
    if (result.success) {
      setEmails([""]);
      setStatus("success");
    } else {
      setStatus("error");
    }
    setLoading(false);
    setShouldShowPopup(false);
    onClose();
  };

  return {
    shouldShowPopup,
    isOpen,
    onOpen,
    onClose,
    cancelRef,
    emails,
    error,
    handleClose,
    handleEmailChange,
    addEmailField,
    removeEmailField,
    loading,
    inviteEmails,
    status: status,
    generateLink,
  };
};
