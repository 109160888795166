import { useEffect, useRef } from "react";
import "./index.css";
import {
  widget,
  ChartingLibraryWidgetOptions,
  LanguageCode,
  ResolutionString,
} from "./charting_library";
import * as React from "react";
import { InstrumentType } from "../../types";
import { APIEndpoint, searchForInstruments } from "../../api";
import { getChartData } from "../../api/Datafeeds";
import convertSymbolToReadable from "../grid/utils/convertSymbolToReadable";
import { Box, useColorMode } from "@chakra-ui/react";
import { SecondaryButton } from "../design_library";
import { useAtomValue } from "jotai";
import { AppStateAtom } from "../../store";

export const TVChartContainer = (props: any) => {
  const chartContainerRef =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;

  const trades = (props.trades as any[]) || [];

  const [dataRequested, setDataRequested] = React.useState(false);
  const [apiChartData, setApiChartData] = React.useState<any>(null);
  const [replaySpeed, setReplaySpeed] = React.useState(1); // Default speed is normal (1x)
  const [isReplaying, setIsReplaying] = React.useState(false);
  const [currentTick, setCurrentTick] = React.useState(0); // Track current tick in replay
  const [intervalId, setIntervalId] = React.useState<NodeJS.Timeout | null>(
    null
  );

  const appState = useAtomValue(AppStateAtom);

  const defaultProps: Omit<ChartingLibraryWidgetOptions, "container"> = {
    symbol: "AAPL",
    interval: "D" as ResolutionString,
    datafeedUrl: "https://demo_feed.tradingview.com",
    libraryPath: "/charting_library/",
    chartsStorageUrl: APIEndpoint + "/tradingview",
    chartsStorageApiVersion: "v1",
    clientId: "wealthbee.io",
    userId: appState.user?.id || "anonymous",
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
  };

  // Find the most common underlying symbol from the trades
  function findMostCommonUnderlying(trades: any[]): any | null {
    if (trades.length === 0) return null;

    const underlyingCountMap: Record<number, any> = {};

    trades.forEach((trade) => {
      if (!trade.underlyingInstrument) {
        return;
      }

      const underlyingId = trade.underlyingInstrumentId;
      const underlying = trade.underlyingInstrument;

      if (!underlyingCountMap[underlyingId]) {
        underlyingCountMap[underlyingId] = {
          id: underlying.id,
          name: underlying.name,
          symbol: underlying.symbol,
          count: 0,
        };
      }

      underlyingCountMap[underlyingId].count += 1;
    });

    return Object.values(underlyingCountMap).reduce(
      (prev, current) => (current.count > prev.count ? current : prev),
      underlyingCountMap[Object.keys(underlyingCountMap)[0]]
    );
  }

  const createTradeAnnotations = (
    trades: any[],
    startDate: number,
    endDate: number
  ) => {
    return trades
      .filter((trade) => {
        const tradeTime = new Date(trade.tradeDate).getTime() / 1000;
        return tradeTime >= startDate && tradeTime <= endDate;
      })
      .map((trade) => ({
        id: trade.id,
        time: new Date(trade.tradeDate).getTime() / 1000, // Convert to UNIX timestamp
        color: trade.buySell === "Buy" ? "green" : "red", // Buy is green, sell is red
        text: `${convertSymbolToReadable(trade.instrument?.symbol)} | \n
      ${trade.buySell} ${trade.quantity} @ ${trade.price} | \n
      Gross Proceeds: ${trade.grossProceeds} ${trade.currency} | \n
      Net Proceeds: ${trade.netProceeds} ${trade.currency} | \n
      Commission: ${trade.commission} | \n
      Strategy: ${trade.strategy || "N/A"}\n
    `,
        label: trade.buySell === "Buy" ? "B" : "S", // B for buy, S for sell
        labelFontColor: "#FFFFFF",
        minSize: 20,
      }));
  };

  // Start the replay function
  const startReplay = () => {
    if (!apiChartData || apiChartData.length === 0) {
      console.error("No chart data available to replay");
      return;
    }

    setIsReplaying(true);
    const tickInterval = 1000 / replaySpeed; // Adjust speed based on replaySpeed
    const interval = setInterval(() => {
      setCurrentTick((prevTick) => {
        if (prevTick < apiChartData.length - 1) {
          //     // Advance to the next tick
          return prevTick + 1;
        } else {
          //     // Stop replay when the trade closes
          clearInterval(interval);
          setIsReplaying(false);
          return prevTick;
        }
      });
    }, tickInterval);
    setIntervalId(interval); // Save the interval ID to stop later
  };

  // Stop the replay function
  const stopReplay = () => {
    if (intervalId) {
      clearInterval(intervalId); // Stop the interval
      setIntervalId(null);
      setIsReplaying(false);
    }
  };

  // Change replay speed
  const changeReplaySpeed = (newSpeed: number) => {
    setReplaySpeed(newSpeed);
    if (isReplaying) {
      // Restart replay with the new speed
      stopReplay();
      startReplay();
    }
  };

  const { colorMode, toggleColorMode } = useColorMode();

  useEffect(() => {
    const configurationData = {
      supported_resolutions: ["1D"],
      supports_marks: true,
      supports_timescale_marks: true,
    };

    const mostCommonUnderlying = findMostCommonUnderlying(trades);

    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: mostCommonUnderlying?.symbol as string,

      datafeed: {
        onReady: (callback) => {
          setTimeout(() => callback(configurationData));
        },
        resolveSymbol: async (
          symbolName,
          onSymbolResolvedCallback,
          onResolveErrorCallback
        ) => {
          try {
            const getStockData = await searchForInstruments(
              symbolName,
              InstrumentType.Equity
            );
            const symbolInfo = {
              ticker: getStockData.results[0].symbol,
              name: getStockData.results[0].symbol,
              exchange: getStockData.results[0].exchange,
              minmov: 1,
              pricescale: 100,
              has_intraday: true,
              has_no_volume: true,
              supported_resolutions: configurationData.supported_resolutions,
            };
            onSymbolResolvedCallback(symbolInfo);
          } catch (error) {
            onResolveErrorCallback("Error resolving symbol");
          }
        },
        getBars: async (
          symbolInfo,
          resolution,
          periodParams,
          onHistoryCallback,
          onErrorCallback
        ) => {
          const chartData = await getChartData(
            mostCommonUnderlying?.symbol,
            resolution,
            periodParams,
            symbolInfo
          );
          setApiChartData(chartData.data);
          onHistoryCallback(chartData.data, { noData: false });
          setDataRequested(true);
        },
        getMarks: async (symbolInfo, startDate, endDate, onDataCallback) => {
          const annotations = createTradeAnnotations(
            trades,
            startDate,
            endDate
          );
          onDataCallback(annotations);
        },
        getTimescaleMarks: async (
          symbolInfo,
          startDate,
          endDate,
          onDataCallback
        ) => {
          onDataCallback(props.timescaleMarks);
        },
        unsubscribeBars: async (subscriberId) => {
          return true;
        },
        subscribeBars: async (
          symbolInfo,
          resolution,
          onRealtimeCallback,
          subscriberUID,
          onResetCacheNeededCallback
        ) => {
          return true;
        },
      },
      interval:
        defaultProps.interval as ChartingLibraryWidgetOptions["interval"],
      container: chartContainerRef.current,
      library_path: defaultProps.libraryPath as string,
      locale: "en",
      disabled_features: [
        "use_localstorage_for_settings",
        "save_chart_properties_to_local_storage",
      ],
      enabled_features: ["study_templates"],
      charts_storage_url: defaultProps.chartsStorageUrl,
      charts_storage_api_version: defaultProps.chartsStorageApiVersion,
      client_id:
        defaultProps.clientId +
        `?user=${defaultProps.userId}&symbol=${mostCommonUnderlying?.symbol}`,
      user_id: defaultProps.userId,
      fullscreen: defaultProps.fullscreen,
      theme: colorMode === "dark" ? "dark" : "light",
      autosize: defaultProps.autosize,
      studies_overrides: defaultProps.studiesOverrides,
      load_last_chart: true,
      auto_save: true,
      auto_save_delay: 5,
    };

    const tvWidget = new widget(widgetOptions);

    tvWidget.onChartReady(() => {
      tvWidget.headerReady().then(() => {
        // Additional logic after chart is ready (e.g., buttons)
      });
    });

    return () => {
      tvWidget.remove();
    };
  }, [props.trades, dataRequested]);

  // Update chart based on the current tick during replay
  useEffect(() => {
    if (apiChartData && currentTick > 0 && currentTick < apiChartData.length) {
      // Subset the chart data to the current tick
      const visibleData = apiChartData.slice(0, currentTick);

      // Update the chart with the current visible data
      const widget = new widget({
        symbol: defaultProps.symbol,
        datafeed: {
          onReady: (callback) => callback({}),
          getBars: (
            symbolInfo,
            resolution,
            periodParams,
            onHistoryCallback
          ) => {
            onHistoryCallback(visibleData, { noData: false });
          },
          resolveSymbol: (symbolName, onSymbolResolvedCallback) => {
            onSymbolResolvedCallback({
              name: symbolName,
              ticker: symbolName,
              session: "24x7",
              has_intraday: true,
              has_no_volume: false,
            });
          },
        },
        interval:
          defaultProps.interval as ChartingLibraryWidgetOptions["interval"],
        container: chartContainerRef.current,
        library_path: defaultProps.libraryPath,
        locale: "en",
        theme: "dark",
      });
    }
  }, [currentTick, apiChartData]);

  return (
    <Box h="100%" w="100%" display="flex" flexDirection="column" pb="50px">
      {/* <div className="replay-controls">
        <SecondaryButton onClick={startReplay} disabled={isReplaying}>
          Start Replay
        </SecondaryButton>
        <SecondaryButton onClick={stopReplay} disabled={!isReplaying}>
          Stop Replay
        </SecondaryButton>
        <div>
          <span>Speed: </span>
          <SecondaryButton onClick={() => changeReplaySpeed(1)}>
            1x
          </SecondaryButton>
          <SecondaryButton onClick={() => changeReplaySpeed(2)}>
            2x
          </SecondaryButton>
          <SecondaryButton onClick={() => changeReplaySpeed(4)}>
            4x
          </SecondaryButton>
        </div>
      </div> */}
      <Box h="100%" w="100%" display="flex" flexDirection="column">
        <div ref={chartContainerRef} className={"TVChartContainer"} />
      </Box>
    </Box>
  );
};
