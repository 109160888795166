import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Box,
  Flex,
  VStack,
  Spinner,
  Text,
  Button,
  Tooltip,
  useToast,
  SlideFade,
  Slide,
  useDisclosure,
  Badge,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import { useInsightSearchListController } from "./useInsightSearchListController";
import CategoryFilter from "./components/CategoryFilter";
import LoadingOrEmptyState from "./components/LoadingOrEmptyState";
import InsightsGrid from "./components/InsightsGrid";
import { InsightTemplate } from "../../../types";
import { BiTrash } from "react-icons/bi";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";

interface InsightSearchListProps {
  setPage: (page: string) => void;
  setInsightData: (insight: any) => void;
  onClose: () => void;
}

const InsightSearchList: React.FC<InsightSearchListProps> = ({
  setPage,
  setInsightData,
  onClose,
}) => {
  const {
    isInsightEnabled,
    insightsByCategory,
    categories,
    appState,
    loading,
    insights,
    searchQuery,
    setSearchQuery,
    applyChanges,
    dashboardLayoutState,
  } = useInsightSearchListController();

  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [tempEnabledInsights, setTempEnabledInsights] = useState<Set<number>>(() => 
    new Set(dashboardLayoutState.enabledInsights.map(Number))
  );

  const [displayDemoData, setDisplayDemoData] = useState(true);
  const [loadingDemoData, setLoadingDemoData] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const toast = useToast();
  const { isOpen: isSaveBarOpen, onOpen: openSaveBar, onClose: closeSaveBar } = useDisclosure();

  // Compare set to array helper - memoized
  const compareSetToArray = useCallback((set: Set<number>, array: number[]) => {
    if (set.size !== array.length) return false;
    return array.every(element => set.has(element));
  }, []);

  // Handle changes to dashboard layout
  useEffect(() => {
    const hasChanges = !compareSetToArray(
      tempEnabledInsights,
      dashboardLayoutState.enabledInsights
    );
    
    if (hasChanges) {
      openSaveBar();
    } else {
      closeSaveBar();
    }
  }, [tempEnabledInsights, dashboardLayoutState.enabledInsights, compareSetToArray, openSaveBar, closeSaveBar]);

  // Handle loading states
  useEffect(() => {
    if (!loadingState) return;

    const timer = setTimeout(() => {
      setLoadingState(false);
      toast({
        title: "Dashboard updated",
        description: "Your insights have been updated successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      onClose();
    }, 1000);

    return () => clearTimeout(timer);
  }, [loadingState, onClose, toast]);

  useEffect(() => {
    setLoadingDemoData(true);
    const timer = setTimeout(() => {
      setLoadingDemoData(false);
    }, 1000);
    
    return () => clearTimeout(timer);
  }, [displayDemoData]);

  // Filter insights based on selected category - memoized
  const filteredEntries = useMemo(() => 
    selectedCategory
      ? { [selectedCategory]: insightsByCategory[selectedCategory] || [] }
      : insightsByCategory,
    [selectedCategory, insightsByCategory]
  );

  // Handle toggling insights - memoized
  const handleToggleInsight = useCallback((insight: InsightTemplate) => {
    setTempEnabledInsights(prev => {
      const newSet = new Set(prev);
      if (newSet.has(insight.insightId)) {
        newSet.delete(insight.insightId);
      } else {
        newSet.add(insight.insightId);
      }
      return newSet;
    });
  }, []);

  // Save changes to dashboard - memoized
  const handleSaveChanges = useCallback(() => {
    setLoadingState(true);
    const insightsToAdd = insights.filter(
      insight =>
        tempEnabledInsights.has(insight.insightId) &&
        !isInsightEnabled(insight.insightId)
    );
    const insightsToRemove = insights.filter(
      insight =>
        !tempEnabledInsights.has(insight.insightId) &&
        isInsightEnabled(insight.insightId)
    );
    applyChanges(insightsToAdd, insightsToRemove);
  }, [insights, tempEnabledInsights, isInsightEnabled, applyChanges]);

  // Clear all insights - memoized
  const handleClearAll = useCallback(() => {
    setTempEnabledInsights(new Set());
    toast({
      title: "All insights cleared",
      description: "Click Save to apply changes",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  }, [toast]);

  const [view, setView] = useState("grid");
  
  // Count changes - memoized
  const changesCount = useMemo(() => 
    insights.reduce((count, insight) => {
      if (
        (tempEnabledInsights.has(insight.insightId) && !isInsightEnabled(insight.insightId)) ||
        (!tempEnabledInsights.has(insight.insightId) && isInsightEnabled(insight.insightId))
      ) {
        return count + 1;
      }
      return count;
    }, 0),
    [insights, tempEnabledInsights, isInsightEnabled]
  );

  // Handle discard changes - memoized
  const handleDiscardChanges = useCallback(() => {
    setTempEnabledInsights(
      new Set(dashboardLayoutState.enabledInsights.map(Number))
    );
  }, [dashboardLayoutState.enabledInsights]);

  return (
    <Box flex="1" fontSize="14px" position="relative" pb="100px">
      {/* Save Changes Bar */}
      <Slide direction="bottom" in={isSaveBarOpen} style={{ zIndex: 10 }}>
        <Flex
          position="fixed"
          bottom="20px"
          left="50%"
          transform="translateX(-50%)"
          bg="var(--bg)"
          border="1px solid var(--accent)"
          borderRadius="lg"
          boxShadow="lg"
          p={3}
          gap={3}
          align="center"
          justify="center"
          maxW="600px"
          w="90%"
        >
          <HStack spacing={2} flex="1">
            <Badge colorScheme="blue" fontSize="sm" px={2} py={1} borderRadius="full">
              {changesCount} {changesCount === 1 ? 'change' : 'changes'}
            </Badge>
            <Text fontSize="sm" color="gray.400">
              Save changes to update your dashboard
            </Text>
          </HStack>
          
          <Tooltip label="Clear all insights">
            <IconButton
              aria-label="Clear all insights"
              icon={<BiTrash />}
              colorScheme="red"
              variant="ghost"
              size="sm"
              onClick={handleClearAll}
              isDisabled={tempEnabledInsights.size === 0}
            />
          </Tooltip>
          
          <Tooltip label="Discard changes">
            <IconButton
              aria-label="Discard changes"
              icon={<CloseIcon />}
              variant="ghost"
              size="sm"
              onClick={handleDiscardChanges}
            />
          </Tooltip>
          
          <Button
            colorScheme="green"
            leftIcon={<CheckIcon />}
            isLoading={loadingState}
            onClick={handleSaveChanges}
            size="sm"
          >
            Save
          </Button>
        </Flex>
      </Slide>

      {/* Category and Search Filters */}
      <CategoryFilter
        categories={categories}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        setView={setView}
        view={view}
        setDisplayDemoData={setDisplayDemoData}
        displayDemoData={displayDemoData}
      />

      {/* Main Content */}
      <Box overflow="auto" w="100%">
        {loading ? (
          <LoadingOrEmptyState loading={loading} insights={insights} />
        ) : loadingDemoData ? (
          <Flex justify="center" align="center" w="100%" mt="20px" minH="200px">
            <Spinner size="lg" color="var(--accent)" thickness="3px" />
          </Flex>
        ) : (
          <InsightsGrid
            filteredEntries={filteredEntries}
            isInsightEnabled={(id) => tempEnabledInsights.has(id)}
            handleToggleInsight={handleToggleInsight}
            appState={appState}
            setInsightData={setInsightData}
            setPage={setPage}
            view={view}
            displayDemoData={displayDemoData}
          />
        )}
      </Box>
    </Box>
  );
};

export default React.memo(InsightSearchList);
