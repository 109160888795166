import { SyncSetting } from "../types";
import { APIEndpoint } from ".";

const token = localStorage.getItem("token");

export const getSyncSettings = async (
  accountId: number
): Promise<SyncSetting[]> => {
  const result = await fetch(`${APIEndpoint}/sync`, {
    method: "GET",
    headers: {
      Authorization: token || "",
      AccountId: accountId as any,
    },
  });

  if (result.ok) {
    const data = await result.json();
    if (data.success && data.data) {
      return data.data as SyncSetting[];
    }
  }

  return [];
};

export const addOrUpdateSyncSetting = async (
  settings: SyncSetting,
  accountId: number
) => {
  return fetch(`${APIEndpoint}/sync`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token || "",
      AccountId: accountId as any,
    },
    body: JSON.stringify(settings),
  })
    .then((response) => response.json())
    .catch((error) =>
      console.error("Error updating autosync settings:", error)
    );
};

export const testSyncSetting = async (
  settings: SyncSetting,
  accountId: number,
  stage?: string
): Promise<boolean> => {
  const result = await fetch(`${APIEndpoint}/sync/test?stage=${stage}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token || "",
      AccountId: accountId as any,
    },
    body: JSON.stringify(settings),
  });

  if (result.ok) {
    const data = await result.json();
    return data;
  }

  return false;
};

export const runSyncSetting = async (
  settings: SyncSetting,
  accountId: number
): Promise<{ success: boolean; message: string }> => {
  try {
    const response = await fetch(`${APIEndpoint}/sync/run`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token || "",
        AccountId: String(accountId),
      },
      body: JSON.stringify(settings),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Failed to run sync");
    }

    if (data.success && data.data) {
      return {
        success: true,
        message: data.data,
      };
    }

    return {
      success: false,
      message: "Sync completed but returned unexpected result",
    };
  } catch (error) {
    return {
      success: false,
      message:
        error instanceof Error ? error.message : "An unknown error occurred",
    };
  }
};

export const getSchwabAuthCode = async (accountId: number) => {
  const result = await fetch(`${APIEndpoint}/sync/schwab/authorize`, {
    method: "GET",
    headers: {
      Authorization: token || "",
      AccountId: accountId as any,
    },
  });
  if (result.ok) {
    const data = await result.json();

    if (data.success && data.url) {
      return data.url as string;
    }
  }

  return "";
};

export const swapCodeForToken = async (code: string, accountId: number) => {
  const result = await fetch(`${APIEndpoint}/sync/schwab/access_token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token || "",
      AccountId: accountId as any,
    },
    body: JSON.stringify({ code }),
  });

  if (result.ok) {
    const data = await result.json();
    if (data.success && data.data) {
      return data.data as string;
    }
  } else {
    console.log("Error swapping code for token:", result);
    return false;
  }

  return "";
};

export const deleteSyncSettings = async (id: number, accountId: number) => {
  const result = await fetch(`${APIEndpoint}/sync/settings/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: token || "",
      AccountId: accountId as any,
    },
  });

  if (result.ok) {
    const data = await result.json();
    return data.success;
  }

  return false;
};
