import React from "react";
import {
  Flex,
  HStack,
  ListItem,
  Spacer,
  Text,
  OrderedList,
  VStack,
} from "@chakra-ui/react";
import { PayoffChart } from "./PayoffChart";
import { Position } from "../../types";
import { usePayoffController } from "./usePayoffController";
import { Show } from "../../components";

export interface PayoffProps {
  positions: Position[];
}

export const Payoff = (props: PayoffProps) => {
  const controller = usePayoffController(props);

  return (
    <Flex className="payoff-chart" pt={2} h="full" w="full">
      <Show if={!controller.supported}>
        <Flex w="full" direction="column" pt={10}>
          <HStack w="full">
            <Spacer />
            <VStack border="1px solid gray" p={10} borderRadius={6}>
              <Text>
                Sorry we can't generate a Payoff Diagram for the position you
                have choosen, we currently support any or all of the following
                positions
              </Text>
              <OrderedList mt={2} spacing={4} w="90%">
                <ListItem>
                  Equity or ETF - Long or short but we don't support multiple.
                </ListItem>
                <ListItem>
                  Unlimited Options - Must belong to the same underlying (again
                  only a single underlying).
                </ListItem>
                <ListItem>
                  Cashflows e.g. Dividends or Fees - Will be applied to every
                  price point
                </ListItem>
              </OrderedList>
            </VStack>
            <Spacer />
          </HStack>
        </Flex>
      </Show>
      <Show if={controller.supported}>
        <PayoffChart
          data={controller.data}
          currentPrice={controller.underlyingPrice}
          options={controller.options}
          positionId={controller.positionId}
        />
      </Show>
    </Flex>
  );
};
