import React from "react";
import {
  Flex,
  InputGroup,
  Input,
  InputLeftAddon,
  Text,
  FormErrorMessage,
  FormControl,
  HStack,
  Button,
  VStack,
  Stack,
  Spacer,
  Divider,
  Image,
  Box,
} from "@chakra-ui/react";
import { Show } from "../../../../components";
import {
  SchwabAutoSyncControllerProps,
  useSchwabAutoSyncController,
} from "./useSCHWABAutoSyncController";
import { getSchwabAuthCode } from "../../../../api";
import { useAtomValue } from "jotai";
import { AccountAtom } from "../../../../store";
import { SchwabAutoSyncInstructions } from "../../../uploads/panels/BrokerUpload/instructions/SchwabAutoSyncInstructions";

const SCHWAB_AutoSync: React.FC<SchwabAutoSyncControllerProps> = (props) => {
  const controller = useSchwabAutoSyncController(props);
  const accountState = useAtomValue(AccountAtom);

  return (
    <Flex direction="column" w="full">
      <SchwabAutoSyncInstructions />

      <Box mt={1} mb="4" display="flex" px="4">
        <Button
          onClick={() => {
            controller.step1GetSchwabAuthCode(accountState.selectedAccount?.id);
          }}
          bg="#009ddb"
          h="60px"
          isLoading={controller.loading}
          isDisabled={controller.config.active === true}
        >
          <Image
            src="https://cdn.wealthbee.io/main/brokers/broker_icons/schwab.png"
            alt="Schwab"
            boxSize="50px"
          />
          <Text
            pl="10px"
            fontSize="14px"
            fontStyle={"italic"}
            fontWeight="bold"
          >
            {!controller.config.active
              ? "Connect to Schwab"
              : "Connected to Schwab"}
          </Text>
        </Button>
      </Box>

      <HStack mb={1} mt="0px" justifyContent={"flex-end"}>
        {/* <Button
          size="sm"
          colorScheme="red"
          disabled={!controller.validAPIKey || !controller.validAPISecret}
          onClick={(e) => {
            e.preventDefault();
            controller.testConfig();
          }}
          isLoading={controller.loading}
          ml={2}
        >
          Test Data Access
        </Button> */}

        <Button
          size="sm"
          onClick={() => {
            controller.setConfig({
              apiKey: "",
              secretKey: "",
              accessToken: "",
              accessTokenSecret: "",
              oauthToken: "",
              oauthTokenSecret: "",
              code: "",
            });
            controller.saveConfig({
              apiKey: "",
              secretKey: "",
              accessToken: "",
              accessTokenSecret: "",
              oauthToken: "",
              oauthTokenSecret: "",
              code: "",
            });
          }}
          ml={2}
        >
          Clear
        </Button>
        <Show if={props.close !== undefined}>
          <Button size="sm" onClick={props.close!} ml={2}>
            Close
          </Button>
        </Show>
      </HStack>
    </Flex>
  );
};
export default SCHWAB_AutoSync;
