import React from "react";
import {
  Box,
  Divider,
  Flex,
  HStack,
  IconButton,
  Spacer,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import mixpanel from "mixpanel-browser";
import {
  RiBarChartGroupedFill,
  RiCircleFill,
  RiSideBarFill,
} from "react-icons/ri";
import { KnowledgeBaseHelperTopics, ToolbarFeatures } from "../../types";
import { useFilters } from "../../hooks/useFilters";
import KnowledgeBaseHelper from "../../features/knowledgebase/KnowledgeBaseHelper";
import PricingQueuePoll from "../pricing/PricingQueuePoll";
import Uploads from "../../features/uploads/Uploads";
import { ToolbarDropDown } from "../design_library/toolbar/ToolbarDropDown";
import CustomSwitch from "../design_library/toolbar/Switch";
import { FilterSettings } from "../../features/filter-settings/FilterSettings";
import { DashboardSelector } from "../../features/dashboard-selector/DashboardSelector";
import { TimeFrameSelector } from "../timeframe-selector/TimeFrameSelector";
import InsightsManager from "../../features/insights/InsightsManager/InsightsManager";
import { AutoSync } from "../../features/AutoSync/AutoSync";
import PageHeading from "../design_library/PageHeading";
import { useAtom, useAtomValue } from "jotai";
import { SidebarStateAtom } from "../../store";
import { ChevronRight, ChevronLeft } from "../design_library";
import SidebarToggle from "./SidebarToggle";

export const Toolbar = ({
  enabledOptions,
  helpKey,
  positionsController,
  transactionsController,
  title,
  description,
  icon,
}: {
  enabledOptions?: ToolbarFeatures[];
  helpKey?: KnowledgeBaseHelperTopics;
  positionsController?: any;
  transactionsController?: any;
  title?: string;
  description?: string;
  icon?: any;
}) => {
  const filtersHook = useFilters();

  const statusOptions = [
    { value: "all", label: "All positions" },
    { value: "active", label: "Active" },
    { value: "open", label: "Open positions only" },
    { value: "closed", label: "Closed positions only" },
  ];

  const groupingOptions = [
    { value: "B/U/P", label: "Book -> Underlying -> Positions" },
    { value: "U/B/P", label: "Underlying -> Book -> Positions" },
    { value: "B/P/U", label: "Book -> Positions -> Underlying" },
    { value: "Underlying Only", label: "Underlying Only" },
    { value: "None", label: "No grouping" },
  ];

  return (
    <Flex className="grid-toolbar-hook" mx="2" mb={2}>
      <SidebarToggle />

      <HStack
        className="grid-toolbar-select"
        wrap={"wrap"}
        maxW="100vw"
        w="100%"
      >
        {enabledOptions?.includes(ToolbarFeatures.TITLE) &&
          enabledOptions?.includes(ToolbarFeatures.DESCRIPTION) && (
            <PageHeading title={title} description={description} icon={icon} />
          )}

        {enabledOptions?.includes(ToolbarFeatures.DASHBOARD_SELECTOR) && (
          <DashboardSelector />
        )}

        {enabledOptions?.includes(ToolbarFeatures.FILTER_SELECTOR) && (
          <FilterSettings />
        )}

        {enabledOptions?.includes(ToolbarFeatures.TIMEFRAMES) && (
          <TimeFrameSelector />
        )}

        {enabledOptions?.includes(ToolbarFeatures.GROUPING) &&
          positionsController && (
            <ToolbarDropDown
              className="tool-bar-select tutorial-grouping"
              placeholder={
                <HStack color="white !important">
                  <RiBarChartGroupedFill fill="var(--accent)" />
                  <Text
                    display={{
                      base: "none",
                      md: "block",
                    }}
                  >
                    Grouping
                  </Text>
                </HStack>
              }
              defaultValue={{
                label: groupingOptions.find(
                  (option) =>
                    option.value === localStorage.getItem("positionGrouping")
                )?.label || (
                  <HStack color="white !important">
                    <RiBarChartGroupedFill fill="var(--accent)" />
                    <Text
                      display={{
                        base: "none",
                        md: "block",
                      }}
                      color="var(--white)"
                    >
                      Grouping
                    </Text>
                  </HStack>
                ),
              }}
              value={
                positionsController.grouping !== "B/U/P"
                  ? {
                      label: positionsController.grouping,
                      value: positionsController.grouping,
                    }
                  : {
                      label: (
                        <HStack color="white !important">
                          <RiBarChartGroupedFill fill="var(--accent)" />
                          <Text
                            display={{
                              base: "none",
                              md: "block",
                            }}
                            color="var(--white)"
                          >
                            Grouping
                          </Text>
                        </HStack>
                      ),
                      value: "None",
                    }
              }
              options={groupingOptions}
              onChange={(event: any) => {
                positionsController.selectGrouping(event.value);
              }}
            />
          )}

        {enabledOptions?.includes(ToolbarFeatures.STATUS) && (
          <ToolbarDropDown
            className="tool-bar-select"
            placeholder={
              <HStack color="white !important">
                <RiCircleFill fill="var(--accent)" />
                <Text
                  display={{
                    base: "none",
                    lg: "block",
                  }}
                >
                  Status
                </Text>
              </HStack>
            }
            options={statusOptions}
            defaultValue={{
              value: localStorage.getItem("positionStatus") || "all",
              label: statusOptions.find(
                (option) =>
                  option.value === localStorage.getItem("positionStatus")
              )?.label || (
                <HStack color="white !important">
                  <RiCircleFill fill="var(--accent)" />
                  <Text
                    display={{
                      base: "none",
                      lg: "block",
                    }}
                  >
                    Status
                  </Text>
                </HStack>
              ),
            }}
            onChange={(event: any) => {
              mixpanel.track("Position View Changed", {
                positionView: event.value,
              });

              filtersHook.setPositionStatus(event.value);
            }}
          />
        )}

        {enabledOptions?.includes(
          ToolbarFeatures.TOGGLE_UNBOOKED_AND_NO_POS_GROUP
        ) && (
          <>
            <CustomSwitch
              id="isChecked"
              isChecked={transactionsController.showUnBookedTrades}
              onChange={(e: any) => {
                transactionsController.setShowUnBookedTrades(
                  !transactionsController.showUnBookedTrades
                );
              }}
              mr={2}
              className="custom-switch-with-elipsis"
            >
              Unbooked ({transactionsController.tradesWithNoBook})
            </CustomSwitch>

            <CustomSwitch
              id="isChecked"
              className="custom-switch-with-elipsis"
              isChecked={transactionsController.showNoPositionGrouping}
              onChange={(e: any) => {
                transactionsController.setShowNoPositionGrouping(
                  !transactionsController.showNoPositionGrouping
                );
              }}
              mr={2}
            >
              No position group (
              {transactionsController.tradesWithNoPositionGrouping})
            </CustomSwitch>
          </>
        )}

        {enabledOptions?.includes(ToolbarFeatures.PRICING_QUEUE) && (
          <PricingQueuePoll />
        )}

        <Spacer
          display={{
            base: "none",
            md: "block",
          }}
        />

        {enabledOptions?.includes(ToolbarFeatures.AUTOSYNC) && (
          <AutoSync minimalGui={true} />
        )}

        {enabledOptions?.includes(ToolbarFeatures.HELP) && (
          <KnowledgeBaseHelper topic={helpKey} />
        )}
        {enabledOptions?.includes(ToolbarFeatures.INSIGHTS) && (
          <InsightsManager />
        )}

        <Uploads />
      </HStack>
    </Flex>
  );
};
