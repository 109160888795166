import React, { useEffect } from "react";
import { getReferrals } from "../../api/Referrals";
import {
  Box,
  Heading,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Badge,
  Text,
} from "@chakra-ui/react";
import moment from "moment";
import currencyFormatter from "currency-formatter";

function getStatusColor(status: string) {
  switch (status?.toLowerCase()) {
    case "credit_applied":
      return "green";
    case "pending":
      return "yellow";
    default:
      return "gray";
  }
}

export default function ReferAFriendList() {
  const [emails, setEmails] = React.useState([]);

  const fetchReferrals = async () => {
    const result = await getReferrals(emails);

    if (result.success) {
      setEmails(result.data);
    }
  };

  useEffect(() => {
    fetchReferrals();
  }, []);

  return (
    <Box borderRadius="md" boxShadow="md">
      <Heading size="lg" mb={4}>
        Referrals
      </Heading>
      {emails.length > 0 ? (
        <Table variant="striped" size="md" borderWidth="1px" borderRadius="md">
          <Thead>
            <Tr>
              <Th>Email</Th>
              <Th>Status</Th>
              <Th>Created At</Th>
              <Th>Completed At</Th>
              <Th>Value</Th>
            </Tr>
          </Thead>
          <Tbody>
            {emails.map((email) => (
              <Tr key={email.referralId}>
                <Td>{email.referredEmail}</Td>
                <Td>
                  <Badge colorScheme={getStatusColor(email.status)}>
                    {email.status}
                  </Badge>
                </Td>
                <Td>{moment(email.createdAt).calendar()}</Td>
                <Td>
                  {email.completedAt && moment(email.completedAt).calendar()}
                </Td>
                <Td>
                  {currencyFormatter.format(email.value, {
                    code: email.ccy,
                    precision: 2,
                  })}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <Text mt={4}>No referrals found.</Text>
      )}
    </Box>
  );
}
