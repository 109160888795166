import React from "react";
import { Flex } from "@chakra-ui/react";
import { Allotment } from "allotment";
import { useTradesController } from "./useTradesController";
import { Trades } from "./Trades";
import { TradesToolbar } from "./TradesToolbar";
import { ExplainTrade } from "../explain-transactions/ExplainTrade";
import { QuickFilter } from "../quick-filter/QuickFilter";

export const TradesContainer = () => {
  const controller = useTradesController();

  return (
    <Flex h="full" w="full" direction="column">
      <TradesToolbar
        showUnBooked={controller.showUnBooked}
        setShowUnBooked={controller.setShowUnBooked}
        showNoPositionGrouping={controller.showNoPositionGrouping}
        setShowNoPositionGrouping={controller.setShowNoPositionGrouping}
        setSelectedDateRange={controller.setSelectedDateRange}
      />
      <QuickFilter />
      <Flex px={2} flex={1}>
        <Allotment separator={true}>
          <Flex h="full" w="full" pr={1}>
            <Trades
              data={controller.trades}
              loading={controller.loading}
              selectedTrades={controller.selectedTrades}
              onSelectedRowChanged={controller.setSelectedTrades}
              onRowDragEnd={controller.onRowDragEnd}
              onRowDragMove={controller.onRowDragMove}
            />
          </Flex>
          <Allotment.Pane
            visible={true}
            preferredSize={controller.selectedTrades.length > 0 ? 600 : 300}
            minSize={controller.selectedTrades.length > 0 ? 600 : 300}
          >
            <Flex
              w="full"
              h="full"
              p="2px"
              ml="1px"
              border="solid 1px var(--dark-gray)"
              borderRadius="md"
            >
              <ExplainTrade
                trades={controller.selectedTrades}
                onExplainTrade={() => {
                  controller.refresh();
                }}
              />
            </Flex>
          </Allotment.Pane>
        </Allotment>
      </Flex>
    </Flex>
  );
};
