import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { Allotment } from "allotment";
import { useCashflowsController } from "./useCashflowsController";
import { CashflowsToolbar } from "./CashflowsToolbar";
import { Cashflows } from "./Cashflows";
import { ExplainCashflow } from "../explain-transactions/ExplainCashflow";
import { QuickFilter } from "../quick-filter/QuickFilter";

export const CashflowsContainer = () => {
  const controller = useCashflowsController();

  return (
    <Flex h="full" w="full" direction="column">
      <CashflowsToolbar
        showUnCategorised={controller.showUnCategorised}
        setShowUnCategorised={controller.setShowUnCategorised}
        showUnBooked={controller.showUnBooked}
        setShowUnBooked={controller.setShowUnBooked}
        showNoPositionGrouping={controller.showNoPositionGrouping}
        setShowNoPositionGrouping={controller.setShowNoPositionGrouping}
        setSelectedDateRange={controller.setSelectedDateRange}
      />
      <QuickFilter />

      <Flex px={2} flex={1}>
        <Allotment separator={true}>
          <Flex h="full" w="full" pr={1}>
            <Cashflows
              data={controller.rows}
              loading={controller.loading}
              selectedCashflows={controller.selectedRows}
              onSelectedRowChanged={controller.setSelectedRows}
            />
          </Flex>
          <Allotment.Pane
            visible={true}
            preferredSize={controller.selectedRows.length > 0 ? 600 : 300}
            minSize={controller.selectedRows.length > 0 ? 600 : 300}
          >
            <Flex
              w="full"
              h="full"
              p="2px"
              ml="1px"
              border="solid 1px var(--dark-gray)"
              borderRadius="md"
            >
              <ExplainCashflow
                cashflows={controller.selectedRows}
                onExplainCashflow={() => {
                  controller.refresh();
                }}
              />
            </Flex>
          </Allotment.Pane>
        </Allotment>
      </Flex>
    </Flex>
  );
};
