import { useCallback, useEffect, useMemo, useState } from "react";
import { useAtomValue } from "jotai";
import { GetContextMenuItemsParams, GetRowIdParams } from "ag-grid-community";
import { KeyValuePair, TradeEx } from "../../types";
import { AccountAtom, TransactionsAtom } from "../../store";
import { getTrades, updateManyTrades } from "../../api/Trades";
import { determineDateRange } from "../../components";
import mixpanel from "mixpanel-browser";
import { useFilterHelper, useInstruments } from "../../hooks";

interface UseTradesController {
  trades: TradeEx[];
  selectedTrades: TradeEx[];
  setSelectedTrades: (trades: TradeEx[]) => void;
  loading: boolean;
  showUnBooked: boolean;
  setShowUnBooked: (value: boolean) => void;
  showNoPositionGrouping: boolean;
  setShowNoPositionGrouping: (value: boolean) => void;
  setSelectedDateRange: (item: KeyValuePair) => void;
  refresh: () => void;
  onRowDragMove: (params: GetRowIdParams) => void;
  onRowDragEnd: (params: GetRowIdParams) => void;
}

export const useTradesController = (): UseTradesController => {
  const [loading, setLoading] = useState(false);
  const [trades, setTrades] = useState<TradeEx[]>([]);
  const [selectedTrades, setSelectedTrades] = useState<TradeEx[]>([]);
  const [showUnBooked, setShowUnBooked] = useState(false);
  const [showNoPositionGrouping, setShowNoPositionGrouping] = useState(false);
  const instruments = useInstruments();
  const accountState = useAtomValue(AccountAtom);
  const filterHelper = useFilterHelper();

  const [selectedDateRange, setSelectedDateRange] = useState<KeyValuePair>();

  const filteredTrades = useMemo(() => {
    let result = filterHelper.filteredUnderlying
      ? trades.filter((trade) => {
          const underlyingInstrument = instruments.list.find((x) => {
            return (
              x.id === trade.underlyingInstrumentId ||
              x.id === trade.instrumentId
            );
          });

          if (
            underlyingInstrument?.name === filterHelper.filteredUnderlying ||
            underlyingInstrument?.symbol === filterHelper.filteredUnderlying ||
            underlyingInstrument?.symbol.split("_")[0] ===
              filterHelper.filteredUnderlying
          ) {
            return true;
          } else {
            return false;
          }
        })
      : trades;

    if (filterHelper.filteredTags && filterHelper.filteredTags !== "All") {
      result = result.filter((trade) => {
        return trade?.tags?.includes(filterHelper.filteredTags!);
      });
    }

    if (showUnBooked) {
      result = result.filter((trade) => !trade.book);
    }
    if (showNoPositionGrouping) {
      result = result.filter(
        (trade) =>
          !trade.positionGroup ||
          trade.positionGroup.toLowerCase() === "ungrouped"
      );
    }

    if (
      filterHelper.filteredPositionGroup &&
      filterHelper.filteredPositionGroup !== "All"
    ) {
      result = result.filter(
        (trade) =>
          trade.positionGroup?.toLowerCase() ===
          filterHelper.filteredPositionGroup!.toLowerCase()
      );
    }

    if (
      filterHelper.filteredStrategy &&
      filterHelper.filteredStrategy !== "All"
    ) {
      result = result.filter(
        (trade) => trade.strategy === filterHelper.filteredStrategy
      );
    }

    if (filterHelper.filteredBook && filterHelper.filteredBook !== "All") {
      result = result.filter(
        (trade) =>
          trade.book?.toLowerCase() === filterHelper.filteredBook!.toLowerCase()
      );
    }

    return result.map((trade) => {
      return {
        ...trade,
        instrument: trade.instrumentId
          ? instruments.list.find((x) => x.id === trade.instrumentId)
          : undefined,
        underlyingInstrument: trade.underlyingInstrumentId
          ? instruments.list.find((x) => x.id === trade.underlyingInstrumentId)
          : instruments.list.find((x) => x.id === trade.instrumentId),
      } as TradeEx;
    });
  }, [filterHelper, trades, showNoPositionGrouping, showUnBooked]);

  const refresh = () => {
    setSelectedTrades([]);
    loadTrades(true);
  };

  //NEED TO REMOVE ONCE WE"RE DONT WITH THIS/ HOTFIX
  const transactions = useAtomValue(TransactionsAtom);
  useEffect(() => {
    refresh();
  }, [transactions.trades]);

  const loadTrades = useCallback(
    (force: boolean = false) => {
      if (
        selectedDateRange?.key &&
        accountState.selectedAccount?.id &&
        (force || !loading)
      ) {
        console.log("[DEBUG] 1. TRADES", accountState.selectedAccount?.id);
        setLoading(true);
        setTimeout(async () => {
          try {
            const dates = determineDateRange(selectedDateRange?.key);
            const result = await getTrades(
              accountState.selectedAccount!.id,
              dates.start,
              dates.end
            );

            // ensure that we have the instruments for the trades
            if ((result.data?.length ?? 0) > 0) {
              await instruments.loadIds(
                result.data.map((x: TradeEx) => x.instrumentId)
              );
            }

            setTrades(result?.data);
          } finally {
            setLoading(false);
          }
        }, 1);
      }
    },
    [accountState.selectedAccount, selectedDateRange, loading]
  );

  const getAllChildTrades = (
    params: GetContextMenuItemsParams,
    rows: any[]
  ) => {
    const children = [];

    const selectedRows = params.api.getSelectedRows();
    if (selectedRows.length > 0) {
      selectedRows.forEach((row) => {
        if (row.tradeIds) {
          row.tradeIds.forEach((tradeId) => {
            const trade = rows.find((transaction) => {
              return transaction.id === tradeId;
            });
            if (trade) {
              children.push(trade);
            }
          });
        } else {
          children.push(row);
        }
      });
    }

    if (params.node.data !== undefined) {
      if (params?.node?.data?.tradeIds) {
        params.node.data.tradeIds.forEach((tradeId) => {
          const trade = rows.find((transaction) => {
            return transaction.id === tradeId;
          });
          if (trade) {
            children.push(trade);
          }
        });
      } else {
        children.push(params.node.data);
      }
    } else if (!params.node?.allLeafChildren) {
      params.api.getSelectedNodes().map((node) => {
        if (node.data !== undefined) {
          children.push(node.data);
        }
      });

      if (params?.api?.getSelectedNodes().length === 0)
        children.push(params?.node?.data);
    } else if (params?.node?.allLeafChildren) {
      params?.node?.allLeafChildren?.forEach((child) => {
        if (!child.data.tradeIds) {
          children.push(child.data);
        } else {
          child.data.tradeIds.forEach((tradeId) => {
            const trade = rows.find((transaction) => {
              return transaction.id === tradeId;
            });

            if (trade) {
              children.push(trade);
            }
          });
        }
      });
    }

    return children;
  };

  const getAllChildrenOfOverNode = (
    params: GetContextMenuItemsParams,
    rows: any[]
  ) => {
    const children = [];

    if (!params.overNode?.allLeafChildren) {
      params.api.getSelectedNodes().map((node) => {
        children.push(node.data);
      });

      if (params?.api?.getSelectedNodes().length === 0)
        children.push(params?.overNode?.data);
    } else if (params?.overNode?.allLeafChildren) {
      params?.overNode?.allLeafChildren?.forEach((child) => {
        if (child.data.tradeIds) {
          child.data.tradeIds.forEach((tradeId) => {
            const trade = rows.find((transaction) => {
              return transaction.id === tradeId;
            });

            if (trade) {
              children.push(trade);
            }
          });
        } else {
          children.push(child.data);
        }
      });
    }

    return children;
  };

  const onRowDragMove = (params: GetRowIdParams) => {
    // console.log("onRowDragEnd", params);
  };

  const onRowDragEnd = (params: GetRowIdParams) => {
    mixpanel.track("Transaction Dragged");
    const nodesData = getAllChildTrades(params, filteredTrades);
    const overNodesData = getAllChildrenOfOverNode(params, filteredTrades);

    if (nodesData.length > 1) {
      if (
        window.confirm(`Are you sure you want to move multiple trades? `) ===
        false
      ) {
        return;
      }
    }

    if (params.overNode.group === false) {
      explainTrades(
        [...nodesData],
        params.overNode.data.book,
        params.overNode.data.positionGroup
      );
    } else if (
      params.overNode.field === "positionGroup" ||
      params.overNode.field === "book"
    ) {
      explainTrades(
        [...nodesData],
        overNodesData[0].book,
        overNodesData[0].positionName ||
          overNodesData[0].positionGroup ||
          params.overNode.aggData.positionName ||
          params.overNode.aggData.positionGroup
      );
    }
    return;
  };

  const explainTrades = async (
    trades: TradeEx[],
    book?: string,
    positionGroup?: string
  ) => {
    if (accountState.selectedAccount?.id) {
      const updatedTrades = trades.map((trade) => {
        if (book) {
          trade.book = book;
        } else {
          trade.book = "";
        }
        if (positionGroup) {
          trade.positionGroup = positionGroup;
        } else {
          trade.positionGroup = "";
        }
        return trade;
      });
      await updateManyTrades(updatedTrades, accountState.selectedAccount?.id);
      setTimeout(() => {
        refresh();
      }, 10);
    }
  };

  useEffect(() => {
    loadTrades();
  }, [accountState.selectedAccount, selectedDateRange]);

  return {
    trades: filteredTrades,
    loading,
    showUnBooked,
    setShowUnBooked,
    showNoPositionGrouping,
    setShowNoPositionGrouping,
    setSelectedDateRange,
    selectedTrades,
    setSelectedTrades,
    refresh,
    onRowDragMove,
    onRowDragEnd,
  };
};
