import { Center, VStack, Heading, Text, Box, Icon, Flex } from "@chakra-ui/react";
import React from "react";
import { FaRegLightbulb } from "react-icons/fa";
import Uploads from "../../features/uploads/Uploads";

export default function RenderNoPositions() {
  return (
    <Center h="70%" p={8}>
      <VStack spacing={6} p={8}>
        <Box  mb={2}>
          <Icon as={FaRegLightbulb} w={12} h={12} />
        </Box>
        <Heading size="lg" >
          No Positions Found
        </Heading>
        <Text
          maxW="400px"
          textAlign="center"
          fontSize="lg"
          mb={4}
        >
          Get started by adding your first trades to see valuable insights about your portfolio.
        </Text>
        <Flex w="full" justifyContent={"center"} alignItems={"center"}>
          <Uploads />
        </Flex>
      </VStack>
    </Center>
  );
}
