export const TOTAL_CASHFLOWS = {
  prefix: "$",
  value: 12536.54,
  suffix: "",
  change: 0,
  changeSuffix: "",
  decimals: 0,
};

export const TOTAL_DIVIDENDS = {
  prefix: "$",
  value: 11500.0,
  suffix: "",
  change: 0,
  changeSuffix: "",
  decimals: 0,
};

export const TOTAL_TAX = {
  prefix: "$",
  value: -152.44,
  suffix: "",
  change: 0,
  changeSuffix: "",
  decimals: 0,
};

export const TOTAL_INTEREST = {
  prefix: "$",
  value: 6.54,
  suffix: "",
  change: 0,
  changeSuffix: "",
  decimals: 0,
};

export const TOTAL_FEE = {
  prefix: "$",
  value: -12.14,
  suffix: "",
  change: 0,
  changeSuffix: "",
  decimals: 0,
};

export const TOTAL_DEPOSIT = {
  prefix: "$",
  value: 15000.0,
  suffix: "",
  change: 0,
  changeSuffix: "",
  decimals: 0,
};

export const TOTAL_WITHDRAWAL = {
  prefix: "$",
  value: -2500.0,
  suffix: "",
  change: 0,
  changeSuffix: "",
  decimals: 0,
};
