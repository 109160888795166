import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  VStack,
  Text,
  Flex,
  useColorModeValue,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  Grid,
  Axis,
  BarStack,
  BarSeries,
  LineSeries,
  AreaSeries,
  XYChart,
  Tooltip,
} from "@visx/xychart";
import { curveCardinal, curveLinear } from "@visx/curve";
import moment from "moment";
import { DataItem } from "../../../types";
import { BaseInsightProps } from "../BaseInsightProps";
import { useInsightChartController } from "./useInsightChartController";
import { NewTooltip } from "../../dashboard/canvas/insight/InsightRenderers/Shared/Tooltip";
import ExampleTag from "../shared/ExampleTag";
import { Show } from "../../../components";
import { InsightStateEnum } from "../InsightStateEnum";
import InsightErrorRouter from "../components/InsightErrorRouter";
import currencyFormatter from "currency-formatter";
import { BiChart, BiFullscreen } from "react-icons/bi";

export const InsightChart = (props: BaseInsightProps) => {
  const { insight, demo } = props;

  // Responsive sizing with minimum dimensions
  const [containerWidth, setContainerWidth] = useState<number>(800);
  const [containerHeight, setContainerHeight] = useState<number>(400);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const controller = useInsightChartController(props);

  // Enhanced margins for better readability
  const margin = { top: 20, left: 80, right: 30, bottom: 60 };
  const background = useColorModeValue("white", "transparent");
  const gridColor = useColorModeValue("#E2E8F0", "#717186");
  const numTicks = useBreakpointValue({ base: 4, md: 6, lg: 8 });

  // Memoized accessors for better performance
  const accessors = useMemo(() => {
    return {
      xAccessor: (d: DataItem, key: string) => {
        if (controller.chartProperties?.flipAxis === true) {
          return d[key] as number;
        }
        return d.domain;
      },
      yAccessor: (d: DataItem, key: string) => {
        if (controller.chartProperties?.flipAxis === true) {
          return d.domain;
        }
        return d[key] as number;
      },
    };
  }, [controller.chartProperties, controller.data]);

  // Responsive resize handler
  useEffect(() => {
    const handleResize = (entries: ResizeObserverEntry[]) => {
      if (!entries.length) return;
      const { width, height } = entries[0].contentRect;
      setContainerWidth(Math.max(width, 300)); // Minimum width
      setContainerHeight(Math.max(height, 200)); // Minimum height
    };

    const observer = new ResizeObserver((entries) => handleResize(entries));
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      containerRef.current?.requestFullscreen();
      setIsFullscreen(true);
    } else {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  return (
    <VStack
      h="full"
      maxH="95%"
      spacing={4}
      align="start"
      ref={containerRef}
      className="insight-chart"
      position="relative"
    >
      {/* Mobile view with improved messaging */}
      <Flex
        display={{
          base: "flex",
          sm: "flex",
          md: "none",
          lg: "none",
          xl: "none",
        }}
        w="full"
        h="full"
        alignItems="center"
        justifyContent="center"
        p={6}
        textAlign="center"
        borderRadius="lg"
      >
        <VStack spacing={4}>
          <BiChart size={64} color="var(--accent)" />
          <Text fontSize="md" color="gray.500">
            This chart is optimized for larger screens. Please view on a tablet
            or desktop for the best experience.
          </Text>
        </VStack>
      </Flex>

      <Box
        p={6}
        overflow="hidden"
        w="full"
        h="full"
        position="relative"
        borderRadius="xl"
        boxShadow="sm"
        display={{
          base: "none",
          sm: "none",
          md: "block",
          lg: "block",
          xl: "block",
        }}
      >
        {/* Fullscreen toggle button */}
        <Box position="absolute" top={4} right={4} zIndex={1}>
          <BiFullscreen
            size={24}
            cursor="pointer"
            onClick={toggleFullscreen}
            color="var(--accent)"
          />
        </Box>

        {props.demo && <ExampleTag />}

        <InsightErrorRouter
          insightState={controller.insightState}
          supported={controller.supported}
        />

        <Show
          if={
            controller.supported === true &&
            controller.insightState === InsightStateEnum.Success
          }
        >
          <XYChart
            xScale={
              controller.chartProperties?.flipAxis === true
                ? { type: "linear" }
                : { type: "band", padding: 0.2 }
            }
            yScale={
              controller.chartProperties?.flipAxis === true
                ? { type: "band", padding: 0.2 }
                : { type: "linear" }
            }
            width={containerWidth - 40}
            height={containerHeight}
            margin={margin}
          >
            <rect
              width={containerWidth}
              height={containerHeight}
              fill={background}
              rx={14}
            />

            <Grid
              rows={true}
              columns={false}
              numTicks={numTicks}
              lineStyle={{
                stroke: gridColor,
                strokeWidth: 1,
                opacity: 0.2,
                strokeDasharray: "4,4",
              }}
            />

            {/* Enhanced Axes with better formatting */}
            <Axis
              orientation={
                controller.chartProperties?.flipAxis === true
                  ? "left"
                  : "bottom"
              }
              tickFormat={(value) => {
                if (
                  controller.chartProperties?.flipAxis !== true &&
                  controller.chartProperties?.domainType === "date"
                ) {
                  return moment(value, "YYYY-MM-DD").format("MMM D, YY");
                }
                return value;
              }}
              tickValues={
                controller.chartProperties?.flipAxis === true &&
                controller.chartProperties?.showEveryDomainLabel
                  ? controller.domainValues
                  : undefined
              }
              stroke={gridColor}
              tickStroke={gridColor}
              tickLabelProps={() => ({
                fill: gridColor,
                fontSize: 12,
                fontWeight: "medium",
              })}
              label={controller.chartProperties?.xAxisLabel || ""}
            />

            <Axis
              orientation={
                controller.chartProperties?.flipAxis === true
                  ? "bottom"
                  : "left"
              }
              tickFormat={(value) => {
                if (
                  controller.chartProperties?.flipAxis === true &&
                  controller.chartProperties?.domainType === "date"
                ) {
                  return moment(value, "YYYY-MM-DD").format("MMM D, YY");
                }

                return currencyFormatter.format(value, {
                  code: "USD",
                  precision: 0,
                });
              }}
              tickValues={
                controller.chartProperties?.flipAxis !== true &&
                controller.chartProperties?.showEveryDomainLabel
                  ? controller.domainValues
                  : undefined
              }
              stroke={gridColor}
              numTicks={
                controller.chartProperties?.flipAxis !== true
                  ? numTicks
                  : undefined
              }
              tickStroke={gridColor}
              tickLabelProps={() => ({
                fill: gridColor,
                fontSize: 12,
                fontWeight: "medium",
              })}
              label={controller.chartProperties?.yAxisLabel || ""}
            />

            {/* Chart Series Rendering */}
            {controller.chartProperties?.keys?.map((key, i) => {
              const name = controller.chartProperties?.name?.replace(
                /\s+/g,
                "-"
              );

              switch (controller.chartProperties?.chartType) {
                case "line":
                  return (
                    <LineSeries
                      key={`1-${key}-${
                        controller.chartProperties?.flipAxis === true
                          ? "horizontal"
                          : "vertical"
                      }-${name}`}
                      dataKey={key}
                      data={controller.data as any[]}
                      xAccessor={(d) => accessors.xAccessor(d, key)}
                      yAccessor={(d) => accessors.yAccessor(d, key)}
                      curve={curveCardinal}
                      strokeWidth={2}
                    />
                  );
                case "area": {
                  const positiveData = (controller.data ?? []).map((d) => ({
                    ...d,
                    [key]: Math.max(d[key] as number, 0),
                  }));

                  const negativeData = (controller.data ?? []).map((d) => ({
                    ...d,
                    [key]: Math.min(d[key] as number, 0),
                  }));

                  return (
                    <>
                      <AreaSeries
                        key={`area-positive-${key}`}
                        dataKey={key + "-alt"}
                        data={positiveData}
                        xAccessor={(d) => accessors.xAccessor(d, key)}
                        yAccessor={(d) => accessors.yAccessor(d, key)}
                        curve={curveLinear}
                        fill="var(--green)"
                        fillOpacity={0.6}
                        lineProps={{
                          stroke: "var(--green)",
                          strokeWidth: 2,
                        }}
                        enableEvents={true}
                      />

                      <AreaSeries
                        key={`area-negative-${key}`}
                        dataKey={key}
                        data={negativeData}
                        xAccessor={(d) => accessors.xAccessor(d, key)}
                        yAccessor={(d) => accessors.yAccessor(d, key)}
                        curve={curveLinear}
                        fill="var(--red)"
                        fillOpacity={0.6}
                        lineProps={{
                          stroke: "var(--red)",
                          strokeWidth: 2,
                        }}
                        enableEvents={true}
                      />

                      <LineSeries
                        dataKey="zero"
                        data={[
                          { domain: controller.domainValues[0], value: 0 },
                          {
                            domain:
                              controller.domainValues[
                                controller.domainValues.length - 1
                              ],
                            value: 0,
                          },
                        ]}
                        xAccessor={(d) => d.domain}
                        yAccessor={() => 0}
                        stroke="var(--accent)"
                        strokeWidth={1}
                        strokeDasharray="4,4"
                      />
                    </>
                  );
                }
                case "bar":
                  return (
                    <>
                      <BarStack>
                        {controller.chartProperties?.keys?.map((key, i) => {
                          const keyIdx =
                            controller.chartProperties?.keys?.indexOf(key);
                          const fillColour =
                            controller.chartProperties?.colors[keyIdx ?? 0] ??
                            "black";
                          return (
                            <BarSeries
                              key={`3-${key}-${
                                controller.chartProperties?.flipAxis === true
                                  ? "horizontal"
                                  : "vertical"
                              }-${name}`}
                              dataKey={key}
                              data={controller.data as any[]}
                              xAccessor={(d) => accessors.xAccessor(d, key)}
                              yAccessor={(d) => accessors.yAccessor(d, key)}
                              barPadding={0.2}
                              colorAccessor={(d) => {
                                if (
                                  typeof accessors.xAccessor(d, key) !==
                                  "number"
                                ) {
                                  const value = accessors.yAccessor(
                                    d,
                                    key
                                  ) as number;
                                  return value >= 0
                                    ? "var(--green)"
                                    : "var(--red)";
                                } else {
                                  const value = accessors.xAccessor(
                                    d,
                                    key
                                  ) as number;
                                  if (key === "unrealisedPnl") {
                                    return value >= 0
                                      ? "var(--unrealised-gain)"
                                      : "var(--unrealised-loss)";
                                  } else if (
                                    key === "realisedPnl" ||
                                    key === "amount"
                                  ) {
                                    return value >= 0
                                      ? "var(--green)"
                                      : "var(--red)";
                                  }
                                  return fillColour;
                                }
                              }}
                            />
                          );
                        })}
                      </BarStack>
                      <LineSeries
                        dataKey="zero"
                        data={[
                          { domain: controller.domainValues[0], value: 0 },
                          {
                            domain:
                              controller.domainValues[
                                controller.domainValues.length - 1
                              ],
                            value: 0,
                          },
                        ]}
                        xAccessor={(d) => d.domain}
                        yAccessor={() => 0}
                        stroke="var(--accent)"
                        strokeWidth={1}
                        strokeDasharray="4,4"
                      />
                    </>
                  );
              }
              return null;
            })}

            <Tooltip
              showHorizontalCrosshair
              showVerticalCrosshair
              showDatumGlyph
              snapTooltipToDatumX
              snapTooltipToDatumY
              renderTooltip={({ tooltipData, colorScale }) => {
                if (!tooltipData?.nearestDatum) return null;

                const x = accessors.xAccessor(
                  tooltipData.nearestDatum.datum,
                  tooltipData.nearestDatum.key as string
                );
                let y = accessors.yAccessor(
                  tooltipData.nearestDatum.datum,
                  tooltipData.nearestDatum.key as string
                );

                if (y === undefined && tooltipData.nearestDatum.key) {
                  y = accessors.yAccessor(
                    tooltipData.nearestDatum.datum,
                    tooltipData.nearestDatum.key.replace("-alt", "")
                  );
                }

                const keyIdx = controller.chartProperties?.keys?.indexOf(
                  tooltipData.nearestDatum.key as string
                );

                if (keyIdx === undefined) return null;

                const value =
                  controller.chartProperties?.flipAxis === true ? x : y;
                const label =
                  controller.chartProperties?.flipAxis === true ? y : x;

                return (
                  <NewTooltip
                    domain={controller.chartProperties?.legend[keyIdx] ?? ""}
                    value={value as number}
                    label={
                      controller.chartProperties?.domainType === "date"
                        ? moment(label as string, "YYYY-MM-DD").format(
                            "MMM D, YYYY"
                          )
                        : (label as string)
                    }
                  />
                );
              }}
            />
          </XYChart>
        </Show>
      </Box>
    </VStack>
  );
};
