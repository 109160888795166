import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { VStack, Text, Center, useDisclosure, Box } from "@chakra-ui/react";
import {
  columnTypes,
  distinctValue,
  GridContainer,
  isMobile,
  Show,
  Spinner,
} from "../../components";
import { tradesColumnDefinitions } from "./tradesColumnDefinitions";
import { TradeEx } from "../../types";
import { TradesInnerRenderer } from "./TradesInnerRenderer";
import {
  overlayLoadingTemplate,
  overlayNoRowsTemplate,
} from "../../components/grid/NoRowsToShow";
import {
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
  SelectionChangedEvent,
} from "ag-grid-community";
import { useGridState } from "../../components/grid/useGridState";
import { useGridContextMenuController } from "../../components/grid/utils/useGridContextMenuController";
import EditTradeModal from "../../components/grid/utils/contextMenu/EditTradeModal";

interface TradesProps {
  data: TradeEx[];
  loading: boolean;
  selectedTrades: TradeEx[];
  onSelectedRowChanged: (rows: TradeEx[]) => void;
  onRowDragMove: (params: GetRowIdParams) => void;
  onRowDragEnd: (params: GetRowIdParams) => void;
}

export const Trades = (props: TradesProps) => {
  const { data, selectedTrades, onRowDragMove, onRowDragEnd } = props;

  const gridRef = useRef<GridApi | null>(null);

  const gridState = useGridState({ gridName: "Trades" });

  const onGridReady = useCallback((e: GridReadyEvent) => {
    gridRef.current = e.api;
    gridState.onGridReady(e);
  }, []);

  const onSelectionChanged = (e: SelectionChangedEvent) => {
    const selectedRows = e.api.getSelectedRows();

    props.onSelectedRowChanged(selectedRows);
  };

  const loadingOverlayComponent = useMemo(() => {
    return overlayLoadingTemplate;
  }, []);

  const loadingOverlayComponentParams = useMemo(() => {
    return {
      loadingMessage: "Retrieving transactions...",
    };
  }, []);

  const noRowsOverlayComponent = useMemo(() => {
    return overlayNoRowsTemplate;
  }, []);

  const noRowsOverlayComponentParams = useMemo(() => {
    return {
      noRowsMessageFunc: () =>
        "No trades found, please add them using the 'Add assets' button ",
    };
  }, []);

  const {
    isOpen: isEditModalOpen,
    onOpen: onOpenEditModal,
    onClose: onCloseEditModal,
  } = useDisclosure();

  const { getContextMenuItems, selectedParams } = useGridContextMenuController(
    () => {},
    () => {},
    () => {},
    () => {},
    onOpenEditModal,
    onCloseEditModal
  );

  useEffect(() => {
    if (selectedTrades.length === 0 && gridRef.current) {
      const nodes = gridRef.current.getSelectedNodes();
      if (nodes) {
        for (const node of nodes) {
          node.setSelected(false);
        }
      }
    }
  }, [selectedTrades]);

  return (
    <>
      <Show if={props.loading}>
        <VStack
          pos="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          w="220px"
          borderRadius={"md"}
          justifyContent={"center"}
          alignItems={"center"}
          bg="var(--bg)"
          border={"1px solid var(--accent)"}
          flexDir={"row"}
          p="4"
          zIndex={100}
        >
          <Spinner size="lg" />
          <Text>Loading Trades...</Text>
        </VStack>
      </Show>
      {/* <Show if={!props.loading}> */}
      <EditTradeModal
        isOpen={isEditModalOpen}
        onClose={onCloseEditModal}
        onConfirm={onCloseEditModal}
        selectedParams={selectedParams}
      />

      <GridContainer>
        <AgGridReact
          //   context={{ instruments: controller.instruments }}
          columnDefs={tradesColumnDefinitions} // Column Defs for Columns
          groupSelectsChildren={true}
          groupSelectsFiltered={true}
          rowData={data} // Row Data for Rows
          columnTypes={columnTypes} // Optional - custom column types
          rowGroupPanelShow="always"
          rowSelection="multiple" // Options - allows click selection of rows
          rowHeight={31}
          suppressAggFilteredOnly={false}
          suppressAggFuncInHeader={true}
          suppressChangeDetection={false}
          rowDragManaged={false}
          rowDragEntireRow={isMobile}
          animateRows={true}
          rowDragMultiRow={true}
          getContextMenuItems={getContextMenuItems}
          aggFuncs={{
            distinct: distinctValue,
          }}
          autoGroupColumnDef={{
            cellRendererParams: {
              suppressCount: true,
              innerRenderer: TradesInnerRenderer,
            },
            checkboxSelection: true,
            sortable: false,
            resizable: true,
            initialWidth: 600,
            headerName: "Book",
          }}
          suppressRowClickSelection={true}
          sideBar={{
            toolPanels: [
              {
                id: "columns",
                labelDefault: "Columns",
                labelKey: "columns",
                iconKey: "columns",
                toolPanel: "agColumnsToolPanel",
                toolPanelParams: {
                  suppressPivotMode: true,
                  suppressValues: true,
                  suppressRowGroups: true,
                },
              },
            ],
            position: "left",
            defaultToolPanel: "filters",
          }}
          onRowDragMove={onRowDragMove}
          onRowDragEnd={onRowDragEnd}
          getRowId={(params: GetRowIdParams) => {
            return params.data.id;
          }}
          loadingOverlayComponent={loadingOverlayComponent}
          loadingOverlayComponentParams={loadingOverlayComponentParams}
          noRowsOverlayComponent={noRowsOverlayComponent}
          noRowsOverlayComponentParams={noRowsOverlayComponentParams}
          onRowGroupOpened={(params) => {
            gridState.performGroupChanged(params);
          }}
          onSelectionChanged={onSelectionChanged}
          onGridReady={onGridReady}
          onFirstDataRendered={gridState.onFirstDataRendered}
          onColumnEverythingChanged={gridState.onColumnChanged}
          onColumnResized={gridState.onColumnResized}
          onSortChanged={gridState.onColumnChanged}
          onColumnMoved={gridState.onColumnMoved}
        />
      </GridContainer>
      {/* </Show> */}
    </>
  );
};
