import { useAtomValue } from "jotai";
import { AccountAtom, TransactionsAtom } from "../../../store";
import { GetContextMenuItemsParams, MenuItemDef } from "ag-grid-community";
import { useTransactions } from "../../../hooks/useTransactions";
import { getAllChildrenOfGroup } from "../..";
import { useNotifications } from "../../../hooks/useNotifications";
import { usePositions } from "../../../hooks";
import moment from "moment";
import { useCallback, useState } from "react";
import { Trade } from "../../../types";
import {
  getCashflowIds,
  updateBookForCashflows,
  updateHiddenFieldForCashflows,
  updatePositionGroupForCashflows,
} from "../../../features/cashflows/CashflowsHelper";

interface UseGridContextMenuController {
  getContextMenuItems: (
    params: GetContextMenuItemsParams
  ) => (string | MenuItemDef)[];
  openExpireModal: (params: GetContextMenuItemsParams) => void;
  handleExpire: (fees: string, commission: string, notes: string) => void;
  isModalOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
  openCloseModal: (params: GetContextMenuItemsParams) => void;
  handleClose: (
    price: string,
    date: string,
    fees: string,
    commission: string,
    notes: string
  ) => void;
  openEditModal: (params: GetContextMenuItemsParams) => void;
  handleEdit: (
    date: string,
    price: string,
    quantity: string,
    notes: string
  ) => void;
  selectedParams: GetContextMenuItemsParams | null;
  openBookSearchModal: (params: GetContextMenuItemsParams) => void;
  openPositionGroupSearchModal: (params: GetContextMenuItemsParams) => void;
  isBookSearchModalOpen: boolean;
  isPositionGroupSearchModalOpen: boolean;
  closeBookSearchModal: () => void;
  closePositionGroupSearchModal: () => void;
  handleBookSelect: (book: string) => void;
  handlePositionGroupSelect: (group: string) => void;
}

export const useGridContextMenuController = (
  onOpenExpireModal: () => void,
  onCloseExpireModal: () => void,
  onOpenCloseModal: () => void,
  onCloseCloseModal: () => void,
  onOpenEditModal: () => void,
  onCloseEditModal: (trade?: Trade) => void,
  onOpenBookSearchModal?: () => void,
  onCloseBookSearchModal?: () => void,
  onOpenPositionGroupSearchModal?: () => void,
  onClosePositionGroupSearchModal?: () => void
): UseGridContextMenuController => {
  const [closingFees, setClosingFees] = useState("");
  const [closingCommission, setClosingCommission] = useState("");
  const [closingNotes, setClosingNotes] = useState("");
  const [selectedParams, setSelectedParams] =
    useState<GetContextMenuItemsParams | null>(null);
  const [isBookSearchModalOpen, setIsBookSearchModalOpen] = useState(false);
  const [isPositionGroupSearchModalOpen, setIsPositionGroupSearchModalOpen] = useState(false);

  const useTransactionsHook = useTransactions();
  const accountState = useAtomValue(AccountAtom);
  const transactions = useAtomValue(TransactionsAtom);
  const usePositionsHook = usePositions();
  const notifications = useNotifications();

  const handleExpire = useCallback(
    (fees: string, commission: string, notes: string) => {
      if (!selectedParams || !selectedParams.node) return;

      const closingDate = selectedParams.node.data.instrument.expiry;
      const children = getAllChildrenOfGroup(selectedParams, transactions as unknown as any[]);

      notifications.createTradeChangeNotification("Position expired");

      useTransactionsHook.createClosingTrade(
        selectedParams.node.data,
        children[0],
        accountState?.selectedAccount?.id,
        0, // Closing price is 0 for expiry
        parseFloat(fees) || 0,
        notes,
        parseFloat(commission) || 0,
        new Date(moment.utc(closingDate, "YYYY-MM-DD").format("YYYY-MM-DD"))
      );

      onCloseExpireModal(); // Close the modal after submitting
    },
    [
      selectedParams,
      closingFees,
      closingNotes,
      closingCommission,
      onCloseExpireModal,
      notifications,
      useTransactionsHook,
      accountState,
      transactions,
    ]
  );

  const handleClose = useCallback(
    (
      price: string,
      date: string,
      fees: string,
      commission: string,
      notes: string
    ) => {
      if (!selectedParams || !selectedParams.node) return;

      const children = getAllChildrenOfGroup(selectedParams, transactions as unknown as any[]);

      notifications.createTradeChangeNotification("Position closed");

      useTransactionsHook.createClosingTrade(
        selectedParams.node.data,
        children[0],
        accountState?.selectedAccount?.id,
        parseFloat(price) || 0,
        parseFloat(fees) || 0,
        notes,
        parseFloat(commission) || 0,
        new Date(date)
      );

      onCloseCloseModal();
    },
    [
      selectedParams,
      notifications,
      useTransactionsHook,
      accountState,
      transactions,
      onCloseCloseModal,
    ]
  );

  const handleEdit = useCallback(
    (
      date: string,
      price: string,
      quantity: string,
      notes: string
    ) => {
      if (!selectedParams) return;

      notifications.createTradeChangeNotification("Trade edited");

      // CLOSE MODAL IF SCUCESS
      onCloseEditModal();
    },
    [selectedParams, notifications, onCloseEditModal]
  );

  const openEditModal = (params: GetContextMenuItemsParams) => {
    setSelectedParams(params);
    onOpenEditModal();
  };

  const openExpireModal = (params: GetContextMenuItemsParams) => {
    setSelectedParams(params);
    onOpenExpireModal();
  };

  const openCloseModal = (params: GetContextMenuItemsParams) => {
    setSelectedParams(params);
    onOpenCloseModal();
  };

  const openBookSearchModal = (params: GetContextMenuItemsParams) => {
    setSelectedParams(params);
    if (onOpenBookSearchModal) {
      onOpenBookSearchModal();
    } else {
      setIsBookSearchModalOpen(true);
    }
  };

  const closeBookSearchModal = () => {
    if (onCloseBookSearchModal) {
      onCloseBookSearchModal();
    } else {
      setIsBookSearchModalOpen(false);
    }
  };

  const openPositionGroupSearchModal = (params: GetContextMenuItemsParams) => {
    setSelectedParams(params);
    if (onOpenPositionGroupSearchModal) {
      onOpenPositionGroupSearchModal();
    } else {
      setIsPositionGroupSearchModalOpen(true);
    }
  };

  const closePositionGroupSearchModal = () => {
    if (onClosePositionGroupSearchModal) {
      onClosePositionGroupSearchModal();
    } else {
      setIsPositionGroupSearchModalOpen(false);
    }
  };

  const handleBookSelect = useCallback(
    (book: string) => {
      if (!selectedParams) return;

      const children = getAllChildrenOfGroup(selectedParams, transactions as unknown as any[]);
      const cashflowIds = getCashflowIds(selectedParams);

      if (cashflowIds.length > 0) {
        updateBookForCashflows(
          book,
          cashflowIds,
          accountState?.selectedAccount?.id
        ).then(() => {
          if (!children || children.length === 0) {
            useTransactionsHook.refresh(accountState?.selectedAccount?.id);
          }
        });
      }

      notifications.createTradeChangeNotification(
        `Trades allocated to book: ${book}`
      );

      useTransactionsHook.updateBook(
        children,
        book,
        accountState?.selectedAccount?.id
      );
    },
    [selectedParams, transactions, accountState, notifications, useTransactionsHook]
  );

  const handlePositionGroupSelect = useCallback(
    (group: string) => {
      if (!selectedParams) return;

      const children = getAllChildrenOfGroup(selectedParams, transactions as unknown as any[]);
      const cashflowIds = getCashflowIds(selectedParams);

      if (cashflowIds.length > 0) {
        updatePositionGroupForCashflows(
          group,
          cashflowIds,
          accountState?.selectedAccount?.id
        ).then(() => {
          if (!children || children.length === 0) {
            useTransactionsHook.refresh(accountState?.selectedAccount?.id);
          }
        });
      }

      notifications.createTradeChangeNotification(
        `Trades allocated to group: ${group}`
      );

      useTransactionsHook.updateGroupNames(
        children,
        group,
        accountState?.selectedAccount?.id
      );
    },
    [selectedParams, transactions, accountState, notifications, useTransactionsHook]
  );

  const getContextMenuItems = (
    params: GetContextMenuItemsParams
  ): (string | MenuItemDef)[] => {
    var result: (string | MenuItemDef)[] = [
      {
        name: "Allocate to new book",
        action: async () => {
          let prompt = window.prompt("Enter new book name:");

          if (prompt) {
            const children = getAllChildrenOfGroup(params, transactions as unknown as any[]);
            const cashflowIds = getCashflowIds(params);

            if (cashflowIds.length > 0) {
              await updateBookForCashflows(
                prompt,
                cashflowIds,
                accountState?.selectedAccount?.id
              );
              if (!children || children.length === 0) {
                await useTransactionsHook.refresh(
                  accountState?.selectedAccount?.id
                );
              }
            }

            notifications.createTradeChangeNotification(
              `Trades allocated to new book: ${prompt}`
            );

            useTransactionsHook.updateBook(
              children,
              prompt,
              accountState?.selectedAccount?.id
            );
          }
        },
      },
      {
        name: "Allocate to existing book",
        disabled: transactions.books.length === 0 ? true : false,
        subMenu: [
          {
            name: "Search for book...",
            action: () => {
              openBookSearchModal(params);
            },
          },
          "separator",
          ...transactions.books
            .sort((a, b) => a.localeCompare(b))
            .slice(0, 15)
            .map((book) => {
              return {
                name: book,
                action: async () => {
                  notifications.createTradeChangeNotification(
                    `Trades allocated to existing book: ${book}`
                  );

                  const children = getAllChildrenOfGroup(params, transactions as unknown as any[]);
                  const cashflowIds = getCashflowIds(params);

                  if (cashflowIds.length > 0) {
                    await updateBookForCashflows(
                      book,
                      cashflowIds,
                      accountState?.selectedAccount?.id
                    );
                    if (!children || children.length === 0) {
                      await useTransactionsHook.refresh(
                        accountState?.selectedAccount?.id
                      );
                    }
                  }

                  useTransactionsHook.updateBook(
                    children,
                    book,
                    accountState?.selectedAccount?.id
                  );
                },
              };
            }),
        ],
      },
      {
        name: "Clear book",
        // disabled: transactions.books.length === 0 ? true : false,
        action: async () => {
          if (
            window.confirm("Are you sure you want to clear the book?") === false
          )
            return;

          const children = getAllChildrenOfGroup(params, transactions as unknown as any[]);
          const cashflowIds = getCashflowIds(params);

          if (cashflowIds.length > 0) {
            await updateBookForCashflows(
              "",
              cashflowIds,
              accountState?.selectedAccount?.id
            );
            if (!children || children.length === 0) {
              await useTransactionsHook.refresh(
                accountState?.selectedAccount?.id
              );
            }
          }

          notifications.createTradeChangeNotification(
            `Book cleared from trades`
          );
          useTransactionsHook.updateBook(
            children,
            "",
            accountState?.selectedAccount?.id
          );
        },
      },

      "separator",
      {
        name: "Allocate to new position group",
        action: async () => {
          let prompt = window.prompt("Enter new position group name:");
          notifications.createTradeChangeNotification(
            `Trades allocated to group: ${prompt}`
          );
          if (prompt) {
            const children = getAllChildrenOfGroup(params, transactions as unknown as any[]);
            const cashflowIds = getCashflowIds(params);

            if (cashflowIds.length > 0) {
              await updatePositionGroupForCashflows(
                prompt,
                cashflowIds,
                accountState?.selectedAccount?.id
              );
              if (!children || children.length === 0) {
                await useTransactionsHook.refresh(
                  accountState?.selectedAccount?.id
                );
              }
            }

            useTransactionsHook.updateGroupNames(
              children,
              prompt,
              accountState?.selectedAccount?.id
            );
          }
        },
      },
      {
        name: "Allocate to existing position group",
        disabled: transactions.groupNames.length === 0 ? true : false,
        subMenu: [
          {
            name: "Search for position group...",
            action: () => {
              openPositionGroupSearchModal(params);
            },
          },
          "separator",
          ...transactions.groupNames
            .sort((a, b) => a.localeCompare(b))
            .slice(0, 15)
            .map((groupName) => {
              return {
                name: groupName,
                action: async () => {
                  notifications.createTradeChangeNotification(
                    `Trades allocated to group: ${groupName}`
                  );
                  const children = getAllChildrenOfGroup(params, transactions as unknown as any[]);
                  const cashflowIds = getCashflowIds(params);

                  if (cashflowIds.length > 0) {
                    await updatePositionGroupForCashflows(
                      groupName,
                      cashflowIds,
                      accountState?.selectedAccount?.id
                    );
                    if (!children || children.length === 0) {
                      await useTransactionsHook.refresh(
                        accountState?.selectedAccount?.id
                      );
                    }
                  }

                  useTransactionsHook.updateGroupNames(
                    children,
                    groupName,
                    accountState?.selectedAccount?.id
                  );
                },
              };
            }),
        ],
      },
      {
        name: "Clear position group name",
        // disabled: transactions.groupNames.length === 0 ? true : false,

        action: async () => {
          //Confirm
          if (
            window.confirm("Are you sure you want to clear the group name?") ===
            false
          )
            return;

          const children = getAllChildrenOfGroup(params, transactions as unknown as any[]);
          const cashflowIds = getCashflowIds(params);

          if (cashflowIds.length > 0) {
            await updatePositionGroupForCashflows(
              "",
              cashflowIds,
              accountState?.selectedAccount?.id
            );
            if (!children || children.length === 0) {
              await useTransactionsHook.refresh(
                accountState?.selectedAccount?.id
              );
            }
          }

          notifications.createTradeChangeNotification(
            `Group name cleared from trades`
          );

          useTransactionsHook.updateGroupNames(
            children,
            "",
            accountState?.selectedAccount?.id
          );
        },
      },
      "separator",
      {
        name: "Close Position",
        disabled: params?.node?.data?.positionType !== "Trade",
        subMenu: [
          {
            name: "Close position",
            disabled:
              !params?.node?.data ||
              params?.node?.data?.quantity === 0 ||
              !params?.node?.data?.positionId ||
              params?.node?.data?.positionType !== "Trade",
            action: () => {
              openCloseModal(params);
            },
          },
          {
            name: "Expire position",
            disabled:
              !params?.node?.data ||
              params?.node?.data?.instrument?.instrumentType !== "Option" ||
              params?.node?.data?.quantity === 0 ||
              !params?.node?.data?.positionId ||
              params?.node?.data?.positionType !== "Trade",
            action: () => {
              openExpireModal(params);
            },
          },
        ],
      },
      {
        name: "Edit Trade",
        disabled: !params?.node?.data?.quantity,
        action: () => {
          openEditModal(params);
        },
      },
      {
        name: "Advanced",
        subMenu: [
          {
            name: "Hide trades (do not get imported again)",

            // disabled: params?.node?.data?.positionType !== "Trade",
            action: () => {
              if (
                window.confirm(
                  "This marks trades as hidden, they won't appear on the table or in any calculations and they won't be imported again. Are you sure you want to hide these trades?"
                ) === true
              ) {
                const selectedTrades = getAllChildrenOfGroup(
                  params,
                  transactions as unknown as any[]
                );
                notifications.createTradeChangeNotification(`Trades hidden`);
                useTransactionsHook.hideTrades(
                  selectedTrades,
                  accountState?.selectedAccount?.id
                );
              }
            },
          },
          {
            name: "Hide cashflows (do not get imported again)",
            // disabled: params?.node?.data?.positionType !== "Trade",
            action: async () => {
              if (
                window.confirm(
                  "This marks cashflows as hidden, they won't appear on the table or in any calculations and they won't be imported again. Are you sure you want to hide these cashflows?"
                ) === true
              ) {
                const cashflowIds = getCashflowIds(params);

                if (cashflowIds.length > 0) {
                  await updateHiddenFieldForCashflows(
                    true,
                    cashflowIds,
                    accountState?.selectedAccount?.id
                  );
                  await useTransactionsHook.refresh(
                    accountState?.selectedAccount?.id
                  );
                }
              }
            },
          },
          "separator",
          {
            name: "Auto assign strategies to 'strategy' field for open positions",
            // disabled: params?.node?.data?.positionType !== "Trade",
            action: () => {
              const children = getAllChildrenOfGroup(params, transactions as unknown as any[]);

              useTransactionsHook.autoAssignGroups(
                children,
                accountState?.selectedAccount?.id,
                "STRATEGY"
              );
            },
          },
          {
            name: "Auto assign strategies to 'position group' field for open positions",
            // disabled: params?.node?.data?.positionType !== "Trade",
            action: () => {
              const children = getAllChildrenOfGroup(params, transactions as unknown as any[]);

              useTransactionsHook.autoAssignGroups(
                children,
                accountState?.selectedAccount?.id,
                "POSITION_GROUP"
              );
            },
          },
          "separator",
          {
            name: "Delete trade(s)",
            // disabled: params?.node?.data?.positionType !== "Trade",
            action: async () => {
              if (
                window.confirm(
                  "This will delete the trades from the database. Are you sure you want to delete this trade?"
                ) === true
              ) {
                const selectedTrades = getAllChildrenOfGroup(
                  params,
                  transactions as unknown as any[]
                );

                const tradeIds = selectedTrades.map((trade) => trade.id);

                useTransactionsHook.deleteManyTrades(
                  tradeIds,
                  accountState?.selectedAccount?.id
                );

                setTimeout(async () => {
                  usePositionsHook.fetchPositions(
                    accountState?.selectedAccount?.id
                  );
                  useTransactionsHook.getTradesAndCashflows(
                    accountState?.selectedAccount?.id
                  );
                }, 400);
              }
            },
          },
          "separator",
          {
            name: "Move trades to different portfolio...",
            // disabled: params?.node?.data?.positionType !== "Trade",
            subMenu: accountState.accounts.map((account) => ({
              name: `${account.name}`,
              action: async () => {
                const children = getAllChildrenOfGroup(params, transactions as unknown as any[]);

                await useTransactionsHook.updateAccountId(
                  children,
                  account.id,
                  accountState?.selectedAccount?.id
                );

                setTimeout(async () => {
                  await Promise.all([
                    usePositionsHook.fetchPositions(
                      accountState?.selectedAccount?.id
                    ),
                    useTransactionsHook.getTradesAndCashflows(
                      accountState?.selectedAccount?.id
                    ),
                  ]);
                }, 400);
              },
            })),
          },
        ],
      },
    ];

    return result;
  };

  return {
    getContextMenuItems,
    openExpireModal,
    handleExpire,
    isModalOpen: isBookSearchModalOpen || isPositionGroupSearchModalOpen,
    setModalOpen: (isOpen: boolean) => {
      setIsBookSearchModalOpen(isOpen);
      setIsPositionGroupSearchModalOpen(isOpen);
    },
    openCloseModal,
    handleClose,
    openEditModal,
    handleEdit,
    selectedParams,
    openBookSearchModal,
    openPositionGroupSearchModal,
    isBookSearchModalOpen,
    isPositionGroupSearchModalOpen,
    closeBookSearchModal,
    closePositionGroupSearchModal,
    handleBookSelect,
    handlePositionGroupSelect,
  };
};
