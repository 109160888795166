import { APIEndpoint } from ".";
import { distinct } from "../utils";

const token = localStorage.getItem("token");

export const getBooks = async (accountId: number): Promise<string[]> => {
  const result = await fetch(`${APIEndpoint}/reference-data/books`, {
    method: "GET",
    headers: {
      Authorization: token || "",
      AccountId: accountId as any,
    },
  });

  if (result.ok) {
    const data = await result.json();
    if (data.success && data.data) {
      return data.data as string[];
    }
  }

  return [];
};

export const getStrategies = async (accountId: number): Promise<string[]> => {
  const result = await fetch(`${APIEndpoint}/reference-data/strategies`, {
    method: "GET",
    headers: {
      Authorization: token || "",
      AccountId: accountId as any,
    },
  });

  if (result.ok) {
    const data = await result.json();
    if (data.success && data.data) {
      return distinct([
        ...(data.data.baseStrategies ?? []),
        ...(data.data.tradedStrategies ?? []),
      ]).sort();
    }
  }

  return [];
};

export const getGroupNames = async (accountId: number): Promise<string[]> => {
  const result = await fetch(`${APIEndpoint}/reference-data/groupnames`, {
    method: "GET",
    headers: {
      Authorization: token || "",
      AccountId: accountId as any,
    },
  });

  if (result.ok) {
    const data = await result.json();
    if (data.success && data.data) {
      return data.data.sort() as string[];
    }
  }

  return [];
};

export const getTags = async (accountId: number): Promise<string[]> => {
  const result = await fetch(`${APIEndpoint}/reference-data/tags`, {
    method: "GET",
    headers: {
      Authorization: token || "",
      AccountId: accountId as any,
    },
  });

  if (result.ok) {
    const data = await result.json();
    if (data.success && data.data) {
      return data.data.sort() as string[];
    }
  }

  return [];
};
