import { HStack, Text } from "@chakra-ui/react";
import React from "react";
import { MdCategory } from "react-icons/md";

export const CategoryRenderer = (params: any) => {
  let category = params.node.aggData.category ?? params.node.aggData.category;
  category =
    category === "null" || category === undefined || category === "Ungrouped"
      ? "Unspecified"
      : category;
  return (
    <HStack>
      {!category || category.toLowerCase() === "unspecified" ? (
        <>
          <Text color="var(--red)">
            <MdCategory />
          </Text>
          <Text fontWeight={500} color="var(--red)">
            No Category ({params.node.allChildrenCount})
          </Text>
        </>
      ) : (
        <>
          <Text>
            <MdCategory />
          </Text>
          <Text fontWeight={500}>
            {category} ({params.node.allChildrenCount})
          </Text>
        </>
      )}
    </HStack>
  );
};
