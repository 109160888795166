import React from "react";
import { InsightDefinition } from "../../../types/Insight";
import { InsightHeader2, InsightRenderer2, InsightWrapper2 } from ".";
import InsightErrorBoundary from "../../dashboard/canvas/insight/InsightRenderers/InsightErrorBoundary";
import { Center, HStack, Text, VStack, Box, keyframes } from "@chakra-ui/react";
import { BiMove } from "react-icons/bi";

interface InsightContainer2Props {
  insight: InsightDefinition;
  resizing?: boolean;
  embedded?: boolean;
}

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

export const InsightContainer2 = (props: InsightContainer2Props) => {
  const { insight, resizing, embedded } = props;

  return (
    <InsightWrapper2>
      <InsightHeader2 insight={insight} embedded={embedded} />

      <InsightErrorBoundary>
        {!resizing && <InsightRenderer2 insight={insight} demo={false} />}
        {resizing && (
          <Center h="100%" w="100%" position="absolute" top="0" left="0" 
            bg="rgba(0,0,0,0.7)" backdropFilter="blur(2px)" 
            transition="all 0.2s ease">
            <VStack spacing={4}>
              <Box animation={`${pulse} 1.5s infinite`}>
                <HStack  p={3} borderRadius="md">
                  <BiMove size={20} />
                  <Text fontWeight="bold">Resizing...</Text>
                </HStack>
              </Box>
              <Text fontSize="sm" color="gray.300">
                Drag corners or edges to resize
              </Text>
            </VStack>
          </Center>
        )}
      </InsightErrorBoundary>
    </InsightWrapper2>
  );
};
