import { useCallback, useEffect, useState } from "react";
import { AccountAtom, TransactionsAtom } from "../../store";
import { useAtomValue, useSetAtom } from "jotai";
import { getBooks, getStrategies, getGroupNames, getTags } from "../../api";
import { updateManyTrades } from "../../api/Trades";

export interface ExplainTradeProps {
  trades: any[];
  onExplainTrade: () => void;
}

interface UseExplainTradesResponse {
  nbrTrades: number;
  books: string[];
  selectedBook: string | undefined;
  setSelectedBook: (value: string | undefined) => void;
  positionGroups: string[];
  selectedPositionGroup: string | undefined;
  setSelectedPositionGroup: (value: string | undefined) => void;
  strategies: string[];
  selectedStrategy: string | undefined;
  setSelectedStrategy: (value: string | undefined) => void;
  tags: string[];
  selectedTags: string[];
  setSelectedTags: (value: string[]) => void;
  notes: string;
  setNotes: (notes: string) => void;
  saveChanges: () => Promise<void>;
  loading: boolean;
  determiningState: boolean;
  saving: boolean;
  applyField: (field: string) => void;
}

export const useExplainTrades = (
  props: ExplainTradeProps
): UseExplainTradesResponse => {
  const [loading, setLoading] = useState(false);
  const [determiningState, setDeterminingState] = useState(false);
  const [books, setBooks] = useState<string[]>([]);
  const [strategies, setStrategies] = useState<string[]>([]);
  const [positionGroups, setPositionGroups] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const accountState = useAtomValue(AccountAtom);
  const transactionState = useAtomValue(TransactionsAtom);
  const setTransactionsState = useSetAtom(TransactionsAtom);
  const [saving, setSaving] = useState(false);

  const loadReferenceData = () => {
    if (!loading && accountState.selectedAccount?.id) {
      setLoading(true);
      setTimeout(async () => {
        try {
          setBooks(await getBooks(accountState.selectedAccount!.id));
          setStrategies(await getStrategies(accountState.selectedAccount!.id));
          setPositionGroups(
            await getGroupNames(accountState.selectedAccount!.id)
          );
          setTags(await getTags(accountState.selectedAccount!.id));
        } finally {
          setLoading(false);
        }
      }, 1);
    }
  };

  const [selectedBook, setSelectedBook] = useState<string | undefined>(
    undefined
  );

  const [selectedPositionGroup, setSelectedPositionGroup] = useState<
    string | undefined
  >(undefined);

  const [selectedTagsState, setSelectedTagsState] = useState<string[]>([]);

  const [selectedStrategy, setSelectedStrategy] = useState<string | undefined>(
    undefined
  );

  // notes
  const [notes, setNotes] = useState<string>("");

  const determineBooks = useCallback(() => {
    if (!loading) {
      let result = undefined;
      for (const trade of props.trades) {
        if (!result && trade.book) {
          result = trade.book;
        } else if (result && trade.book && result !== trade.book) {
          result = undefined;
          break;
        }
      }
      setSelectedBook(result);
    }
  }, [props.trades, loading]);

  const determinePositionGroups = useCallback(() => {
    if (!loading) {
      let result = undefined;
      for (const trade of props.trades) {
        if (!result && trade.positionGroup) {
          result = trade.positionGroup;
        } else if (
          result &&
          trade.positionGroup &&
          result !== trade.positionGroup
        ) {
          result = undefined;
          break;
        }
      }
      setSelectedPositionGroup(result);
    }
  }, [props.trades, loading]);

  const determineStrategies = useCallback(() => {
    if (!loading) {
      let result = undefined;
      for (const trade of props.trades) {
        if (!result && trade.strategy) {
          result = trade.strategy;
        } else if (result && trade.strategy && result !== trade.strategy) {
          result = undefined;
          break;
        }
      }
      setSelectedStrategy(result);
    }
  }, [props.trades, loading]);

  const determineTags = useCallback(() => {
    if (!loading) {
      let result = undefined;
      for (const trade of props.trades) {
        if (trade.tags) {
          const tradeTags = trade.tags as string[];
          const ordered = tradeTags.sort().join(",");
          if (!result) {
            result = ordered;
          } else if (result !== ordered) {
            result = undefined;
            break;
          }
        }
      }
      setSelectedTagsState(result ? (result?.split(",") ?? []) : []);
    }
  }, [props.trades, loading]);

  const determineNotes = useCallback(() => {
    if (!loading) {
      let result = "";
      for (const trade of props.trades) {
        if (trade.notes) {
          if (!result) {
            result = trade.notes;
          } else if (result !== trade.notes) {
            result = "";
            break;
          }
        }
      }
      setNotes(result);
    }
  }, [props.trades, loading]);

  const setSelectedTags = useCallback(
    async (newTags: string[]) => {
      setSelectedTagsState(newTags);

      if (newTags.length > 0) {
        const allTagsSet = new Set([...transactionState.tags, ...newTags]);
        const allTags = Array.from(allTagsSet);

        if (allTags.length !== transactionState.tags.length) {
          setTransactionsState((prevState) => ({
            ...prevState,
            tags: allTags,
          }));
        }
      }
    },
    [transactionState.tags, setTransactionsState, props.onExplainTrade]
  );

  const clearState = useCallback(() => {
    setSelectedBook(undefined);
    setSelectedPositionGroup(undefined);
    setSelectedStrategy(undefined);
    setSelectedTagsState([]);
    setNotes("");
  }, []);

  const applyField = async (field: string) => {
    setSaving(true);
    if (accountState.selectedAccount?.id) {
      let tradesToUpdate = props.trades;
      switch (field) {
        case "book":
          tradesToUpdate = props.trades.map((trade) => {
            if (selectedBook) {
              trade.book = selectedBook;
            } else {
              trade.book = "";
            }
            return trade;
          });
          break;
        case "positionGroup":
          tradesToUpdate = props.trades.map((trade) => {
            if (selectedPositionGroup) {
              trade.positionGroup = selectedPositionGroup;
            } else {
              trade.positionGroup = "";
            }
            return trade;
          });
          break;
        case "strategy":
          tradesToUpdate = props.trades.map((trade) => {
            if (selectedStrategy) {
              trade.strategy = selectedStrategy;
            } else {
              trade.strategy = "";
            }
            return trade;
          });
          break;
        case "tags":
          tradesToUpdate = props.trades.map((trade) => {
            if (selectedTagsState) {
              trade.tags = selectedTagsState;
            } else {
              trade.tags = [];
            }
            return trade;
          });
          break;
        case "notes":
          tradesToUpdate = props.trades.map((trade) => {
            if (notes) {
              trade.notes = notes;
            } else {
              trade.notes = "";
            }
            return trade;
          });
          break;
      }

      await updateManyTrades(tradesToUpdate, accountState.selectedAccount?.id);
      props.onExplainTrade();
      clearState();
    }
    setSaving(false);
  };

  const saveChanges = async () => {
    setSaving(true);
    if (accountState.selectedAccount?.id) {
      const updatedTrades = props.trades.map((trade) => {
        if (selectedStrategy) {
          trade.strategy = selectedStrategy;
        } else {
          trade.strategy = "";
        }

        if (selectedBook) {
          trade.book = selectedBook;
        } else {
          trade.book = "";
        }

        if (selectedPositionGroup) {
          trade.positionGroup = selectedPositionGroup;
        } else {
          trade.positionGroup = "";
        }

        if (selectedTagsState) {
          trade.tags = selectedTagsState;
        } else {
          trade.tags = [];
        }

        if (notes) {
          trade.notes = notes;
        } else {
          trade.notes = "";
        }

        return trade;
      });

      await updateManyTrades(updatedTrades, accountState.selectedAccount?.id);
      props.onExplainTrade();
      clearState();
    }
    setSaving(false);
  };

  useEffect(() => {
    if (!loading && props.trades.length > 0) {
      clearState();
      setDeterminingState(true);
      setTimeout(() => {
        determineBooks();
        determinePositionGroups();
        determineStrategies();
        determineTags();
        determineNotes();
        setTimeout(() => {
          setDeterminingState(false);
        }, 0);
      }, 50);
    }
  }, [props.trades, loading]);

  useEffect(() => {
    loadReferenceData();
  }, [accountState.selectedAccount]);

  return {
    nbrTrades: props.trades.length ?? 0,
    books,
    loading,
    selectedBook,
    setSelectedBook,
    positionGroups,
    selectedPositionGroup,
    setSelectedPositionGroup,
    tags,
    selectedTags: selectedTagsState,
    setSelectedTags,
    notes,
    setNotes,
    strategies,
    selectedStrategy,
    setSelectedStrategy,
    saveChanges,
    determiningState,
    saving,
    applyField,
  };
};
