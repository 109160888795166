import { TOTAL_NET_PROCEEDS } from "./TOTAL_NET_PROCEEDS";
import { AVG_DAYS_IN_POSITION } from "./AVG_DAYS_IN_POSITION";
import { BIGGEST_LOSER } from "./BIGGEST_LOSER";
import { BIGGEST_WINNER } from "./BIGGEST_WINNER";
import { REALIZED_PNL } from "./REALIZED_PNL";
import { TOTAL_COSTS } from "./TOTAL_COSTS";
import { TOTAL_CREDITS } from "./TOTAL_CREDITS";
import { TOTAL_DEBITS } from "./TOTAL_DEBITS";
import { TOTAL_GROSS_PROCEEDS } from "./TOTAL_GROSS_PROCEEDS";
import { TOTAL_PNL } from "./TOTAL_PNL";
import { TOTAL_PREMIUM_RECEIVED } from "./TOTAL_PREMIUM_RECEIVED";
import { UNREALIZED_PNL } from "./UNREALIZED_PNL";
import {
  TOTAL_CASHFLOWS,
  TOTAL_DIVIDENDS,
  TOTAL_TAX,
  TOTAL_INTEREST,
  TOTAL_FEE,
  TOTAL_DEPOSIT,
  TOTAL_WITHDRAWAL,
} from "./CASHFLOWS";

const InsightNumberDemoDataMap = {
  TOTAL_NET_PROCEEDS: TOTAL_NET_PROCEEDS,
  AVG_DAYS_IN_POSITION: AVG_DAYS_IN_POSITION,
  BIGGEST_LOSER: BIGGEST_LOSER,
  BIGGEST_WINNER: BIGGEST_WINNER,
  REALIZED_PNL: REALIZED_PNL,
  TOTAL_COSTS: TOTAL_COSTS,
  TOTAL_CREDITS: TOTAL_CREDITS,
  TOTAL_DEBITS: TOTAL_DEBITS,
  TOTAL_GROSS_PROCEEDS: TOTAL_GROSS_PROCEEDS,
  TOTAL_PNL: TOTAL_PNL,
  // TODO - REVIEW
  TOTAL_PREMIUM_RECEIVED: TOTAL_PREMIUM_RECEIVED,
  UNREALIZED_PNL: UNREALIZED_PNL,
  TOTAL_CASHFLOWS: TOTAL_CASHFLOWS,
  TOTAL_DIVIDENDS: TOTAL_DIVIDENDS,
  TOTAL_TAX: TOTAL_TAX,
  TOTAL_INTEREST: TOTAL_INTEREST,
  TOTAL_FEE: TOTAL_FEE,
  TOTAL_DEPOSIT: TOTAL_DEPOSIT,
  TOTAL_WITHDRAWAL: TOTAL_WITHDRAWAL,
};

export default InsightNumberDemoDataMap;
