import { useCallback, useEffect, useState } from "react";
import { UserFiltersAtom } from "../../store";
import { useAtom } from "jotai";
import { UserFilter } from "../../types";
import {
  createUserFilter,
  deleteUserFilter,
  getUserFilters,
  updateUserFilterName,
} from "../../api";
import { FilterStateAtom } from "../../store/FilterStateAtom";
import { QuickFilterAtom } from "../../store/QuickFilterAtom";

interface useFilterSettingsControllerResponse {
  quickFilterEnabled: boolean;
  toggleQuickFilter: () => void;
  filters: UserFilter[];
  selectedFilterId: number | undefined;
  setSelectedFilterId: (id: number) => void;
  selectedFilter?: UserFilter;
  renameFilter: (filterId: number, name: string) => void;
  createFilter: (name: string) => void;
  deleteFilter: (filterId: number) => void;
  filterApplied: boolean;
  setFilterApplied: (value: boolean) => void;
  refreshFilters: () => void;
}

export const useFilterSettingsController =
  (): useFilterSettingsControllerResponse => {
    const [quickFilter, setQuickFilter] = useAtom(QuickFilterAtom);
    const [filterValues, setFilterValues] = useAtom(FilterStateAtom);
    const [filters, setFilters] = useAtom(UserFiltersAtom);
    const [loading, setLoading] = useState(false);

    const toggleQuickFilter = useCallback(() => {
      if (!quickFilter.enabled) {
        setFilterValues((prevState) => {
          return { ...prevState, filterApplied: false };
        });
      }
      setQuickFilter((prevState) => {
        return { ...prevState, enabled: !prevState.enabled };
      });
    }, [quickFilter.enabled]);

    const selectedFilter = filters.find(
      (x) => x.id === filterValues.selectedFilterId
    );

    const renameFilter = useCallback(
      async (filterId: number, name: string) => {
        if (filterId && name) {
          if (await updateUserFilterName(filterId, name)) {
            await loadFilters();
            setFilterValues((prevState) => {
              return { ...prevState, selectedFilterId: filterId };
            });
          }
        }
      },
      [filterValues.selectedFilterId]
    );

    const createFilter = useCallback(async (name: string) => {
      if (name) {
        const result = await createUserFilter(name);

        if (result) {
          await loadFilters();
          setFilterValues((prevState) => {
            return { ...prevState, selectedFilterId: result };
          });
        }
      }
    }, []);

    const deleteFilter = useCallback(
      async (filterId: number) => {
        if (filterId) {
          const currentFilter = filterValues.selectedFilterId;
          if (await deleteUserFilter(filterId)) {
            const result = await getUserFilters();
            setFilters(result);
            if (currentFilter === filterId) {
              const id = result.length > 0 ? result[0].id : undefined;
              setFilterValues((prevState) => {
                return {
                  ...prevState,
                  selectedFilterId: id,
                  filterApplied: false,
                };
              });
            }
          }
        }
      },
      [filterValues.selectedFilterId]
    );

    const loadFilters = async () => {
      const result = await getUserFilters();

      if (result && result.length > 0) {
        setFilters(result);
      }
    };

    const refreshFilters = useCallback(async () => {
      await loadFilters();
    }, []);

    const selectFilter = (id: number) => {
      setFilterValues((prevState) => {
        return { ...prevState, selectedFilterId: id };
      });
    };

    const toggleFilter = useCallback(() => {
      setFilterValues((prevState) => {
        if (
          !prevState.filterApplied &&
          prevState.selectedFilterId === undefined &&
          filters.length > 0
        ) {
          return {
            ...prevState,
            filterApplied: true,
            selectedFilterId: filters[0].id,
          };
        } else {
          return { ...prevState, filterApplied: !prevState.filterApplied };
        }
      });
    }, [filterValues.filterApplied, filters]);

    useEffect(() => {
      if (!filterValues.filterApplied) {
        setFilterValues((prevState) => {
          return {
            ...prevState,
            selectedUnderlying: undefined,
            selectedTag: "",
            // toDate: moment().add(1, "day").toDate(),
            // fromDate: moment().subtract(5, "year").toDate(),
            selectedPositionGroup: "",
            selectedBook: "",
            selectedStrategy: "",
          };
        });
      } else if (selectedFilter) {
        setQuickFilter((prevState) => {
          return { ...prevState, enabled: false };
        });
        setFilterValues((prevState) => {
          return {
            ...prevState,
            selectedUnderlying: selectedFilter.filterConfig?.underlyings?.length
              ? selectedFilter.filterConfig?.underlyings[0]
              : undefined,
            selectedTag: selectedFilter.filterConfig?.tags?.length
              ? selectedFilter.filterConfig?.tags[0]
              : undefined,
            // toDate: moment().add(1, "day").toDate(),
            // fromDate: moment().subtract(5, "year").toDate(),
            selectedPositionGroup: selectedFilter.filterConfig?.positionGroups
              ?.length
              ? selectedFilter.filterConfig?.positionGroups[0]
              : undefined,
            selectedBook: selectedFilter.filterConfig?.books?.length
              ? selectedFilter.filterConfig?.books[0]
              : undefined,
            selectedStrategy: selectedFilter.filterConfig?.strategies?.length
              ? selectedFilter.filterConfig?.strategies[0]
              : undefined,
          };
        });
      }
    }, [filterValues.filterApplied]);

    useEffect(() => {
      if (selectedFilter && !filterValues.filterApplied) {
        setFilterValues((prevState) => {
          return { ...prevState, filterApplied: true };
        });
      }
    }, [filterValues.selectedFilterId]);

    useEffect(() => {
      (async () => {
        await loadFilters();
      })();
    }, []);

    return {
      quickFilterEnabled: quickFilter.enabled,
      toggleQuickFilter,
      filters: filters.sort((a, b) => a.name.localeCompare(b.name)),
      selectedFilterId: filterValues.selectedFilterId,
      setSelectedFilterId: selectFilter,
      selectedFilter,
      renameFilter,
      createFilter,
      deleteFilter,
      filterApplied: filterValues.filterApplied,
      setFilterApplied: toggleFilter,
      refreshFilters,
    };
  };
