import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Heading,
  Flex,
  VStack,
  Text,
  HStack,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useMemo, lazy, Suspense } from "react";
import { useInstruments } from "../../hooks";
import { Trade, TradeEx } from "../../types";

// Lazy load components that might not be needed immediately
const CalendarNoteEditor = lazy(() => import("./CalendarNoteEditor"));
const PositionsAgGrid = lazy(
  () =>
    import(
      "../../features/positions/position-details/components/PositionsAgGrid"
    )
);
const TradesAgGrid = lazy(
  () =>
    import("../../features/positions/position-details/components/TradesAgGrid")
);
// Handle named export for NotesTimeline
const NotesTimeline = lazy(() =>
  import("../timeline/NotesTimeline").then((module) => ({
    default: module.NotesTimeline,
  }))
);

// Loading fallback component
const LoadingFallback = () => (
  <Flex justify="center" align="center" minH="200px">
    <Text>Loading...</Text>
  </Flex>
);

interface Earning {
  instrumentId: string;
  instrument?: {
    symbol: string;
  };
  beforeAfterMarket: string;
}

interface CalendarNotesModalProps {
  isOpen: boolean;
  onClose: () => void;
  date: Date;
  note: {
    content: string;
    date: Date;
  };
  expiries: Trade[];
  trades: Trade[];
  earnings: Earning[];
}

export const CalendarNotesModal = React.memo(
  ({
    isOpen,
    onClose,
    date,
    note,
    expiries,
    trades,
    earnings,
  }: CalendarNotesModalProps) => {
    const instruments = useInstruments();
   
    // Map the trades array to include `instrument` and `underlyingInstrument`
    const updatedTrades: TradeEx[] = useMemo(() => {
      if (!trades?.length || !instruments?.list?.length) return [];

      return trades.map((trade: Trade) => ({
        ...trade,
        instrument: trade.instrumentId
          ? instruments.list.find((x) => x.id === trade.instrumentId)
          : undefined,
        underlyingInstrument: trade.underlyingInstrumentId
          ? instruments.list.find((x) => x.id === trade.underlyingInstrumentId)
          : instruments.list.find((x) => x.id === trade.instrumentId),
      }));
    }, [trades, instruments]);

    // Map the expiries array to include `instrument` and `underlyingInstrument`
    const updatedExpiries: TradeEx[] = useMemo(() => {
      if (!expiries?.length || !instruments?.list?.length) return [];

      return expiries.map((trade: Trade) => ({
        ...trade,
        instrument: trade.instrumentId
          ? instruments.list.find((x) => x.id === trade.instrumentId)
          : undefined,
        underlyingInstrument: trade.underlyingInstrumentId
          ? instruments.list.find((x) => x.id === trade.underlyingInstrumentId)
          : instruments.list.find((x) => x.id === trade.instrumentId),
        positionName: trade.positionGroup,
      }));
    }, [expiries, instruments.list]);

    // Sort earnings by symbol
    const sortedEarnings = useMemo(() => {
      if (!earnings?.length) return [];

      return [...earnings].sort((a, b) => {
        if (!a?.instrument?.symbol || !b?.instrument?.symbol) return 0;
        return a.instrument.symbol.localeCompare(b.instrument.symbol);
      });
    }, [earnings]);

    // Only render the modal content when it's open to improve performance
    if (!isOpen) return null;

    return (
      <Modal isOpen={isOpen} size="full" onClose={onClose}>
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
        <ModalContent bg="var(--bg)">
          <ModalHeader p={0} pl={6} mt={4}>
            {date ? moment(date).format("Do MMMM YYYY") : ""}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Using Tabs to switch between sections */}
            <Tabs>
              <TabList>
                <Tab>Notes</Tab>
                {updatedExpiries.length > 0 && <Tab>Upcoming Expiries</Tab>}
                {updatedTrades.length > 0 && <Tab>Trades</Tab>}
                {updatedTrades.length > 0 && <Tab>Timeline</Tab>}
                {sortedEarnings.length > 0 && <Tab>Earnings</Tab>}
              </TabList>

              <TabPanels>
                <TabPanel>
                  <Suspense fallback={<LoadingFallback />}>
                    <CalendarNoteEditor note={note} menuBarEnabled={true} />
                  </Suspense>
                </TabPanel>

                {updatedExpiries.length > 0 && (
                  <TabPanel>
                    <Heading mb="10px" size="sm">
                      Upcoming expiries on open positions
                    </Heading>
                    <Suspense fallback={<LoadingFallback />}>
                      <PositionsAgGrid trades={updatedExpiries} />
                    </Suspense>
                  </TabPanel>
                )}

                {updatedTrades.length > 0 && (
                  <TabPanel>
                    <Heading mb="10px" size="sm">
                      Trades for this day
                    </Heading>
                    <Suspense fallback={<LoadingFallback />}>
                      <TradesAgGrid trades={updatedTrades} />
                    </Suspense>
                  </TabPanel>
                )}

                {updatedTrades.length > 0 && (
                  <TabPanel>
                    <Suspense fallback={<LoadingFallback />}>
                      <NotesTimeline trades={updatedTrades} />
                    </Suspense>
                  </TabPanel>
                )}

                {sortedEarnings.length > 0 && (
                  <TabPanel>
                    <VStack spacing={4} w="full" alignItems={"flex-start"}>
                      {sortedEarnings.map((e) => (
                        <HStack key={e.instrumentId}>
                          <Text fontWeight={600} minW="100px">
                            {e?.instrument?.symbol}
                          </Text>
                          <Text>
                            {e?.beforeAfterMarket === "AfterMarket"
                              ? "After Market"
                              : "Before Market"}
                          </Text>
                        </HStack>
                      ))}
                    </VStack>
                  </TabPanel>
                )}
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }
);

// Add display name for debugging
CalendarNotesModal.displayName = "CalendarNotesModal";
