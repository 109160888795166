import React from "react";
import {
  Box,
  Button,
  Heading,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  VStack,
  HStack,
  Image,
  Tooltip,
  Spinner,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Badge,
  Divider,
  useColorModeValue,
} from "@chakra-ui/react";
import { TbPlayerPlayFilled } from "react-icons/tb";
import { useAutoSyncController } from "./useAutoSyncController";
import { AddOrEditAutoSyncConfiguration } from "./components/AddOrEditAutoSyncConfiguration";
import { Brokers } from "../../types";
import { useNavigate } from "react-router";
import { FaCog, FaSync } from "react-icons/fa";
import { InfoIcon, WarningTwoIcon } from "@chakra-ui/icons";
import DeleteSyncSettingsComponent from "./components/DeleteSyncSettingsComponent";
import moment from "moment";

export const AutoSync = ({ minimalGui }: { minimalGui?: boolean }) => {
  const controller = useAutoSyncController();
  const navigate = useNavigate();
  const borderColor = useColorModeValue("gray.200", "gray.700");

  const getPanelContent = () => {
    if (controller.settings.length === 0) {
      return (
        <Box pt={4} pb={4}>
          <Flex direction="column" alignItems="center" justifyContent="center" py={8}>
            <FaSync size={40} opacity={0.5} />
            <Text fontSize="md" mt={4} textAlign="center">
              No auto sync settings defined for this account.
            </Text>
            <Text fontSize="sm" color="gray.500" mt={2} textAlign="center">
              Add a configuration to automatically sync your investment data.
            </Text>
          </Flex>
        </Box>
      );
    } else {
      return (
        <Flex my={6}>
          <VStack w="full" spacing={4}>
            {controller.settings.map((setting) => {
              // Check if setting has an 'active' property and provide a fallback
              const isActive = 'active' in setting ? setting.active : true;
              
              return (
                <Box 
                  key={setting.syncSettingId}
                  w="100%" 
                  p={4} 
                  borderWidth="1px" 
                  borderColor={borderColor}
                  borderRadius="md"
                  bg={useColorModeValue("white", "gray.800")}
                  boxShadow="sm"
                >
                  <HStack
                    justifyContent={"space-between"}
                    w="100%"
                  >
                    <HStack
                      justifyContent={"flex-start"}
                      w="full"
                      spacing={4}
                    >
                      <Button
                        w="110px"
                        isDisabled={!isActive}
                        leftIcon={
                          isActive ? (
                            <TbPlayerPlayFilled />
                          ) : (
                            <WarningTwoIcon />
                          )
                        }
                        size="md"
                        colorScheme={isActive ? "green" : "red"}
                        onClick={() => {
                          controller.run(setting);
                        }}
                      >
                        {isActive ? "Sync Now" : "Inactive"}
                      </Button>

                      <Button
                        size="md"
                        colorScheme="blue"
                        variant="outline"
                        onClick={() => {
                          if (minimalGui) {
                            navigate("/app/settings");
                          } else {
                            controller.setSelectedSettings(setting);
                            controller.setShowConfigure(true);
                          }
                        }}
                      >
                        {minimalGui ? <FaCog /> : "Edit Configuration"}
                      </Button>

                      <Flex direction="column">
                        <HStack>
                          <Text fontWeight="semibold">{setting.broker}</Text>
                          {isActive && (
                            <Badge colorScheme="green">Active</Badge>
                          )}
                          {!isActive && (
                            <Badge colorScheme="red">Inactive</Badge>
                          )}
                        </HStack>
                        <Text fontSize="sm" color="gray.500">
                          Last updated {moment(setting.updatedAt).calendar()}
                        </Text>
                      </Flex>
                    </HStack>

                    {!minimalGui && (
                      <DeleteSyncSettingsComponent
                        setting={setting}
                        controller={controller}
                      />
                    )}
                  </HStack>
                </Box>
              );
            })}
          </VStack>
        </Flex>
      );
    }
  };

  if (minimalGui) {
    return (
      <Flex p={1}>
        {controller.loading && (
          <Flex
            bg="var(--bg)"
            h="18px"
            w="40px"
            justifyContent="center"
            alignItems="center"
          >
            <Spinner size="sm" />
          </Flex>
        )}

        <Popover placement="bottom-start">
          <PopoverTrigger>
            <Button p="0" m={0} bg="transparent" maxH="16px">
              {!controller.loading &&
                controller.settings.map((setting) => {
                  // Check if setting has an 'active' property and provide a fallback
                  const isActive = 'active' in setting ? Boolean(setting.active) : true;
                  
                  // Helper function to get image source
                  const getImageSource = (): string => {
                    if (setting.broker === Brokers.Schwab) {
                      return "https://cdn.wealthbee.io/main/brokers/broker_icons/schwab.png";
                    } else if (setting.broker === Brokers.ETrade) {
                      return "https://cdn.wealthbee.io/main/brokers/broker_icons/etrade.png";
                    } else if (setting.broker === Brokers.IBKR) {
                      return "https://cdn.wealthbee.io/main/brokers/broker_icons/interactive-brokers.png";
                    }
                    return "";
                  }
                  
                  return (
                    <HStack key={setting.syncSettingId} justifyContent={"space-between"} w="full">
                      <Box pr="1" position="relative">
                        {!isActive && (
                          <Tooltip
                            label={`${setting.broker} is not connected. Please activate it in the settings page`}
                          >
                            <Box
                              h="24px"
                              w="24px"
                              bg="red"
                              pos={"absolute"}
                              opacity={1}
                              borderRadius="full"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <WarningTwoIcon color="white" />
                            </Box>
                          </Tooltip>
                        )}
                        <Image
                          height="24px"
                          width="24px"
                          src={getImageSource()}
                          borderRadius="sm"
                          alt={`${setting.broker} icon`}
                        />
                      </Box>
                    </HStack>
                  );
                })}
            </Button>
          </PopoverTrigger>
          <PopoverContent w="320px" bg="var(--bg)" boxShadow="lg" borderColor={borderColor}>
            <PopoverArrow bg="var(--bg)" />
            <PopoverBody p={4}>
              <Text fontWeight="semibold" mb={2}>Auto Sync Settings</Text>
              <Divider mb={3} />
              <Box>{getPanelContent()}</Box>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>
    );
  }

  return (
    <Flex direction="column">
      <Flex justify="space-between" align="center" mb={6}>
        <Heading as="h3" size="lg">
          Auto Sync
        </Heading>
        <Tooltip label="Automatically sync your investment data from supported brokers">
          <InfoIcon ml={2} color="gray.500" />
        </Tooltip>
      </Flex>
      
      {controller.loading ? (
        <Flex justify="center" py={8}>
          <Spinner size="lg" />
        </Flex>
      ) : (
        getPanelContent()
      )}
      
      <Flex mt={6}>
        <Button
          w="full"
          colorScheme="blue"
          leftIcon={<FaCog />}
          onClick={() => {
            controller.setSelectedSettings(undefined);
            controller.setShowConfigure(true);
          }}
          size="lg"
          bg="var(--accent)"
          color="white"
          _hover={{ bg: "var(--accent)", opacity: 0.9 }}
        >
          Add New Configuration
        </Button>
      </Flex>
      
      <Modal
        closeOnOverlayClick={false}
        isOpen={controller.showConfigure}
        onClose={() => controller.setShowConfigure(false)}
        scrollBehavior="inside"
        isCentered
        size="6xl"
      >
        <ModalOverlay />
        <ModalContent bg="var(--bg)">
          <ModalHeader>Configure Auto Sync</ModalHeader>
          <ModalCloseButton size="sm" />
          <ModalBody>
            <AddOrEditAutoSyncConfiguration
              settings={controller.selectedSettings}
              close={() => controller.setShowConfigure(false)}
            />
          </ModalBody>
          <Box h="10px" />
        </ModalContent>
      </Modal>
    </Flex>
  );
};
