import React, { useEffect } from "react";
import {
  categorizeCashflows,
  getCashflowsWithoutCategory,
} from "../../../api/Cashflow";
import { Box, Button, Divider, List, ListItem, Text } from "@chakra-ui/react";

export default function CashflowsAdmin() {
  const [cashflows, setCashflows] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  useEffect(() => {
    // fetch cashflows
    fetchCashflows();
  }, []);

  const fetchCashflows = async () => {
    setLoading(true);
    const cashflows = await getCashflowsWithoutCategory();
    setCashflows(cashflows.data);
    setLoading(false);
  };

  const applyCategorization = async () => {
    setLoading(true);
    const response = await categorizeCashflows();
    fetchCashflows();
  };

  return (
    <Box p="4">
      <Text mb="10px">
        This is a list of distinct cashflow types without a category. Clicking
        apply categorization will auto apply a new category to them based on the
        regex in the code.
      </Text>
      <Button
        isLoading={loading}
        onClick={() => {
          if (
            window.confirm("Are you sure you want to apply categorization?")
          ) {
            applyCategorization();
          }
        }}
      >
        Apply Categorization
      </Button>
      <Divider mt="20px" />

      {loading && <Text>Loading...</Text>}
      {!loading && (
        <List mt="20px" spacing={3}>
          {cashflows.map((cashflow) => (
            <ListItem key={cashflow.id} border={"1px solid #ccc"} p="4">
              <Text>Description: {cashflow.description}</Text>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
}
