import { PositionStateAtom } from "../../store/PositionsStateAtom";
import { useAtomValue } from "jotai";
import { Position } from "../../types/Position";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { GetRowIdParams } from "ag-grid-community";
import {
  overlayLoadingTemplate,
  overlayNoRowsTemplate,
} from "../../components/grid/NoRowsToShow";
import {
  getAllChildrenOfGroup,
  getAllChildrenOfOverNode,
} from "../../components";
import { AccountAtom, TransactionsAtom } from "../../store";
import { useTransactions } from "../../hooks/useTransactions";
import mixpanel from "mixpanel-browser";
import { columnDefinitions } from "./columnDefinitions";
import { useNotifications } from "../../hooks/useNotifications";
import { useFilters } from "../../hooks/useFilters";
import { FilterStateAtom } from "../../store/FilterStateAtom";
import { PositionGroupingOptions, PositionStatus } from "../../types";
import { getCashflowIds } from "../cashflows/CashflowsHelper";
import { useFilterHelper } from "../../hooks";

// Define a custom interface for row drag end params that includes the overNode property
interface RowDragEndParams extends GetRowIdParams {
  overNode: {
    group?: boolean;
    field?: string;
    data?: any;
  };
}

interface UsePositionController {
  positions: Position[];

  getTradeAndCashflowIds: (rowIds: number[]) => {
    tradeIds: number[];
    cashflowIds: number[];
  };
  getRowId: (params: GetRowIdParams) => string;

  loadingOverlayComponent: any;
  loadingOverlayComponentParams: any;
  noRowsOverlayComponent: any;
  noRowsOverlayComponentParams: any;

  onRowDragMove: (params: GetRowIdParams) => void;

  onRowDragEnd: (
    params: any,
    openBookSearchModal?: (params: any) => void,
    openPositionGroupSearchModal?: (params: any) => void
  ) => void;
  selectGrouping: (grouping: PositionGroupingOptions) => void;
  grouping: PositionGroupingOptions | string;
  filterByTag: (tag: string) => void;
  switchingColDef: boolean;
  columnDefs: any;
  selectedTag?: string;
  setSelectedTag: (tag: string | undefined) => void;
  gridApiRef: any;
  transactions: any;
}

export const usePositionController = (): UsePositionController => {
  const transactions = useAtomValue(TransactionsAtom);
  const useTransactionsHook = useTransactions();
  const filters = useAtomValue(FilterStateAtom);
  const accountState = useAtomValue(AccountAtom);
  const notifications = useNotifications();
  const [columnDefs, setColumnDefs] = useState<any>(columnDefinitions);
  const [switchingColDef, setSwitchingColDef] = useState<boolean>(false);
  const [selectedTag, setSelectedTag] = useState<string | undefined>(undefined);
  const positionState = useAtomValue(PositionStateAtom);
  const gridApiRef = useRef<any>(null);
  const [grouping, setGrouping] = useState("B/U/P" as PositionGroupingOptions);
  const filterHelper = useFilterHelper();

  const reconfigureDefaultColumnDefs = () => {
    columnDefinitions.map((col: any) => {
      if (
        col.field === "realisedPnl" ||
        col.field === "unrealisedPnl" ||
        col.field === "unRealisedPnL" ||
        col.field === "totalPnl" ||
        col.field === "priceData.price" ||
        col.field === "marketValue"
      ) {
        col.hide = false;
      } else {
        col.hide = true;
      }
    });

    setColumnDefs([...columnDefinitions]);
  };

  useEffect(() => {
    const storedGrouping = localStorage.getItem("positionGrouping");
    if (storedGrouping) {
      selectGrouping(storedGrouping as PositionGroupingOptions);
    } else {
      selectGrouping(grouping);
    }
  }, [grouping]);

  const selectGrouping = (groupingValue: PositionGroupingOptions) => {
    localStorage.setItem("positionGrouping", groupingValue);

    reconfigureDefaultColumnDefs();

    mixpanel.track("Position Grouping Changed", {
      groupingValue,
    });

    setGrouping(groupingValue);

    const newColumnDefs = [...columnDefinitions];

    const underlyingColumn = newColumnDefs.find(
      (col: any) => col.field === "underlyingName"
    );
    const bookColumn = newColumnDefs.find((col: any) => col.field === "book");
    const positionNameColumn = newColumnDefs.find(
      (col: any) => col.field === "positionName"
    );

    if (underlyingColumn && bookColumn && positionNameColumn) {
      newColumnDefs.splice(newColumnDefs.indexOf(underlyingColumn), 1);
      newColumnDefs.splice(newColumnDefs.indexOf(bookColumn), 1);
      newColumnDefs.splice(newColumnDefs.indexOf(positionNameColumn), 1);

      setSwitchingColDef(true);

      let reorderedColumns = [];
      switch (groupingValue) {
        case PositionGroupingOptions.UBP:
          reorderedColumns = [underlyingColumn, bookColumn, positionNameColumn];
          break;
        case PositionGroupingOptions.BUP:
          reorderedColumns = [bookColumn, underlyingColumn, positionNameColumn];
          break;
        case PositionGroupingOptions.BPU:
          reorderedColumns = [bookColumn, positionNameColumn, underlyingColumn];
          break;
        case PositionGroupingOptions.UNDERLYING:
          reorderedColumns = [underlyingColumn];
          break;
        case PositionGroupingOptions.NONE:
          newColumnDefs.forEach((col) => {
            col.rowGroup = false;
            col.hide = false;
            col.rowDrag = false;
            if (col.field === "instrument.symbol") {
              col.headerName = "Symbol";
              col.minWidth = 400;
            }
            if (col.field === "lastTradeDate") {
              col.sort = "asc";
            }
          });

          reorderedColumns = newColumnDefs; // No grouping case
          break;
        default:
          reorderedColumns = newColumnDefs; // Fallback
      }

      // Ensure the column definition update only happens once

      setColumnDefs([
        ...newColumnDefs,
        ...reorderedColumns.filter(
          (col) => !newColumnDefs.find((x) => x.field === col.field)
        ),
      ]);

      setTimeout(() => {
        setSwitchingColDef(false);
      }, 200);
    }
  };

  const filterByTag = (tag: string) => {
    mixpanel.track("Position Tag Filter Changed", {
      tag,
    });
  };

  const filtersHook = useFilters();

  useEffect(() => {
    if (localStorage.getItem("positionStatus")) {
      filtersHook.setPositionStatus(
        localStorage.getItem("positionStatus") as string
      );
    } else {
      filtersHook.setPositionStatus("ACTIVE");
    }
  }, [filtersHook.positionStatus]);

  const positions: Position[] = useMemo(() => {
    return positionState.positions;
  }, [positionState.positions]);

  useEffect(() => {
    reconfigureDefaultColumnDefs();
    if (accountState?.selectedAccount?.id) {
      useTransactionsHook.getTradesAndCashflows(
        accountState?.selectedAccount?.id
      );
    }
    // else {
    //   window.location.reload();
    // }
  }, []);

  const getTradeAndCashflowIds = (
    rowIds: number[]
  ): { tradeIds: number[]; cashflowIds: number[] } => {
    if (positions.length > 0) {
      let tradeIds: number[] = [];
      let cashflowIds: number[] = [];
      rowIds.forEach((rowId) => {
        const position = positions.find((x) => x.id === rowId);
        if (position) {
          tradeIds = [...tradeIds, ...position.tradeIds];
          cashflowIds = [...cashflowIds, ...position.cashflowIds];
        }
      });
      return { tradeIds: tradeIds ?? [], cashflowIds: cashflowIds };
    }
    return { tradeIds: [], cashflowIds: [] };
  };

  const getRowId = useCallback(function (params: GetRowIdParams) {
    return params.data.id;
  }, []);

  const loadingOverlayComponent = useMemo(() => {
    return overlayLoadingTemplate;
  }, []);

  const loadingOverlayComponentParams = useMemo(() => {
    return {
      loadingMessage: "Retrieving positions...",
    };
  }, []);

  const noRowsOverlayComponent = useMemo(() => {
    return overlayNoRowsTemplate;
  }, []);

  const noRowsOverlayComponentParams = useMemo(() => {
    return {
      noRowsMessageFunc: () =>
        "No positions found! Please add them using the 'Add assets' button in the top right.",
    };
  }, []);

  const onRowDragMove = (params: GetRowIdParams) => {
    // console.log("onRowDragEnd", params);
  };

  const onRowDragEnd = async (
    params: any,
    openBookSearchModal?: (params: any) => void,
    openPositionGroupSearchModal?: (params: any) => void
  ) => {
    mixpanel.track("Position Dragged");

    // Cast params to our custom interface that includes overNode
    const dragParams = params as RowDragEndParams;

    const nodesData = getAllChildrenOfGroup(
      params,
      transactions as unknown as any[]
    );
    // getCashflowIds
    const overNodesData = getAllChildrenOfOverNode(
      params,
      transactions as unknown as any[]
    );

    // if (nodesData.length > 1) {
    //   if (
    //     window.confirm(`Are you sure you want to move multiple trades? `) ===
    //     false
    //   ) {
    //     return;
    //   }
    // }

    const cashflowIds = getCashflowIds(params as any);

    // Check if we're dropping on a book or position group header
    if (
      dragParams.overNode?.group &&
      dragParams.overNode.field === "book" &&
      openBookSearchModal
    ) {
      // If dropping on a book header, open the book search modal
      openBookSearchModal(params);
      return;
    } else if (
      dragParams.overNode?.group &&
      (dragParams.overNode.field === "positionGroup" ||
        dragParams.overNode.field === "positionName") &&
      openPositionGroupSearchModal
    ) {
      // If dropping on a position group header, open the position group search modal
      openPositionGroupSearchModal(params);
      return;
    }

    // Otherwise, proceed with the original behavior
    const book = !dragParams.overNode?.group
      ? dragParams.overNode?.data?.book
      : overNodesData[0].book;

    const positionGroup = !dragParams.overNode?.group
      ? dragParams.overNode?.data?.positionGroup
      : (overNodesData[0].positionName ?? overNodesData[0].positionGroup);

    if (
      !dragParams.overNode?.group ||
      dragParams.overNode.field === "positionGroup" ||
      dragParams.overNode.field === "positionName" ||
      dragParams.overNode.field === "book"
    ) {
      useTransactionsHook.updateBookAndGroup(
        [...nodesData],
        book,
        positionGroup,
        accountState?.selectedAccount?.id,
        cashflowIds
      );
    }

    return;
  };

  const filteredPositions = useMemo(() => {
    let result = [...positions];

    // 1) Apply status filters (if any)
    switch (filters.status) {
      case PositionStatus.ACTIVE:
        result = result.filter((position) =>
          positionState.openPositions.includes(position.id)
        );
        break;

      case PositionStatus.OPEN:
        result = result.filter((position) => position.quantity !== 0);
        break;

      case PositionStatus.CLOSED:
        result = result.filter((position) => position.quantity === 0);
        break;
    }

    // 2) If no filter is applied at all, return now or apply the next set
    if (!filterHelper.filterEnabled) {
      return result;
    }

    // 3) Apply the remaining filters
    result = result.filter((position: Position) => {
      try {
        let underlyingMatch = true;
        if (
          filterHelper.filteredUnderlying &&
          filterHelper.filteredUnderlying !== "All"
        ) {
          underlyingMatch =
            (position.underlyingSymbol ?? "") ===
              filterHelper.filteredUnderlying ||
            (position.underlyingName ?? "") === filterHelper.filteredUnderlying;
        }

        let tagsMatch = true;
        if (filterHelper.filteredTags && filterHelper.filteredTags !== "All") {
          tagsMatch =
            (!Array.isArray(position.tags) &&
              position.tags === filterHelper.filteredTags) ||
            (Array.isArray(position.tags) &&
              position.tags.includes(filterHelper.filteredTags));
        }

        let positionGroupMatch = true;
        if (
          filterHelper.filteredPositionGroup &&
          filterHelper.filteredPositionGroup !== "All"
        ) {
          positionGroupMatch =
            (position.positionGroup ?? "") ===
            filterHelper.filteredPositionGroup;
        }

        let booksMatch = true;
        if (filterHelper.filteredBook && filterHelper.filteredBook !== "All") {
          booksMatch = (position.book ?? "") === filterHelper.filteredBook;
        }

        let strategiesMatch = true;
        if (
          filterHelper.filteredStrategy &&
          filterHelper.filteredStrategy !== "All"
        ) {
          strategiesMatch =
            (position.strategy ?? "") === filterHelper.filteredStrategy;
        }

        return (
          underlyingMatch &&
          tagsMatch &&
          positionGroupMatch &&
          booksMatch &&
          strategiesMatch
        );
      } catch (error) {
        console.error("Error filtering position:", error);
        return false; // Exclude positions that cause errors from results
      }
    });

    return result;
  }, [positions, filterHelper, positionState]);

  return {
    positions: filteredPositions,
    selectedTag,
    setSelectedTag,
    getTradeAndCashflowIds,
    getRowId,
    loadingOverlayComponent,
    loadingOverlayComponentParams,
    noRowsOverlayComponent,
    noRowsOverlayComponentParams,
    onRowDragMove,
    onRowDragEnd,
    selectGrouping,
    grouping,
    filterByTag,
    switchingColDef,
    columnDefs,
    gridApiRef,
    transactions,
  };
};
