import { useAtom } from "jotai";
import { AccountAtom } from "../store";
import { Account } from "../types/Account";
import { getAccounts } from "../api";
import { useCallback } from "react";

export interface UseAccount {
  setSelectedAccount: (account: Account) => void;
  retrieveAccounts: (force: boolean) => void;
  setDefaultDashboardConfiguration: (id: number) => void;
  setAccountName: (name: string) => void;
}

export const useAccount = (): UseAccount => {
  const [accountState, setAccountState] = useAtom(AccountAtom);

  const setSelectedAccount = (account: Account) => {
    console.log("Setting selected account", account);
    localStorage.setItem("selectedAccount", JSON.stringify(account));
    setAccountState((prevState) => {
      return {
        ...prevState,
        selectedAccount: account,
      };
    });
  };

  const setDefaultDashboardConfiguration = (id: number) => {
    setAccountState((prevState) => {
      return {
        ...prevState,
        selectedAccount: {
          ...prevState.selectedAccount,
          defaultDashboardConfigurationId: id,
        },
      };
    });
  };

  const setAccountName = (name: string) => {
    setAccountState((prevState) => {
      return {
        ...prevState,
        selectedAccount: {
          ...prevState.selectedAccount,
          name: name,
        },
      };
    });
  };

  // WARNING - Only use force when neccessary as it will cause a re-render of anything that depends on the account state
  const retrieveAccounts = useCallback(
    async (force: boolean = false) => {
      const accounts = await getAccounts();
      if (accounts.length > 0) {
        let selectedAccount = localStorage.getItem("selectedAccount");
        selectedAccount = selectedAccount
          ? JSON.parse(selectedAccount)
          : undefined;

        // ensure that we don't always replace the account state if its unchanged as so much hanges off it and it will fire api calls
        // only replace state if the selected account has changed or the accounts array has changed
        if (
          force ||
          (selectedAccount as unknown as Account)?.id !==
            accountState.selectedAccount?.id ||
          accounts.length !== accountState.accounts.length
        ) {
          setAccountState((prevState) => {
            // ensure the cached account is in the list of accounts else choose the first account
            const accountToSelect = selectedAccount
              ? accounts.find(
                  (account) =>
                    account.id === (selectedAccount as unknown as Account)?.id
                )
              : accounts[0];

            return {
              ...prevState,
              accounts: accounts,
              selectedAccount: accountToSelect,
            };
          });
        } else if (selectedAccount === undefined) {
          setAccountState((prevState) => {
            return {
              ...prevState,
              selectedAccount: accounts[0],
            };
          });
        }
      } else {
        setTimeout(() => {
          console.log("Retrying accounts...");
          retrieveAccounts(false);
        }, 1500);
      }
    },
    [accountState, setAccountState]
  );

  return {
    setSelectedAccount,
    retrieveAccounts,
    setAccountName,
    setDefaultDashboardConfiguration,
  };
};
