import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Container,
  Heading,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  TableContainer,
  Spinner,
  Text,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import currencyFormatter from "currency-formatter";
import { getAdminAnalysis, getLastTradesByAccountId } from "../../../api/Admin";
import { useInstruments } from "../../../hooks";

export default function Analysis() {
  const [accounts, setAccounts] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const instruments = useInstruments();

  // States for modal
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedAccountId, setSelectedAccountId] = useState<number | null>(
    null
  );
  const [trades, setTrades] = useState<any[]>([]);
  const [tradesLoading, setTradesLoading] = useState<boolean>(false);

  const fetchAnalysis = async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await getAdminAnalysis();
      // Sort accounts by highest totalpnl (descending)
      const sortedAccounts = (data.accounts || []).sort(
        (a: any, b: any) => parseFloat(b.totalpnl) - parseFloat(a.totalpnl)
      );
      setAccounts(sortedAccounts);
    } catch (err: any) {
      setError(err.message || "Error fetching analysis data.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch top accounts on mount
  useEffect(() => {
    fetchAnalysis();
  }, []);

  // Handle opening the modal: fetch the last 10 trades for that account
  const handleViewLastTrades = async (accountId: number) => {
    setSelectedAccountId(accountId);
    setTrades([]);
    setTradesLoading(true);
    onOpen(); // Open the modal
    try {
      const lastTrades = await getLastTradesByAccountId(accountId);
      setTrades(lastTrades.data);
    } catch (err) {
      console.error("Error fetching trades:", err);
    } finally {
      setTradesLoading(false);
    }
  };

  // Render loading state
  if (loading) {
    return (
      <Container maxW="container.xl" py={8}>
        <Spinner size="xl" />
        <Text mt={4}>Loading top-performing accounts...</Text>
      </Container>
    );
  }

  // Render error state
  if (error) {
    return (
      <Container maxW="container.xl" py={8}>
        <Text color="red.500" fontWeight="bold">
          {error}
        </Text>
      </Container>
    );
  }

  return (
    <Container maxW="container.xl" py={8}>
      <Heading mb={6}>Highest Performing Accounts (Past Month)</Heading>
      <TableContainer>
        <Table variant="simple">
          <TableCaption placement="bottom">
            Showing only accounts with positive PnL over the past month
          </TableCaption>
          <Thead>
            <Tr>
              <Th>Account ID</Th>
              <Th>Account Name</Th>
              <Th>Email</Th>
              <Th isNumeric>Total PnL</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {accounts.map((account) => (
              <Tr key={account.id}>
                <Td>{account.id}</Td>
                <Td>{account.name}</Td>
                <Td>{account.email}</Td>
                <Td isNumeric>
                  {currencyFormatter.format(account.totalpnl, {
                    code: "USD",
                    precision: 2,
                  })}
                </Td>
                <Td>
                  <Button
                    colorScheme="blue"
                    onClick={() => handleViewLastTrades(account.id)}
                  >
                    View Last 10 Trades
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      {/* Modal for displaying last 10 trades */}
      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Last 10 Trades for Account {selectedAccountId ?? ""}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {tradesLoading ? (
              <Spinner size="lg" />
            ) : (
              <>
                {trades.length === 0 ? (
                  <Text>No trades found.</Text>
                ) : (
                  <Table>
                    <Thead>
                      <Tr>
                        <Th>Instrument</Th>
                        <Th>Quantity</Th>
                        <Th>Price</Th>
                        <Th>Trade Date</Th>
                      </Tr>
                    </Thead>
                    {trades.map((x) => {
                      const inst = instruments.list.find(
                        (y) => y.id === x.instrumentId
                      );

                      return (
                        <Tr>
                          <Td>{inst?.name}</Td>
                          <Td>{x.quantity}</Td>
                          <Td>{x.price}</Td>
                          <Td>{moment(x.tradeDate).calendar()}</Td>
                        </Tr>
                      );
                    })}
                  </Table>
                )}
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  );
}
