import { useRef, useState, KeyboardEvent } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  Box,
  Text,
  VStack,
  Divider,
  HStack,
  Avatar,
  Spinner,
  useDisclosure,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import React from "react";
import { useAtomValue } from "jotai";
import { AccountAtom, SidebarStateAtom } from "../../store";
import useAccountController from "./useAccountController";
import { IoRadioButtonOff, IoRadioButtonOn } from "react-icons/io5";
import { MangageAccounts } from "../ManageAccounts/ManageAccounts";
import { Show } from "../misc";
import { Account } from "../../types/Account";

interface AccountSelectorProps {
  className?: string;
}

export default function AccountSelector({
  className = "tutorial-account-manager",
}: AccountSelectorProps) {
  const controller = useAccountController();
  const isCollapsed = useAtomValue(SidebarStateAtom);
  const accountState = useAtomValue(AccountAtom);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [focusIndex, setFocusIndex] = useState(0);
  const accountButtonsRef = useRef<Array<HTMLButtonElement | null>>([]);

  const filteredAccounts = accountState.accounts.filter((account) =>
    account.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleAccountSelection = (account: Account) => {
    setIsLoading(true);
    Promise.resolve(controller.setSelectedAccount(account))
      .then(() => {
        setIsLoading(false);
        onClose();
        setSearchQuery("");
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleKeyDown = (
    e: KeyboardEvent<HTMLButtonElement | HTMLInputElement>,
    index?: number
  ) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      const nextIndex =
        typeof index === "number" ? (index + 1) % filteredAccounts.length : 0;
      setFocusIndex(nextIndex);
      accountButtonsRef.current[nextIndex]?.focus();
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      const prevIndex =
        typeof index === "number"
          ? (index - 1 + filteredAccounts.length) % filteredAccounts.length
          : filteredAccounts.length - 1;
      setFocusIndex(prevIndex);
      accountButtonsRef.current[prevIndex]?.focus();
    }
  };

  return (
    <>
      <Button
        className={className}
        bg={"var(--dark-gray)"}
        onClick={onOpen}
        width="240px"
        justifyContent="space-between"
        height="60px"
        px="8px"
        mt="2px"
        position="relative"
      >
        <HStack>
          <Box
            bg="var(--gray)"
            borderRadius={"6px"}
            minWidth="46px"
            height="46px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
          >
            {isLoading ? (
              <Spinner size="md" color="var(--white)" />
            ) : (
              <Text fontSize="2xl">
                {accountState?.selectedAccount?.name?.[0]?.toUpperCase() || "?"}
              </Text>
            )}
          </Box>
          {isCollapsed ? null : (
            <VStack w="full" alignItems={"flex-start"} pl="4px">
              <Text fontSize="xs" fontWeight={400}>
                Selected Portfolio
              </Text>
              <Text
                maxW="140px"
                overflow={"hidden"}
                textOverflow={"ellipsis"}
                whiteSpace="nowrap"
              >
                {accountState?.selectedAccount?.name || "Select a portfolio"}
              </Text>
            </VStack>
          )}
        </HStack>
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom">
        <ModalOverlay backdropFilter="blur(4px)" />
        <ModalContent
          bg="var(--bg)"
          maxW="500px"
          mt="120px"
          boxShadow="xl"
          borderRadius="xl"
        >
          <VStack align="stretch" p={4} spacing={4}>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.500" />
              </InputLeftElement>
              <Input
                placeholder="Search portfolios..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={handleKeyDown}
                autoFocus
                border="none"
                fontSize="lg"
                _focus={{
                  boxShadow: "none",
                  borderColor: "transparent",
                }}
              />
            </InputGroup>

            <Box maxH="400px" overflowY="auto">
              {filteredAccounts.map((account, index) => (
                <React.Fragment key={account.id}>
                  <Button
                    p="3"
                    variant="ghost"
                    justifyContent="space-between"
                    onClick={() => handleAccountSelection(account)}
                    width="full"
                    mb="2"
                    bg={"var(--bg)"}
                    overflow={"hidden"}
                    role="option"
                    aria-selected={
                      account.id === accountState.selectedAccount?.id
                    }
                    ref={(el) => {
                      if (accountButtonsRef.current) {
                        accountButtonsRef.current[index] = el;
                      }
                    }}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    _hover={{ bg: "var(--gray)" }}
                    _focus={{
                      boxShadow: "0 0 0 2px var(--white)",
                      bg: "var(--gray)",
                    }}
                    transition="all 0.2s"
                  >
                    <HStack>
                      <Avatar
                        bg={"var(--gray)"}
                        name={account.name}
                        size={"sm"}
                        borderRadius={6}
                        color={"var(--white)"}
                      />
                      <Text
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                        maxW="300px"
                        whiteSpace="nowrap"
                      >
                        {account.name}
                      </Text>
                    </HStack>

                    <Show if={account.id === accountState.selectedAccount?.id}>
                      <IoRadioButtonOn
                        color="var(--white)"
                        aria-hidden="true"
                      />
                    </Show>
                    <Show if={account.id !== accountState.selectedAccount?.id}>
                      <IoRadioButtonOff
                        color="var(--white)"
                        aria-hidden="true"
                      />
                    </Show>
                  </Button>
                  <Divider mb="2" />
                </React.Fragment>
              ))}
            </Box>

            <MangageAccounts />
          </VStack>
        </ModalContent>
      </Modal>
    </>
  );
}
