import React, { useEffect, useRef } from "react";
import { Stack } from "@chakra-ui/react";
import { CreatableSelect } from "chakra-react-select";
import { Trade } from "../../types";
import { useAtomValue, useSetAtom } from "jotai";
import { AccountAtom, TransactionsAtom } from "../../store";
import { useTransactions } from "../../hooks/useTransactions";

let getTagsLastCallString = "";

export const TagEntry = (props: { trade: Trade }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const transactionState = useAtomValue(TransactionsAtom);
  const setTransactionsState = useSetAtom(TransactionsAtom);
  const accountState = useAtomValue(AccountAtom);
  const [options, setOptions] = React.useState(transactionState.tags);
  const initialised = useRef<boolean>(false);
  const transactions = useTransactions();

  // Update options when transactionState.tags changes
  useEffect(() => {
    setOptions(transactionState.tags);
  }, [transactionState.tags]);

  useEffect(() => {
    if (accountState?.selectedAccount?.id) {
      // DIRTY HACK - This avoids repeatitive calls to getTags which leads to getTrades being called multiple times which is heavy
      if (
        getTagsLastCallString !== `getTags-${accountState.selectedAccount!.id}`
      ) {
        getTagsLastCallString = `getTags-${accountState.selectedAccount!.id}`;
        // Fetch tags when component mounts
        transactions.getTags(accountState.selectedAccount!.id);
      }
    }
  }, [accountState?.selectedAccount?.id, transactions]);

  const onChange = (value: any) => {
    if (!value) return;

    const tags = value.map((item: any) => item.value);

    // Update the trade with the new tags
    transactions.setTags(
      tags,
      [props.trade],
      accountState?.selectedAccount?.id
    );

    // Update the global tags list with any new tags
    const newTagsSet = new Set([...transactionState.tags, ...tags]);
    const newTags = Array.from(newTagsSet);
    if (newTags.length !== transactionState.tags.length) {
      setTransactionsState((prevState) => ({
        ...prevState,
        tags: newTags,
      }));
    }
  };

  return (
    <Stack spacing="4" mt="2" mb="1">
      <CreatableSelect
        tagVariant="solid"
        value={
          props?.trade?.tags &&
          props?.trade?.tags?.map((item) => {
            if (item === "") return undefined;
            return { value: item, label: item };
          })
        }
        placeholder="Add Tags"
        isMulti={true}
        options={
          options &&
          options.map((item) => {
            return { value: item, label: item };
          })
        }
        onChange={(e) => onChange(e)}
      />
    </Stack>
  );
};
