import React, { useEffect, useMemo, useState } from "react";
import {
  Flex,
  HStack,
  Popover,
  PopoverBody,
  PopoverTrigger,
  Text,
  VStack,
  PopoverContent,
  PopoverArrow,
  Box,
} from "@chakra-ui/react";
import { KeyValuePair } from "../../types";
import DropDownButton from "../design_library/DropDownButton";
import { SecondaryButton } from "../design_library";
import moment from "moment";
import { Show } from "../misc";

const fixedDateRanges: KeyValuePair[] = [
  { key: "today", value: "Today Only" },
  { key: "wtd", value: "WTD" },
  { key: "mtd", value: "MTD" },
  { key: "ytd", value: "YTD" },
  { key: "1m", value: "Last 30 Days" },
  { key: "3m", value: "Last 90 Days" },
  { key: "6m", value: "Last 180 Days" },
  { key: "1y", value: "Last 365 Days" },
  { key: "all", value: "From Start" },
];

interface FixedDateRangeSelectorProps {
  onDateRangeSelected: (item: KeyValuePair) => void;
  storageKey: string;
  defaultValue?: string;
}

export const determineDateRange = (
  selectedDateRange?: string
): {
  start: string | undefined;
  end: string | undefined;
} => {
  let start: string | undefined = undefined;
  let end: string | undefined = undefined;

  let selectedPeriod = selectedDateRange ?? "today";

  switch (selectedPeriod) {
    case "today":
      start = moment().startOf("day").toDate().toISOString();
      end = moment().endOf("day").toDate().toISOString();
      break;
    case "wtd":
      start = moment().startOf("week").toDate().toISOString();
      end = moment().endOf("day").toDate().toISOString();
      break;
    case "mtd":
      start = moment().startOf("month").toDate().toISOString();
      end = moment().endOf("day").toDate().toISOString();
      break;
    case "ytd":
      start = moment().startOf("year").toDate().toISOString();
      end = moment().endOf("day").toDate().toISOString();
      break;
    case "1m":
      start = moment().add(-30, "days").toDate().toISOString();
      end = moment().endOf("day").toDate().toISOString();
      break;
    case "3m":
      start = moment().add(-90, "days").toDate().toISOString();
      end = moment().endOf("day").toDate().toISOString();
      break;
    case "6m":
      start = moment().add(-180, "days").toDate().toISOString();
      end = moment().endOf("day").toDate().toISOString();
      break;
    case "1y":
      start = moment().add(-365, "days").toDate().toISOString();
      end = moment().endOf("day").toDate().toISOString();
      break;
    case "all":
      start = new Date(2000, 1, 1).toISOString();
      end = moment().endOf("day").toDate().toISOString();
      break;
  }

  return { start, end };
};

export const FixedDateRangeSelector = (props: FixedDateRangeSelectorProps) => {
  const localStorageKey = useMemo(
    () => `FixedDateRangeSelector.${props.storageKey ?? "default"}`,
    [props.storageKey]
  );

  const [expanded, setExpanded] = React.useState(false);

  const [selectedDateRange, setSelectedDateRange] = useState<KeyValuePair>();

  const onDateRangeSelected = (item: KeyValuePair) => {
    setSelectedDateRange(item);
    localStorage.setItem(localStorageKey, item.key);
    setTimeout(() => {
      props.onDateRangeSelected(item);
    }, 10);
  };

  useEffect(() => {
    const initialValue =
      localStorage.getItem(localStorageKey) ?? props.defaultValue;
    onDateRangeSelected(
      fixedDateRanges.find((item) => item.key === initialValue) ??
        fixedDateRanges[0]
    );
  }, []);

  return (
    <Popover
      isOpen={expanded}
      onClose={() => setExpanded(false)}
      closeOnBlur={true}
      placement="bottom-start"
      arrowSize={15}
    >
      <PopoverTrigger>
        <Flex>
          <DropDownButton
            aria-expanded={expanded}
            onClick={() => setExpanded(!expanded)}
          >
            <HStack cursor="pointer" minWidth="60px" overflow={"visible"}>
              <Text>{selectedDateRange?.value}</Text>
            </HStack>
          </DropDownButton>
        </Flex>
      </PopoverTrigger>
      <PopoverContent p={0} mt="-4px" w="300px" bg="var(--bg)">
        <PopoverArrow bg="var(--bg)" />
        <PopoverBody py={2} m={0} w="full">
          <VStack align="stretch" p={0} spacing={2}>
            {fixedDateRanges.map((item, index) => {
              const { start, end } = determineDateRange(item.key);
              return (
                <SecondaryButton
                  size="xs"
                  key={item.key}
                  variant={
                    selectedDateRange?.key === item.key ? "solid" : "outline"
                  }
                  bg={
                    selectedDateRange?.key === item.key
                      ? "var(--accent)"
                      : "var(--bg)"
                  }
                  border="0"
                  justifyContent="space-between"
                  onClick={(e) => {
                    e.preventDefault();
                    onDateRangeSelected(item);
                    setExpanded(false);
                  }}
                >
                  <Flex w="full" justifyContent="space-between" align="center">
                    <Box>{item.value}</Box>
                    <Box>
                      <Show if={start !== undefined && end !== undefined}>
                        <Text fontSize="12px" fontWeight={400}>
                          {moment.utc(start).format("Do MMM YY")} -{" "}
                          {moment.utc(end).format("Do MMM YY")}
                        </Text>
                      </Show>
                    </Box>
                  </Flex>
                </SecondaryButton>
              );
            })}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
