import React, { useMemo } from "react";
import {
  Flex,
  VStack,
  Text,
  HStack,
  Spacer,
  Tag,
  Divider,
  Skeleton,
  FormControl,
  FormLabel,
  Box,
  Heading,
} from "@chakra-ui/react";
import { IoExpand } from "react-icons/io5";
import { CreatableSelect, Select } from "chakra-react-select";
import StarterKit from "@tiptap/starter-kit";
import { EditorProvider } from "@tiptap/react";
import { CustomTag, SecondaryButton } from "../../components/design_library";
import { Show, Spinner } from "../../components";
import { MenuBar } from "../../components/texteditor/MenuBar";
import { ExplainCashflowProps, useExplainCashflow } from "./useExplainCashflow";
import { CashflowCategories } from "../../types";
import { ExplainAlert } from "./ExplainAlert";

export const ExplainCashflow = (props: ExplainCashflowProps) => {
  const [showAlert, setShowAlert] = React.useState(false);
  const controller = useExplainCashflow(props);

  const categoryOptions = useMemo(() => {
    return Object.entries(CashflowCategories).map((item) => {
      return { value: item[0], label: item[1] };
    });
  }, []);

  const bookOptions = useMemo(() => {
    return controller.books.map((item) => {
      return { value: item, label: item };
    });
  }, [controller.books]);

  const positionNames = useMemo(() => {
    return controller.positionGroups.map((item) => {
      return { value: item, label: item };
    });
  }, [controller.positionGroups]);

  const strategyOptions = useMemo(() => {
    return controller.strategies.map((item) => {
      return { value: item, label: item };
    });
  }, [controller.strategies]);

  const tagOptions = useMemo(() => {
    return controller.tags.map((item) => {
      return { value: item, label: item };
    });
  }, [controller.tags]);

  const extensions = [
    StarterKit.configure({
      heading: {
        levels: [1, 2, 3, 4, 5, 6],
      },
    }),
  ];

  const doSave = async (force: boolean = false) => {
    const dontWarn = localStorage.getItem(`explain-alert-Cashflow`);
    if (dontWarn || force) {
      await controller.saveChanges();
      setShowAlert(false);
    } else {
      setShowAlert(true);
    }
  };

  return (
    <>
      <ExplainAlert
        entityType="Cashflow"
        isOpen={showAlert}
        onClose={() => setShowAlert(false)}
        apply={() => doSave(true)}
      />
      <Flex w="full" p={2} background={"var(--bg)"}>
        <VStack alignItems="start" w="full">
          <HStack
            w="full"
            mt={1}
            justifyContent={"space-between"}
            alignItems={"flex-start"}
          >
            <VStack>
              <Heading
                fontSize="14px"
                fontWeight={500}
                w="full"
                textAlign="left"
              >
                Explain Cashflows
              </Heading>
              <Show if={props.cashflows?.length > 0}>
                <CustomTag colorScheme="orange" size="sm">
                  {controller.nbrCashflows}
                  {controller.nbrCashflows > 1
                    ? " cashflows"
                    : " cashflows"}{" "}
                  selected
                </CustomTag>
              </Show>
            </VStack>

            {controller.nbrCashflows !== 0 && (
              <SecondaryButton
                size="sm"
                colorScheme="blue"
                minW="120px"
                isLoading={controller.saving}
                onClick={() => doSave()}
              >
                <Text>Save changes</Text>
              </SecondaryButton>
            )}
          </HStack>

          {controller.nbrCashflows == 0 && (
            <VStack mt="20px">
              <IoExpand size={50} />
              <Show if={props.cashflows?.length === 0}>
                <Tag size="sm" colorScheme="red" ml={4}>
                  SELECT ONE OR MORE CASHFLOWS
                </Tag>
              </Show>
              <Text textAlign={"center"} px="10px">
                Use the checkboxes to the left to select cashflows, you can then
                'explain them' by assigning strategies, books, position names,
                tags, and notes.
              </Text>
            </VStack>
          )}
          <Skeleton
            overflow={controller.nbrCashflows ? "auto" : "hidden"}
            isLoaded={controller.nbrCashflows > 0}
            w="full"
            h="full"
            fadeDuration={0}
            speed={0}
            startColor="var(--bg)"
            overflowX={"hidden"}
            p="10px"
          >
            <Divider mt={1} />

            <FormControl mt="30px">
              <HStack my={1}>
                <FormLabel>Category</FormLabel>
              </HStack>

              <Flex direction="row" w="full">
                <Box flex={1}>
                  <Select
                    size="sm"
                    tagVariant="solid"
                    value={categoryOptions.find(
                      (x) =>
                        controller.selectedCategory &&
                        x.value === controller.selectedCategory
                    )}
                    options={categoryOptions}
                    onChange={(e) => {
                      if (e?.value) {
                        controller.setSelectedCategory(
                          e.value as unknown as CashflowCategories
                        );
                      } else {
                        controller.setSelectedCategory(undefined);
                      }
                    }}
                    isClearable={true}
                  />
                </Box>
                <Flex ml={4}>
                  <SecondaryButton
                    size="sm"
                    colorScheme="blue"
                    isLoading={controller.saving}
                    onClick={() => {
                      controller.applyField("category");
                    }}
                  >
                    <Text>Apply</Text>
                  </SecondaryButton>
                </Flex>
              </Flex>
            </FormControl>

            <FormControl mt="30px">
              <HStack my={1}>
                <FormLabel>Book</FormLabel>
              </HStack>

              <Flex direction="row" w="full">
                <Box flex={1}>
                  <CreatableSelect
                    size="sm"
                    tagVariant="solid"
                    value={bookOptions.find(
                      (x) => x.value === controller.selectedBook
                    )}
                    options={bookOptions}
                    onChange={(e) => {
                      if (e?.value) {
                        controller.setSelectedBook(e.value);
                      } else {
                        controller.setSelectedBook(undefined);
                      }
                    }}
                    isClearable={true}
                  />
                </Box>
                <Flex ml={4}>
                  <SecondaryButton
                    size="sm"
                    colorScheme="blue"
                    isLoading={controller.saving}
                    onClick={() => {
                      controller.applyField("book");
                    }}
                  >
                    <Text>Apply</Text>
                  </SecondaryButton>
                </Flex>
              </Flex>
            </FormControl>

            <FormControl mt="30px">
              <HStack my={1}>
                <FormLabel>Position Name</FormLabel>
              </HStack>
              <Flex direction="row" w="full">
                <Box flex={1}>
                  <CreatableSelect
                    size="sm"
                    tagVariant="solid"
                    value={positionNames.find(
                      (x) => x.value === controller.selectedPositionGroup
                    )}
                    options={positionNames}
                    onChange={(e) => {
                      if (e?.value) {
                        controller.setSelectedPositionGroup(e.value);
                      } else {
                        controller.setSelectedPositionGroup(undefined);
                      }
                    }}
                    isClearable={true}
                  />
                </Box>
                <Flex ml={4}>
                  <SecondaryButton
                    size="sm"
                    colorScheme="blue"
                    isLoading={controller.saving}
                    onClick={() => {
                      controller.applyField("positionGroup");
                    }}
                  >
                    <Text>Apply</Text>
                  </SecondaryButton>
                </Flex>
              </Flex>
            </FormControl>

            <FormControl mt="30px">
              <HStack my={1}>
                <FormLabel>Strategy</FormLabel>
              </HStack>

              <Flex direction="row" w="full">
                <Box flex={1}>
                  <CreatableSelect
                    size="sm"
                    tagVariant="solid"
                    value={strategyOptions.find(
                      (x) => x.value === controller.selectedStrategy
                    )}
                    options={strategyOptions}
                    onChange={(e) => {
                      if (e?.value) {
                        controller.setSelectedStrategy(e.value);
                      } else {
                        controller.setSelectedStrategy(undefined);
                      }
                    }}
                    isClearable={true}
                  />
                </Box>
                <Flex ml={4}>
                  <SecondaryButton
                    size="sm"
                    colorScheme="blue"
                    isLoading={controller.saving}
                    onClick={() => {
                      controller.applyField("strategy");
                    }}
                  >
                    <Text>Apply</Text>
                  </SecondaryButton>
                </Flex>
              </Flex>
            </FormControl>

            <FormControl mt="30px">
              <HStack my={1}>
                <FormLabel>Tags</FormLabel>
              </HStack>

              <Flex direction="row" w="full">
                <Box flex={1}>
                  <CreatableSelect
                    size="sm"
                    tagVariant="solid"
                    value={controller.selectedTags.map((item) => {
                      return { value: item, label: item };
                    })}
                    placeholder="Specify Tags"
                    isMulti={true}
                    options={tagOptions}
                    onChange={(e) => {
                      const newtags = e.map((item: any) => item.value);
                      controller.setSelectedTags(newtags);
                    }}
                    isClearable={true}
                  />
                </Box>
                <Flex ml={4}>
                  <SecondaryButton
                    size="sm"
                    colorScheme="blue"
                    isLoading={controller.saving}
                    onClick={() => {
                      controller.applyField("tags");
                    }}
                  >
                    <Text>Apply</Text>
                  </SecondaryButton>
                </Flex>
              </Flex>
            </FormControl>

            {/* NOTES */}
            <FormControl mt="30px">
              <HStack my={1}>
                <FormLabel>Notes</FormLabel>
              </HStack>

              {!controller.loading && !controller.determiningState && (
                <EditorProvider
                  slotBefore={
                    <Box mb="20px">
                      <MenuBar />
                    </Box>
                  }
                  extensions={extensions}
                  content={`` + controller.notes}
                  onUpdate={(e) => {
                    controller.setNotes(e.editor.getHTML());
                  }}
                  editorProps={{
                    attributes: {
                      class: "prose",
                    },
                  }}
                ></EditorProvider>
              )}
              {controller.loading && (
                <VStack>
                  <Spinner />
                  <Text>Retrieving notes...</Text>
                </VStack>
              )}
              <HStack mt={2}>
                <Spacer />
                <SecondaryButton
                  size="sm"
                  colorScheme="blue"
                  isLoading={controller.saving}
                  onClick={() => {
                    controller.applyField("notes");
                  }}
                >
                  <Text>Apply</Text>
                </SecondaryButton>
              </HStack>
              <Spacer mt="160px" />
            </FormControl>
          </Skeleton>
        </VStack>
      </Flex>
    </>
  );
};
