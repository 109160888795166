import { useQuery } from "@tanstack/react-query";
import { CashFlowEx } from "../../../types/Cashflow";
import { AccountAtom } from "../../../store";
import { useAtomValue } from "jotai";
import { getCashFlowsByIds } from "../../../api/Cashflow";
import { useInstruments } from "../../../hooks";

interface UseCashflowViewController {
  cashflowIds: number[];
  rows: CashFlowEx[];
  loading: boolean;
}

export const useCashflowViewController = (
  cashflowIds: number[]
): UseCashflowViewController => {
  const accountState = useAtomValue(AccountAtom);
  const instruments = useInstruments();

  // uses react query to cache result
  const result = useQuery<CashFlowEx[]>({
    queryKey: ["cashflows", cashflowIds],
    queryFn: async () => {
      const cashflows = await getCashFlowsByIds(
        accountState.selectedAccount.id,
        cashflowIds
      );

      return cashflows.map((row) => {
        return {
          ...row,
          instrument: row.instrumentId
            ? instruments.list.find((x) => x.id === row.instrumentId)
            : undefined,
          underlyingInstrument: row.underlyingInstrumentId
            ? instruments.list.find((x) => x.id === row.underlyingInstrumentId)
            : instruments.list.find((x) => x.id === row.instrumentId),
        } as CashFlowEx;
      });
    },
    enabled:
      accountState.selectedAccount?.id &&
      cashflowIds !== undefined &&
      cashflowIds.length > 0,
  });

  return {
    cashflowIds,
    rows: result.data || [],
    loading: result.isLoading || result.isFetching,
  };
};
