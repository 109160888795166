import APIEndpoint from "./APIEndpoint";

export const getAdminAnalysis = async () => {
  const token = localStorage.getItem("token");

  return fetch(`${APIEndpoint}/admin/analysis`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token || ""}`,
    },
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error creating API key:", error));
};

export const getLastTradesByAccountId = async (accountId: number) => {
  const token = localStorage.getItem("token");

  return fetch(`${APIEndpoint}/admin/analysis/trades/${accountId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token || ""}`,
    },
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error creating API key:", error));
};
