import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Box,
  Text,
  List,
  ListItem,
  Kbd,
  InputGroup,
  InputRightElement,
  Input,
  InputLeftElement,
} from "@chakra-ui/react";
import SearchInput from "../../../forms/components/SearchInput";
import { MdSearch } from "react-icons/md";

interface SearchGroupsModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  items: string[];
  onSelect: (item: string) => void;
}

const SearchGroupsModal: React.FC<SearchGroupsModalProps> = ({
  isOpen,
  onClose,
  title,
  items,
  onSelect,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredItems, setFilteredItems] = useState<string[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  // Sort items alphabetically
  useEffect(() => {
    const sortedItems = [...items].sort((a, b) => a.localeCompare(b));
    setFilteredItems(sortedItems);
  }, [items]);

  // Filter items based on search query
  useEffect(() => {
    if (!searchQuery) {
      const sortedItems = [...items].sort((a, b) => a.localeCompare(b));
      setFilteredItems(sortedItems);
      setSelectedIndex(0);
      return;
    }

    const query = searchQuery.toLowerCase();
    const filtered = items
      .filter((item) => item.toLowerCase().includes(query))
      .sort((a, b) => a.localeCompare(b));
    setFilteredItems(filtered);
    setSelectedIndex(0);
  }, [searchQuery, items]);

  const handleSelect = (item: string) => {
    onSelect(item);
    onClose();
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const visibleItems = filteredItems.slice(0, 15);
    
    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setSelectedIndex((prev) => 
          prev < visibleItems.length - 1 ? prev + 1 : prev
        );
        break;
      case "ArrowUp":
        e.preventDefault(); 
        setSelectedIndex((prev) => prev > 0 ? prev - 1 : prev);
        break;
      case "Enter":
        if (visibleItems[selectedIndex]) {
          handleSelect(visibleItems[selectedIndex]);
        }
        break;
      case "Escape":
        onClose();
        break;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={undefined}>
      <ModalOverlay backdropFilter="blur(2px)" />
      <ModalContent 
        bg="var(--bg)"
        boxShadow="xl"
        maxW="500px"
        onKeyDown={handleKeyDown}
      >
        <ModalHeader w='full'>
          {title}
          <Text fontSize="sm" color="gray.500" mt={1}>
            Search or use arrow keys to navigate
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack  align="stretch" >
            <InputGroup w='full' >
              <InputLeftElement pointerEvents="none">
                <MdSearch fontSize="24px" color="gray" />
              </InputLeftElement>
              <Input
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder={`Search ${title.toLowerCase()}`}
                autoFocus
                pl="2rem"
                border="1px solid var(--gray)"
                _focus={{
                  borderColor: "var(--primary)",
                  boxShadow: "0 0 0 1px var(--primary)"
                }}
              />
            </InputGroup>
            <Box
              border="1px solid var(--gray)"
              borderRadius="md"
              maxHeight="400px"
              overflowY="auto"
              sx={{
                "&::-webkit-scrollbar": {
                  width: "8px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "var(--bg)",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "var(--gray)",
                  borderRadius: "4px",
                },
              }}
            >
              {filteredItems.length > 0 ? (
                <List spacing={1} p={2}>
                  {filteredItems.map((item, index) => (
                    <ListItem
                      key={index}
                      p={2}
                      borderRadius="md"
                      cursor="pointer"
                      bg={selectedIndex === index ? "var(--dark-gray)" : "transparent"}
                      _hover={{ bg: "var(--dark-gray)" }}
                      onClick={() => handleSelect(item)}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Text>{item}</Text>
                      {selectedIndex === index && (
                        <Text fontSize="sm" color="gray.500">
                          <Kbd>↵</Kbd> to select
                        </Text>
                      )}
                    </ListItem>
                  ))}
                
                </List>
              ) : (
                <Box p={4} textAlign="center">
                  <Text color="gray.500">No matches found</Text>
                </Box>
              )}
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter gap={2}>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
          <Button 
            colorScheme="blue"
            onClick={() => filteredItems[selectedIndex] && handleSelect(filteredItems[selectedIndex])}
            isDisabled={!filteredItems.length}
          >
            Select
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SearchGroupsModal;