import { useEffect, useState } from "react";
import { useAtomValue } from "jotai";
import { AccountAtom } from "../../store";
import { SyncSetting } from "../../types";
import { deleteSyncSettings, getSyncSettings, runSyncSetting } from "../../api";
import { useNotifications } from "../../hooks/useNotifications";

interface useAutoSyncControllerResponse {
  settings: SyncSetting[];
  selectedSettings?: SyncSetting;
  setSelectedSettings: (setting?: SyncSetting) => void;
  showConfigure: boolean;
  setShowConfigure: (show: boolean) => void;
  run: (setting: SyncSetting) => void;
  loading: boolean;
  loadSettings: () => void;
  deleteSyncSettingsFunc: (syncSettingId: number) => void;
}

export const useAutoSyncController = (): useAutoSyncControllerResponse => {
  const accountState = useAtomValue(AccountAtom);
  const notifications = useNotifications();

  const [settings, setSettings] = useState<SyncSetting[]>([]);
  const [selectedSettings, setSelectedSettings] = useState<
    SyncSetting | undefined
  >();
  const [showConfigure, setShowConfigure] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const loadSettings = async () => {
    if (accountState.selectedAccount?.id) {
      const result = await getSyncSettings(accountState.selectedAccount?.id);
      setSettings(result ?? []);
    }
  };

  const showConfig = async (show: boolean) => {
    await setShowConfigure(show);
    if (!show) {
      await loadSettings();
    }
  };

  const run = async (setting: SyncSetting) => {
    setLoading(true);
    const { selectedAccount } = accountState;
    if (!selectedAccount?.id) {
      alert("No account selected. Please select an account to continue.");
      return;
    }

    notifications.createNotification(
      "Auto Sync Settings",
      `Running ${setting.broker} Sync...`
    );

    try {
      const result = await runSyncSetting(setting, selectedAccount.id);
      if (result.success) {
        alert(
          "Sync started successfully. Please allow a few seconds for the sync to complete. (" +
            result.message +
            ")"
        );
      } else {
        alert(result.message || "An error occurred during sync.");
      }
    } catch (error) {
      alert(
        `An unexpected error occurred: ${
          error instanceof Error ? error.message : "Unknown error"
        }`
      );
    }
    setLoading(false);
  };

  const deleteSyncSettingsFunc = async (syncSettingId: number) => {
    await deleteSyncSettings(syncSettingId, accountState.selectedAccount?.id);
    loadSettings();
  };

  useEffect(() => {
    loadSettings();
  }, [accountState]);

  return {
    settings,
    selectedSettings,
    setSelectedSettings,
    showConfigure,
    setShowConfigure: showConfig,
    run,
    loading,
    loadSettings,
    deleteSyncSettingsFunc,
  };
};
