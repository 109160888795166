import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Text,
  Button,
  Flex,
  VStack,
  HStack,
  Spacer,
} from "@chakra-ui/react";
import { Checkbox, CheckboxGroup } from "@chakra-ui/react";
import React from "react";
import { Show } from "../../components";

interface ExplainAlertProps {
  entityType: string;
  isOpen: boolean;
  onClose: () => void;
  apply: () => void;
}

export const ExplainAlert = (props: ExplainAlertProps) => {
  const { entityType, isOpen, onClose, apply } = props;
  const cancelRef = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const toggleWarning = (checked: boolean) => {
    localStorage.setItem(
      `explain-alert-${entityType}`,
      checked ? "false" : "true"
    );
  };

  const handleApply = async () => {
    setIsLoading(true);
    try {
      await apply();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      size={"lg"}
    >
      <AlertDialogOverlay>
        <AlertDialogContent  bg='var(--bg)'>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Explain {entityType}s - Save Changes
          </AlertDialogHeader>

          <AlertDialogBody>
            <Show if={entityType === "Cashflow"}>
              <VStack spacing={4}>
                <Text>
                  This will apply the selected Category, Book, Position Name,
                  Strategy, Tags and Notes to ALL the selected cashflows.
                </Text>
                <Alert status="warning" variant="solid">
                  <AlertIcon />
                  <AlertDescription>
                    If a value is blank e.g Category or Book this will have the
                    effect of clearing that value on all the selected cashflows.
                  </AlertDescription>
                </Alert>
              </VStack>
            </Show>
            <Show if={entityType === "Trade"}>
              <VStack spacing={4}>
                <Text>
                  This will apply the selected Book, Position Name, Strategy,
                  Tags and Notes to ALL the selected trades.
                </Text>
                <Alert status="warning" variant="solid">
                  <AlertIcon />
                  <AlertDescription>
                    If a value is blank e.g Strategy or Book this will have the
                    effect of clearing that value on all the selected trades.
                  </AlertDescription>
                </Alert>
              </VStack>
            </Show>
            <Alert status="info" mt={2}>
              <AlertIcon />
              <AlertDescription>
                If you wish to only update one of the values then use the APPLY
                button next to the field.
              </AlertDescription>
            </Alert>
            <Text mt={6}>Do you wish to continue?</Text>
            <Flex mt={6}>
              <Checkbox
                size="sm"
                colorScheme="blue"
                onChange={(e) => {
                  toggleWarning(e.currentTarget.checked);
                }}
              >
                Don't warn me again I understand the implications
              </Checkbox>
            </Flex>
          </AlertDialogBody>

          <AlertDialogFooter>
            <HStack>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Spacer />
              <Button colorScheme="green" onClick={handleApply} isLoading={isLoading}>
                Yes
              </Button>
            </HStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
