import React from "react";
import {
  Box,
  Button,
  filter,
  Flex,
  HStack,
  Spacer,
  Text,
} from "@chakra-ui/react";
import mixpanel from "mixpanel-browser";
import { RiFilterOffLine, RiStockFill } from "react-icons/ri";
import { FaTags, FaSave } from "react-icons/fa";
import { BiArrowToRight, BiPaperPlane } from "react-icons/bi";
import { MdBook } from "react-icons/md";
import { useFilterController } from "./useFilterController";
import { ToolbarDropDown } from "../../../components/design_library/toolbar/ToolbarDropDown";
import { ToolbarFeatures, UserFilter } from "../../../types";
import { Show } from "../../../components";
import ActionButton from "../../../components/design_library/ActionButton";
import { SecondaryButton } from "../../../components/design_library";
import { useFilters } from "../../../hooks/useFilters";

interface FilterProps {
  active: boolean;
  selectedFilter?: UserFilter;
  enabledOptions: ToolbarFeatures[];
  close: () => void;
}

export const Filter = (props: FilterProps) => {
  const { enabledOptions } = props;
  const controller = useFilterController(props.close, props.selectedFilter);

  return (
    <Show if={props.active}>
      <Flex direction="column" w="full">
        {enabledOptions?.includes(ToolbarFeatures.UNDERLYING) && (
          <Box mb={4} w="full">
            <ToolbarDropDown
              value={
                controller.selectedUnderlying
                  ? {
                      label: controller.selectedUnderlying,
                      value: controller.selectedUnderlying,
                    }
                  : {
                      label: (
                        <HStack color="var(--white)">
                          <RiStockFill fill="var(--accent)" />
                          <Text
                            display={{
                              base: "none",
                              md: "block",
                            }}
                          >
                            Underlying ({controller.underlyings.length - 1})
                          </Text>
                        </HStack>
                      ),
                      value: "All",
                    }
              }
              className="tool-bar-select"
              placeholder={
                <HStack color="var(--white)">
                  <RiStockFill fill="var(--accent)" />
                  <Text
                    display={{
                      base: "none",
                      lg: "block",
                    }}
                  >
                    Underlying ({controller.underlyings.length - 1})
                  </Text>
                </HStack>
              }
              options={controller.underlyings.map((underlying) => ({
                value: underlying,
                label: underlying,
              }))}
              onChange={(event: any) => {
                mixpanel.track("Underlying Filter Changed", {
                  underlying: event.value,
                });
                controller.setSelectedUnderlying(
                  event.value === "All" ? undefined : event.value
                );
              }}
            />
          </Box>
        )}

        {enabledOptions.includes(ToolbarFeatures.TAGS) && (
          <Box mb={4} w="full">
            <ToolbarDropDown
              className="tool-bar-select"
              placeholder={
                <HStack color="var(--white)">
                  <FaTags fill="var(--accent)" />
                  <Text
                    display={{
                      base: "none",
                      lg: "block",
                    }}
                  >
                    Tags
                  </Text>
                </HStack>
              }
              options={controller.tags}
              value={
                controller.selectedTag && controller.selectedTag !== "All"
                  ? {
                      label: controller.selectedTag,
                      value: controller.selectedTag,
                    }
                  : {
                      label: (
                        <HStack color="var(--white)">
                          <FaTags fill="var(--accent)" />
                          <Text
                            display={{
                              base: "none",
                              md: "block",
                            }}
                          >
                            Tags
                          </Text>
                        </HStack>
                      ),
                      value: "All",
                    }
              }
              onChange={(event: any) => {
                controller.setSelectedTag(event.value);
              }}
            />
          </Box>
        )}

        {enabledOptions.includes(ToolbarFeatures.POSITION_GROUPING) && (
          <Box mb={4} w="full">
            <ToolbarDropDown
              className="tool-bar-select"
              placeholder={
                <HStack color="var(--white)">
                  <BiArrowToRight fill="var(--accent)" />
                  <Text
                    display={{
                      base: "none",
                      lg: "block",
                    }}
                  >
                    Position Groups
                  </Text>
                </HStack>
              }
              options={controller.positionGroups.map((group) => ({
                value: group,
                label: group,
              }))}
              value={
                controller.selectedPositionGroup &&
                controller.selectedPositionGroup !== "All"
                  ? {
                      label: controller.selectedPositionGroup,
                      value: controller.selectedPositionGroup,
                    }
                  : {
                      label: (
                        <HStack color="var(--white)">
                          <BiArrowToRight fill="var(--accent)" />
                          <Text
                            display={{
                              base: "none",
                              md: "block",
                            }}
                          >
                            Position Groups
                          </Text>
                        </HStack>
                      ),
                      value: "All",
                    }
              }
              onChange={(event: any) => {
                controller?.setSelectedPositionGroup(event.value);
              }}
            />
          </Box>
        )}

        {enabledOptions.includes(ToolbarFeatures.STRATEGY) && (
          <Box mb={4} w="full">
            <ToolbarDropDown
              className="tool-bar-select"
              placeholder={
                <HStack color="var(--white)">
                  <BiPaperPlane fill="var(--accent)" />
                  <Text
                    display={{
                      base: "none",
                      lg: "block",
                    }}
                  >
                    Strategy
                  </Text>
                </HStack>
              }
              options={controller.tradedStrategies.map((strategy) => ({
                value: strategy,
                label: strategy,
              }))}
              value={
                controller.selectedStrategy &&
                controller.selectedStrategy !== "All"
                  ? {
                      label: controller.selectedStrategy,
                      value: controller.selectedStrategy,
                    }
                  : {
                      label: (
                        <HStack color="var(--white)">
                          <BiPaperPlane fill="var(--accent)" />

                          <Text
                            display={{
                              base: "none",
                              md: "block",
                            }}
                          >
                            Strategy
                          </Text>
                        </HStack>
                      ),
                      value: "All",
                    }
              }
              onChange={(event: any) => {
                controller?.setSelectedStrategy(event.value);
              }}
            />
          </Box>
        )}

        {enabledOptions.includes(ToolbarFeatures.BOOKS) && (
          <Box w="full">
            <ToolbarDropDown
              className="tool-bar-select"
              placeholder={
                <HStack color="var(--white)">
                  <MdBook fill="var(--accent)" />
                  <Text
                    display={{
                      base: "none",
                      lg: "block",
                    }}
                  >
                    Books
                  </Text>
                </HStack>
              }
              options={controller.books.map((book) => ({
                value: book,
                label: book,
              }))}
              value={
                controller.selectedBook && controller.selectedBook !== "All"
                  ? {
                      label: controller.selectedBook,
                      value: controller.selectedBook,
                    }
                  : {
                      label: (
                        <HStack color="var(--white)">
                          <MdBook fill="var(--accent)" />
                          <Text
                            display={{
                              base: "none",
                              md: "block",
                            }}
                          >
                            Books
                          </Text>
                        </HStack>
                      ),
                      value: "All",
                    }
              }
              onChange={(event: any) => {
                controller?.setSelectedBook(event.value);
              }}
            />
          </Box>
        )}

        <Show if={props.selectedFilter !== undefined}>
          <HStack mb={2} mt={4}>
            <SecondaryButton
              size="sm"
              bg="var(--red)"
              leftIcon={<RiFilterOffLine />}
              onClick={() => {
                controller.resetFilter();
              }}
              w="full"
            >
              Reset Filter
            </SecondaryButton>
            <Spacer />
            <SecondaryButton
              w="full"
              bg="var(--accent)"
              leftIcon={<FaSave />}
              aria-label="Filter Settings"
              color="var(--white)"
              onClick={() => {
                controller.saveFilter();
              }}
            >
              Save Filter
            </SecondaryButton>
          </HStack>
        </Show>
      </Flex>
    </Show>
  );
};
