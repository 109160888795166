import React from "react";
import {
  Box,
  HStack,
  VStack,
  Heading,
  Text,
  Tag,
  Flex,
  IconButton,
  Tooltip,
  Badge,
  useColorModeValue,
} from "@chakra-ui/react";
import { InsightTemplate } from "../../../../types/Insight";
import { InsightTypeIcon } from "../../../../components/insights/InsightTypeIcon";
import { DeleteIcon, EditIcon, CheckIcon } from "@chakra-ui/icons";
import { useInsightsController } from "../../InsightsManager/useInsightsController";

interface InsightListItemProps {
  insight: InsightTemplate;
  enabled: boolean;
  handleToggleInsight: (insight: InsightTemplate) => void;
  isOwner: boolean;
  setPage: (page: string) => void;
  setInsightData: (insight: InsightTemplate) => void;
}

const InsightListItem: React.FC<InsightListItemProps> = ({
  insight,
  enabled,
  handleToggleInsight,
  isOwner,
  setPage,
  setInsightData,
}) => {
  const controller = useInsightsController();
  
  // Prevent event propagation for action buttons
  const handleEditClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setInsightData(insight);
    setPage("creator");
  };
  
  const handleDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    controller.handleDelete(insight.insightId as number);
  };

  return (
    <Flex
      p={4}
      borderRadius="md"
      cursor="pointer"
      alignItems="center"
      transition="all 0.2s"
      bg={enabled ? "rgba(0, 128, 0, 0.1)" : "transparent"}
      border="1px solid"
      borderColor={enabled ? "var(--green)" : "var(--light-gray)"}
      _hover={{
        bg: "rgba(255, 255, 255, 0.05)",
        transform: "translateY(-1px)",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
      }}
      onClick={() => handleToggleInsight(insight)}
      position="relative"
      overflow="hidden"
    >
      {/* Status indicator */}
      {enabled && (
        <Badge 
          position="absolute" 
          top={0} 
          right={0}
          borderBottomLeftRadius="md"
          borderTopRightRadius="md"
          bg="var(--green)" 
          px={2}
          py={1}
        >
          <CheckIcon mr={1} fontSize="xs" />
          Active
        </Badge>
      )}

      {/* Icon */}
      <Box
        w="40px"
        h="40px"
        borderRadius="md"
        bg={enabled ? "var(--green)" : "var(--gray)"}
        color="white"
        display="flex"
        justifyContent="center"
        alignItems="center"
        mr={4}
        flexShrink={0}
      >
        <InsightTypeIcon type={insight.type} />
      </Box>

      {/* Content */}
      <Flex flex="1" direction="column" overflow="hidden" mr={4}>
        <Heading
          fontSize="16px"
          fontWeight="600"
          mb={1}
          isTruncated
        >
          {insight.title}
        </Heading>
        
        <Text
          fontSize="sm"
          color="var(--light-gray)"
          noOfLines={2}
          mb={2}
        >
          {insight.description}
        </Text>
        
        <HStack spacing={2} flexWrap="wrap">
          {insight.official && (
            <Tag size="sm" colorScheme="green" variant="solid">Official</Tag>
          )}
          {insight.isDefaultInsight && (
            <Tag size="sm" colorScheme="blue" variant="solid">Recommended</Tag>
          )}
          {insight.tags && insight.tags.map((tag: string) => (
            <Tag size="sm" key={tag} variant="subtle">
              {tag}
            </Tag>
          ))}
        </HStack>
      </Flex>

      {/* Actions */}
      <HStack spacing={2} flexShrink={0}>
        {isOwner && (
          <>
            <Tooltip label="Edit insight" placement="top">
              <IconButton
                aria-label="Edit insight"
                icon={<EditIcon />}
                size="sm"
                variant="ghost"
                onClick={handleEditClick}
              />
            </Tooltip>
            <Tooltip label="Delete insight" placement="top">
              <IconButton
                aria-label="Delete insight"
                icon={<DeleteIcon />}
                size="sm"
                variant="ghost"
                colorScheme="red"
                onClick={handleDeleteClick}
              />
            </Tooltip>
          </>
        )}
      </HStack>
    </Flex>
  );
};

export default InsightListItem;
