import { Box, HStack } from "@chakra-ui/react";
import moment from "moment";
import Notes from "../../../features/notes/Notes";
import { calculateDaysUntilExpiry } from "../utils/calculateDaysUntilExpiry";
import { FastTag } from "../../design_library";
import React from "react";
import { CashFlowType } from "../../../types";

export const CashflowDetailsRenderer = (params: any, page: string) => {
  let cashflow = params?.node?.data;
  let instrument = cashflow?.instrument;
  if (!instrument) {
    return <></>;
  }

  const cashflowType = cashflow.positionType as CashFlowType;

  const value = cashflow.netProceeds;

  return (
    <HStack
      fontWeight={400}
      justifyContent={"space-between"}
      textAlign={"right"}
      color="var(--white)"
    >
      <FastTag h="24px" backgroundColor="var(--cashflow-instrument)">
        Cash
      </FastTag>

      <FastTag
        h="24px"
        backgroundColor={
          value > 0 ? "var(--cashflow-green)" : "var(--cashflow-red)"
        }
      >
        {cashflowType}
      </FastTag>

      <Notes
        tags={cashflow.tags && cashflow.tags[0] !== "" ? cashflow.tags : null}
        notes={cashflow.notes !== "<p></p>" ? cashflow.notes : null}
      />
    </HStack>
  );
};
