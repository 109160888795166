import React from "react";
import { 
  Box, 
  Grid, 
  Heading, 
  GridItem, 
  Flex, 
  Text, 
  Divider,
  useBreakpointValue
} from "@chakra-ui/react";
import { InsightTemplate } from "../../../../types/Insight";
import InsightCard from "./InsightCard";
import InsightListItem from "./InsightListItem";

interface InsightsGridProps {
  filteredEntries: Record<string, InsightTemplate[]>;
  isInsightEnabled: (id: number) => boolean;
  handleToggleInsight: (insight: InsightTemplate) => void;
  appState: { user?: { id: string } };
  setInsightData: (insight: any) => void;
  setPage: (page: string) => void;
  view: string;
  displayDemoData: boolean;
}

const InsightsGrid: React.FC<InsightsGridProps> = ({
  filteredEntries,
  isInsightEnabled,
  handleToggleInsight,
  setInsightData,
  setPage,
  appState,
  view,
  displayDemoData,
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const hasEntries = Object.values(filteredEntries).some(arr => arr.length > 0);

  // Function to determine grid span based on insight type
  const getGridSpan = (insight: InsightTemplate) => {
    const isChartOrTable = insight.type.toLowerCase().includes("chart") ||
                          insight.type.toLowerCase().includes("treemap") ||
                          insight.type.toLowerCase().includes("table");
    const isComparison = insight.type.toLowerCase().includes("comparison");
    
    return {
      colSpan: isChartOrTable ? { base: 1, md: 2, lg: 2 } : 1,
      rowSpan: (isChartOrTable || isComparison) ? { base: 1, md: 2 } : 1
    };
  };

  if (!hasEntries) {
    return (
      <Flex 
        direction="column" 
        align="center" 
        justify="center" 
        py={10} 
        px={4}
        minH="200px"
      >
        <Text fontSize="lg" mb={2}>No insights found</Text>
        <Text color="gray.500">Try adjusting your search or category filters</Text>
      </Flex>
    );
  }

  return (
    <Box pb="120px">
      {Object.entries(filteredEntries).map(([category, insights]) => {
        if (insights.length === 0) return null;
        
        return (
          <Box key={category} w="full" mb={8} px={4}>
            <Flex 
              align="center" 
              mb={4} 
              mt={4}
            >
              <Heading size="md" fontWeight="600">
                {category}
              </Heading>
              <Text 
                ml={2} 
                fontSize="sm" 
                color="gray.500" 
                fontWeight="normal"
              >
                ({insights.length})
              </Text>
            </Flex>
            
            <Divider mb={4} />

            {view === "list" ? (
              <Flex direction="column" gap={3}>
                {insights.map((insight) => (
                  <InsightListItem
                    key={insight.insightId}
                    insight={insight}
                    enabled={isInsightEnabled(insight.insightId as number)}
                    handleToggleInsight={handleToggleInsight}
                    isOwner={
                      parseInt(appState.user?.id || '0') ===
                      parseInt(insight.ownerUserId?.toString() || '0')
                    }
                    setInsightData={setInsightData}
                    setPage={setPage}
                  />
                ))}
              </Flex>
            ) : (
              <Grid
                templateColumns={{
                  base: "1fr",
                  sm: "repeat(auto-fill, minmax(280px, 1fr))",
                  md: "repeat(auto-fill, minmax(320px, 1fr))",
                  lg: "repeat(auto-fill, minmax(350px, 1fr))"
                }}
                gap={4}
                w="full"
                autoFlow="dense"
              >
                {insights.map((insight) => {
                  const { colSpan, rowSpan } = getGridSpan(insight);
                  
                  return (
                    <GridItem
                      key={insight.insightId}
                      colSpan={colSpan}
                      rowSpan={rowSpan}
                    >
                      <InsightCard
                        insight={insight}
                        enabled={isInsightEnabled(insight.insightId as number)}
                        handleToggleInsight={handleToggleInsight}
                        isOwner={
                          parseInt(appState.user?.id || '0') ===
                          parseInt(insight.ownerUserId?.toString() || '0')
                        }
                        setInsightData={setInsightData}
                        setPage={setPage}
                        displayDemoData={displayDemoData}
                      />
                    </GridItem>
                  );
                })}
              </Grid>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default InsightsGrid;
