import React from "react";
import { Flex, HStack, Spacer } from "@chakra-ui/react";
import { FilterSettings } from "../filter-settings/FilterSettings";
import SidebarToggle from "../../components/toolbar/SidebarToggle";
import CustomSwitch from "../../components/design_library/toolbar/Switch";
import PageHeading from "../../components/design_library/PageHeading";
import { RiExchangeBoxFill } from "react-icons/ri";
import { FixedDateRangeSelector } from "../../components";
import { KeyValuePair, KnowledgeBaseHelperTopics } from "../../types";
import { AutoSync } from "../AutoSync/AutoSync";
import KnowledgeBaseHelper from "../knowledgebase/KnowledgeBaseHelper";
import Uploads from "../uploads/Uploads";

interface TradesToolbarProps {
  showUnBooked: boolean;
  setShowUnBooked: (value: boolean) => void;
  showNoPositionGrouping: boolean;
  setShowNoPositionGrouping: (value: boolean) => void;
  setSelectedDateRange: (item: KeyValuePair) => void;
}

export const TradesToolbar = (props: TradesToolbarProps) => {
  const { setSelectedDateRange } = props;
  return (
    <Flex className="grid-toolbar-hook" mx="2" h="42px">
      <HStack
        className="grid-toolbar-select"
        wrap={"wrap"}
        maxW="100vw"
        w="100%"
        mb="1px"
      >
        <SidebarToggle />

        <PageHeading
          title="Trades"
          description="View and manage your trades. Filter, group, and sort your trades to get the insights you need."
          icon={<RiExchangeBoxFill />}
        />

        <FilterSettings />

        <FixedDateRangeSelector
          onDateRangeSelected={setSelectedDateRange}
          storageKey="trades"
          defaultValue="1y"
        />

        <CustomSwitch
          id="isChecked"
          isChecked={props.showUnBooked}
          onChange={(e: any) => {
            props.setShowUnBooked(!props.showUnBooked);
          }}
          mr={2}
          className="custom-switch-with-elipsis"
        >
          Show Unbooked
        </CustomSwitch>

        <CustomSwitch
          id="isChecked"
          className="custom-switch-with-elipsis"
          isChecked={props.showNoPositionGrouping}
          onChange={(e: any) => {
            props.setShowNoPositionGrouping(!props.showNoPositionGrouping);
          }}
          mr={2}
        >
          Show No position group
        </CustomSwitch>

        <Spacer
          display={{
            base: "none",
            md: "block",
          }}
        />

        <AutoSync minimalGui={true} />

        <KnowledgeBaseHelper topic={KnowledgeBaseHelperTopics.TRANSACTIONS} />

        <Uploads />
      </HStack>
    </Flex>
  );
};
